import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiDownloadGroup } from "@/types/strapi";

import { Colors } from "@/tokens/color";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { DownloadGroupWrapper } from "@/ui/molecules/download_group_wrapper";
import { TextHeader } from "@/ui/molecules/text_header";

import { RenderLocation } from "@/util/tokens/render_location_util";

interface PostFooterProps {
    className?: SerializedStyles;
    containerClassName?: SerializedStyles;
    downloadGroups: StrapiDownloadGroup[];
    legacyGrid?: boolean;
}

export const DownloadPostFooter: FunctionComponent<PostFooterProps> = ({
    legacyGrid = true,
    ...props
}) => {
    /**
     * Styles
     */
    const postFooterContainerStyles = css(
        {
            background: Colors["neutral-95"],
        },
        props.containerClassName,
    );

    /**
     * Elements
     */
    return (
        <GridContainer
            className={props.className}
            containerClassName={postFooterContainerStyles}
            legacyGrid={legacyGrid}
            marginBottom="InterSectionGapSmall"
            marginTop="InterSectionGapSmall"
        >
            <GridColumn>
                <TextHeader
                    {...{
                        Eyebrow: "All Downloads",
                        Headline: "Get Warp today",
                        id: "download-post-footer",
                    }}
                    marginBottom="InterSectionGapSmall"
                />

                <DownloadGroupWrapper
                    downloadGroups={props.downloadGroups}
                    renderLocation={RenderLocation.DOWNLOAD_POST_FOOTER}
                />
            </GridColumn>
        </GridContainer>
    );
};

DownloadPostFooter.displayName = "DownloadPostFooter";
