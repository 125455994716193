/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyPreFooter, StrapiLottieAnimation } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";
import { ProductAnimation } from "@/ui/molecules/product_animation";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { RenderLocation } from "@/util/tokens/render_location_util";

interface LegacyPreFooterAnimatedProps extends StrapiLegacyPreFooter {
    onlyRenderDownloadCta?: boolean;
}

export const LegacyPreFooterAnimated: FunctionComponent<
    LegacyPreFooterAnimatedProps
> = (props) => {
    /**
     * Styles
     */
    const preFooterContainerStyle = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.ProductHeroBlocks),
    );

    const textContainerStyles = css({
        display: "flex",
        flexDirection: "column",
    });

    const ctaGroupContainerStyles = css({
        marginTop: Spacing["spacing-12"],
    });

    const ctaGroupStyles = buildStylesByBreakpoint("alignItems", {
        extraSmall: "center",
        large: "flex-start",
    });

    const productAnimationStyles = buildStylesByBreakpoint("padding", {
        extraSmall: Spacing["spacing-8"],
        medium: `${Spacing["spacing-8"]} ${Spacing["spacing-0"]}`,
        large: Spacing["spacing-16"],
    });

    /**
     * Rendering
     */
    const renderTextContainer = () => {
        return (
            <GridColumn
                className={textContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                    large: 4,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                    large: 1,
                }}
            >
                {props.Eyebrow && (
                    <Text
                        fontSize="EyeBrow"
                        fontWeight="medium"
                        themeKey="textSecondary"
                    >
                        {props.Eyebrow}
                    </Text>
                )}

                <Text
                    fontSize="HeadlineSmall"
                    marginBottom="PreFooterHeadlineAnimated"
                    tag="h2"
                    textAlign={{
                        extraSmall: "left",
                        medium: "center",
                        large: "left",
                    }}
                    themeKey="headlinePrimary"
                >
                    {props.Headline}
                </Text>

                {props.Subheadline && (
                    <RichText config="LegacyPreFooter">
                        {props.Subheadline}
                    </RichText>
                )}
            </GridColumn>
        );
    };

    const renderCtaGroup = () => {
        const _castedCta = props.CTA!;

        return (
            <GridColumn
                className={ctaGroupContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                    large: 4,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                    large: 1,
                }}
            >
                <CtaGroup
                    alignment={{
                        extraSmall: "center",
                        large: "start",
                    }}
                    className={ctaGroupStyles}
                    ctaData={_castedCta}
                    onlyRenderDownloadCta={props.onlyRenderDownloadCta}
                    primaryFontSize="CtaHero"
                    renderLocation={RenderLocation.PRE_FOOTER}
                    secondaryFontSize="Micro"
                />
            </GridColumn>
        );
    };

    const renderAnimation = () => {
        return (
            <ProductAnimation
                {...(props.Media[0] as StrapiLottieAnimation)}
                animationColumnSpan={{
                    extraSmall: "full",
                    medium: 8,
                    large: "full",
                }}
                animationColumnStart={{
                    extraSmall: 1,
                    medium: 3,
                    large: 1,
                }}
                className={productAnimationStyles}
                containerColumnSpan="full"
                containerColumnStart={{
                    extraSmall: 1,
                    large: 6,
                }}
            />
        );
    };

    return (
        <div>
            <Divider
                marginBottom="DefaultSectionMargin"
                marginTop="DefaultSectionMargin"
            />

            <GridContainer
                className={preFooterContainerStyle}
                marginBottom="DefaultSectionMargin"
            >
                <SubgridContainer
                    columnSpan={{
                        extraSmall: "full",
                        large: 5,
                    }}
                >
                    {renderTextContainer()}

                    {props.CTA && renderCtaGroup()}
                </SubgridContainer>

                {renderAnimation()}
            </GridContainer>
        </div>
    );
};
