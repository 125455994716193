/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiDownloadGroup } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";

import { buildStylesByBreakpoint } from "@/util/style_util";
import type { RenderLocation } from "@/util/tokens/render_location_util";
import { convertToRem } from "@/util/ui_util";

import { DownloadGroupItem } from "./download_group_item";

interface DownloadGroupProps {
    className?: SerializedStyles;
    downloadGroups: StrapiDownloadGroup[];
    renderLocation?: RenderLocation;
}

export const DownloadGroupWrapper: FunctionComponent<DownloadGroupProps> = (
    props,
) => {
    /**
     * Styles
     */
    const downloadGroupContainerStyles = css(
        {
            display: "flex",
            flexWrap: "wrap",
        },
        buildStylesByBreakpoint("gap", spacingSets["DownloadGroupContainer"]),
    );

    const dividerStyles = css(
        buildStylesByBreakpoint("height", {
            extraSmall: convertToRem(1),
            medium: "100%",
        }),
        buildStylesByBreakpoint("marginBottom", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("width", {
            extraSmall: "100%",
            medium: convertToRem(1),
        }),
    );

    const downloadGroupWrapperStyles = css(
        {
            display: "flex",
            flex: "1 0 0",
        },
        buildStylesByBreakpoint("flexBasis", {
            extraSmall: "100%",
            medium: "0",
        }),
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column",
            medium: "row",
        }),
        buildStylesByBreakpoint("gap", {
            medium: Spacing["spacing-5"],
            large: Spacing["spacing-8"],
        }),
    );

    return (
        <GridColumn className={downloadGroupContainerStyles}>
            {props.downloadGroups.map((_downloadGroup) => {
                return (
                    <div
                        css={downloadGroupWrapperStyles}
                        key={`download-group::${_downloadGroup.OS_Name}`}
                    >
                        <Divider className={dividerStyles} />

                        <DownloadGroupItem
                            {..._downloadGroup}
                            renderLocation={props.renderLocation}
                        />
                    </div>
                );
            })}
        </GridColumn>
    );
};
