import { SingleTheme } from "@/types/tokens/themes";

export const MasterTheme: SingleTheme = {
    backgrounds: {
        backgroundActive: "lighten-10",
        backgroundInverted: "off-white",
        backgroundPrimary: "black",
        backgroundSecondary: "opaque-lighten-5",
        backgroundSecondaryInverted: "opaque-darken-5",
        backgroundTertiary: "lighten-5",
        backgroundTertiaryInverted: "darken-5",
    },
    buttons: {
        buttonGhost: {
            background: {
                active: "lighten-10",
                default: "transparent",
                focus: "transparent",
                hover: "lighten-10",
            },
            boxShadow: {
                active: "box-shadow-transparent",
                default: "box-shadow-transparent",
                focus: "box-shadow-lighten-20",
                hover: "box-shadow-transparent",
            },
            icon: {
                active: "lighten-90",
                default: "lighten-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
            iconContainer: {
                active: "lighten-5",
                default: "lighten-5",
                focus: "lighten-5",
                hover: "lighten-5",
            },
            text: {
                active: "lighten-90",
                default: "lighten-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
        },
        buttonPrimary: {
            background: {
                active: "opaque-lighten-20",
                default: "off-white",
                focus: "opaque-lighten-10",
                hover: "opaque-lighten-10",
            },
            boxShadow: {
                active: "box-shadow-transparent",
                default: "box-shadow-transparent",
                focus: "box-shadow-lighten-20",
                hover: "box-shadow-transparent",
            },
            icon: {
                active: "lighten-90",
                default: "darken-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
            iconContainer: {
                active: "lighten-5",
                default: "darken-5",
                focus: "darken-5",
                hover: "lighten-5",
            },
            text: {
                active: "lighten-90",
                default: "darken-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
        },
        buttonSecondary: {
            background: {
                active: "opaque-lighten-5",
                default: "opaque-lighten-10",
                focus: "opaque-lighten-10",
                hover: "opaque-lighten-5",
            },
            boxShadow: {
                active: "box-shadow-transparent",
                default: "box-shadow-transparent",
                focus: "box-shadow-lighten-20",
                hover: "box-shadow-transparent",
            },
            icon: {
                active: "lighten-90",
                default: "lighten-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
            iconContainer: {
                active: "lighten-5",
                default: "lighten-5",
                focus: "lighten-5",
                hover: "lighten-5",
            },
            text: {
                active: "lighten-90",
                default: "lighten-90",
                focus: "lighten-90",
                hover: "lighten-90",
            },
        },
    },
    links: {
        linkInactive: {
            icon: {
                active: "lighten-50",
                default: "lighten-50",
                focus: "lighten-50",
                hover: "lighten-50",
            },
            text: {
                active: "lighten-50",
                default: "lighten-50",
                focus: "lighten-50",
                hover: "lighten-50",
            },
            underline: {
                active: "lighten-50",
                default: "lighten-30",
                focus: "lighten-50",
                hover: "lighten-50",
            },
        },
        linkPrimary: {
            icon: {
                active: "lighten-70",
                default: "lighten-90",
                focus: "off-white",
                hover: "off-white",
            },
            text: {
                active: "lighten-70",
                default: "lighten-90",
                focus: "off-white",
                hover: "off-white",
            },
            underline: {
                active: "lighten-50",
                default: "lighten-70",
                focus: "off-white",
                hover: "off-white",
            },
        },
        linkSecondary: {
            icon: {
                active: "lighten-50",
                default: "lighten-70",
                focus: "lighten-90",
                hover: "lighten-90",
            },
            text: {
                active: "lighten-50",
                default: "lighten-70",
                focus: "lighten-90",
                hover: "lighten-90",
            },
            underline: {
                active: "lighten-30",
                default: "lighten-50",
                focus: "lighten-70",
                hover: "lighten-70",
            },
        },
    },
    text: {
        headlinePrimary: "lighten-90",
        headlineSecondary: "lighten-50",
        textEmphasis: "lighten-90",
        textPrimary: "lighten-70",
        textSecondary: "lighten-50",
    },
    ui: {
        uiActive: "lighten-90",
        uiActiveInverted: "darken-90",
        uiBorder: "lighten-10",
        uiInactive: "lighten-50",
        uiInactiveInverted: "darken-50",
    },
};
