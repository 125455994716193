import { useEffect, useState } from "react";

import { useInterval } from "@/util/hooks/user_interval_hook";

export type WarpStatusDescription =
    | "All Systems Operational"
    | "Partial System Outage"
    | "Major System Outage"
    | null;

export function useWarpStatus() {
    const [isFetchingStatus, setIsFetchingStatus] = useState(false);

    const [statusMessage, setStatusMessage] =
        useState<WarpStatusDescription>(null);

    const checkStatus = async () => {
        setIsFetchingStatus(true);

        const response = await fetch(
            "https://status.warp.dev/api/v2/status.json",
        );

        const {
            status: { description },
        } = await response.json();

        setStatusMessage(description);
        setIsFetchingStatus(false);
    };

    useInterval(checkStatus, 600000, true); /** check every 10 minutes */

    useEffect(() => {
        checkStatus();
    }, []);

    return {
        isFetchingStatus,
        statusMessage,
    };
}
