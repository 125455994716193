import testIsTouchDevice from "is-touch-device";
import { useState } from "react";
import { browserName, osName } from "react-device-detect";

import { Browser, OperatingSystem } from "@/types/data";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";

export function useUserAgent() {
    /**
     * State Management
     */
    const [operatingSystem, setOperatingSystem] =
        useState<OperatingSystem>("mac"); // We're Mac-first, default to Mac lockup

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const [browser, setBrowser] = useState<Browser>("chrome");

    /**
     * Effects
     */
    // We use useLayoutEffect when able to prevent FOUC
    useIsomorphicLayoutEffect(() => {
        const fetchOperatingSystem = () => {
            switch (osName) {
                case "Windows":
                    return "windows";
                case "Fedora":
                case "Linux":
                case "Ubuntu":
                    return "linux";
                case "Android":
                    return "android";
                case "iOS":
                    return "iOS";
                default:
                    return "mac";
            }
        };

        const fetchBrowser = () => {
            switch (browserName) {
                case "Safari":
                case "Mobile Safari":
                    return "safari";
                case "Edge":
                    return "edge";
                case "Firefox":
                    return "firefox";
                default:
                    return "chrome";
            }
        };

        setOperatingSystem(fetchOperatingSystem());

        setBrowser(fetchBrowser());

        setIsTouchDevice(testIsTouchDevice());
    }, []);

    return { browser, isTouchDevice, operatingSystem };
}
