/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import _ from "lodash";
import NextLink from "next/link";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { Breadcrumbs as BreadcrumbsType } from "@/types/data";
import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";
import type {
    StrapiDownloadOption,
    StrapiNavBar,
    StrapiNotification,
} from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Logo } from "@/ui/atoms/logo";
import { MobileMenuButton } from "@/ui/atoms/mobile_menu_button";
import { NavLink } from "@/ui/atoms/nav_link";
import { Breadcrumbs } from "@/ui/molecules/breadcrumbs";
import { DownloadButton } from "@/ui/molecules/download_button";
import { DropdownMenu } from "@/ui/molecules/dropdown_menu";
import { ExpandableNav } from "@/ui/molecules/expandable_nav";
import { PushNotification } from "@/ui/molecules/push_notification";

import { useGlobalsContext } from "@/util/context/globals_context";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { TrackEvent, getTrackEvent } from "@/util/tokens/track_event_util";
import { convertToRem } from "@/util/ui_util";

export interface NavigationBarRef {
    navigationBarRef: TypedRefObject;
    notificationsRef: TypedArrayRefObject;
}
interface NavigationBarProps extends StrapiNavBar {
    breadcrumbs?: BreadcrumbsType;
    className?: SerializedStyles;
    notifications?: Array<StrapiNotification>;
    renderMinimalNav?: boolean;
    renderNotifications?: boolean;
}

export const NavigationBar = forwardRef<NavigationBarRef, NavigationBarProps>(
    ({ renderNotifications = false, ...props }, ref) => {
        /**
         * Globals
         */
        const theme = useTypedTheme();

        /**
         * Refs
         */
        const navigationBarRef = useRef(null);
        const [notificationsRef, setNotificationRef] = useArrayRef();

        useImperativeHandle(
            ref,
            () => ({
                navigationBarRef,
                notificationsRef,
            }),
            [notificationsRef],
        );

        /**
         * Context
         */
        const { downloads, isTouchDevice, operatingSystem } =
            useGlobalsContext();

        /**
         * State
         */
        const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);

        const [activeDropdownIndex, setActiveDropdownIndex] =
            useState<number>();

        const resetDropdownMenu = () => {
            setActiveDropdownIndex(undefined);
        };

        const resetMobileMenu = () => {
            setIsMobileMenuExpanded(false);
        };

        const resetMenusState = () => {
            resetDropdownMenu();
            resetMobileMenu();
        };

        /**
         * Interactivity
         */
        const handleMobileMenuClick = () => {
            setIsMobileMenuExpanded(!isMobileMenuExpanded);
        };

        const handleNavGroupHover = (index?: number) => {
            setActiveDropdownIndex(index);
        };

        /**
         * Styles
         */
        const navContainerStyles = css(
            {
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                rowGap: Spacing["spacing-2"],
                width: "100%",
            },
            buildStylesByBreakpoint("pointerEvents", {
                extraSmall: "none",
                medium: "auto",
            }),
            buildStylesByBreakpoint("height", {
                extraSmall: "100%",
                medium: "auto",
            }),
            props.className,
        );

        const navInnerContainerStyles = css({
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
            rowGap: Spacing["spacing-2"],
            width: "100%",
        });

        const supplementalContainerStyles = css({
            display: "flex",
            flexShrink: 0,
            justifyContent: "space-between",
            position: "relative",
            width: "100%",
        });

        const navStyles = css(
            {
                backdropFilter: backgroundBlur("blurMedium"),
                background: Colors[theme.backgrounds.backgroundSecondary],
                borderRadius: BorderRadiuses.borderLarge,
                boxSizing: "border-box",
                columnGap: Spacing["spacing-1"],
                display: "flex",
                flexShrink: 0,
                justifyContent: "space-between",
                pointerEvents: "auto",
                width: "100%",
            },
            // Lots of padding styles here, but needed for different orientations
            buildStylesByBreakpoint("paddingTop", HalfColumnGaps),
            buildStylesByBreakpoint("paddingBottom", HalfColumnGaps),
            buildStylesByBreakpoint("paddingRight", {
                ...HalfColumnGaps,
                extraSmall: Spacing["spacing-0"],
                small: Spacing["spacing-0"],
            }),
            buildStylesByBreakpoint("paddingLeft", {
                extraSmall: Spacing["spacing-3"],
                small: Spacing["spacing-4"],
                large: Spacing["spacing-5"],
            }),
        );

        const linksContainerStyles = css({
            alignItems: "center",
            columnGap: Spacing["spacing-3"],
            display: "flex",
        });

        const linksListStyles = buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "flex",
        });

        const logoButtonStyles = css({
            appearance: "none",
            background: "none",
            border: "none",
            cursor: "pointer",
        });

        const smallLogoStyles = css(
            { width: convertToRem(23) },
            buildStylesByBreakpoint("display", {
                extraSmall: "flex",
                medium: "none",
            }),
        );

        const defaultLogoStyles = css(
            buildStylesByBreakpoint("display", {
                extraSmall: "none",
                medium: "flex",
            }),
            buildStylesByBreakpoint("width", {
                extraSmall: convertToRem(75),
                large: convertToRem(83),
            }),
        );

        const actionsContainerStyles = css(
            {
                alignItems: "center",
                display: "flex",
            },
            buildStylesByBreakpoint("flexGrow", {
                extraSmall: undefined,
                medium: 1,
            }),
            buildStylesByBreakpoint("justifyContent", {
                extraSmall: undefined,
                medium: "flex-end",
            }),
        );

        const actionsListStyles = css(
            { alignItems: "center", display: "flex" },
            buildStylesByBreakpoint("columnGap", {
                extraSmall: Spacing["spacing-1"],
                medium: Spacing["spacing-2"],
            }),
        );

        // We always show download button across all breakpoints
        const actionStyles = (isDownload?: boolean) =>
            isDownload
                ? css({ display: "flex" })
                : buildStylesByBreakpoint("display", {
                      extraSmall: "none",
                      small: "flex",
                  });

        // We only render menu button on XS + S
        const mobileMenuButtonStyles = buildStylesByBreakpoint("display", {
            extraSmall: "flex",
            medium: "none",
        });

        const notificationStackStyles = css(
            {
                flexShrink: 0,
                pointerEvents: "auto",
                right: 0,
                top: 0,
                transformOrigin: "top right",
            },
            buildStylesByBreakpoint("width", {
                extraSmall: "100%",
                medium: "43ch",
            }),
            buildStylesByBreakpoint("position", {
                extraSmall: "relative",
                medium: "absolute",
            }),
        );

        const dropdownMenusContainerStyles = css(
            {
                position: "relative",
            },
            buildStylesByBreakpoint("display", {
                extraSmall: "none",
                medium: "flex",
            }),
        );

        /**
         * Rendering
         */
        // Glyph only on XS + S, full logo on M–XL
        const renderLogo = () => {
            return (
                <NextLink
                    css={logoButtonStyles}
                    href="/"
                    onClick={resetMobileMenu}
                    onMouseEnter={resetDropdownMenu}
                >
                    {/* Glyph-only logo */}
                    <Logo className={smallLogoStyles} glyphOnly={true} />

                    {/* Full-logo */}
                    <Logo className={defaultLogoStyles} />
                </NextLink>
            );
        };

        const renderLinksList = () => {
            const linksList = props.Links.map((link, index) => {
                const _isPrimitiveLink =
                    link.__component === "primitives.primitive-link";

                const navLinkText = _isPrimitiveLink ? link.Text : link.Title;
                const navLinkUrl = _isPrimitiveLink ? link.URL : undefined;

                return (
                    <li key={`nav-bar::links-list::link::${link.id}::${index}`}>
                        <NavLink
                            expandable={!_isPrimitiveLink}
                            isActive={activeDropdownIndex === index}
                            text={navLinkText}
                            url={navLinkUrl}
                            onHover={
                                _isPrimitiveLink
                                    ? resetDropdownMenu
                                    : () => handleNavGroupHover(index)
                            }
                        />
                    </li>
                );
            });

            return <ul css={linksListStyles}>{linksList}</ul>;
        };

        const renderActionsList = () => {
            const sanitizedActions = props.renderMinimalNav
                ? _.filter(
                      props.Actions,
                      (action) =>
                          action.__component ===
                          "primitives.primitive-download-button",
                  )
                : props.Actions;

            const actionsList = sanitizedActions.map((action, index) => {
                const key = `nav-bar::actions-list::action::${action.id}::${index}`;

                switch (action.__component) {
                    case "primitives.primitive-link":
                    case "primitives.primitive-button": {
                        return (
                            <NavLink
                                className={actionStyles()}
                                key={key}
                                text={action.Text}
                                url={action.URL}
                                onClick={resetMenusState}
                            />
                        );
                    }
                    case "primitives.primitive-download-button": {
                        // If touch device, we send to the downloads page
                        if (isTouchDevice) {
                            return (
                                <Button
                                    fontSize="NavText"
                                    href="/download"
                                    key={key}
                                    track={TrackEvent.ALL_DOWNLOADS_LINK}
                                    onClick={resetMenusState}
                                >
                                    All downloads
                                </Button>
                            );
                        }

                        // If Mac, we show the direct download button
                        if (operatingSystem === "mac") {
                            const actionData = downloads.find(
                                (download) =>
                                    download.OS_Name.toLowerCase() === "mac",
                            );

                            return (
                                <DownloadButton
                                    actionData={
                                        actionData
                                            ?.Primary_Action[0] as StrapiDownloadOption
                                    }
                                    fontSize="NavText"
                                    key={key}
                                    os="Mac"
                                    renderIcon={false}
                                    track={TrackEvent.NAV_BAR_DOWNLOAD}
                                    onClick={resetMenusState}
                                />
                            );
                        }

                        /**
                         * If it's any other operating system,
                         * we send them to all downloads with different language
                         */
                        return (
                            <Button
                                fontSize="NavText"
                                href="/download"
                                key={key}
                                track={TrackEvent.ALL_DOWNLOADS_LINK}
                                onClick={resetMenusState}
                            >
                                Download Warp
                            </Button>
                        );
                    }
                    default: {
                        // Contact Sales CTA
                        return (
                            <NavLink
                                className={actionStyles()}
                                key={key}
                                text={action.Text}
                                track={getTrackEvent("REQUEST_DEMO_LINK", {
                                    location: "navbar",
                                })}
                                url="/contact-sales"
                                onClick={resetMenusState}
                            />
                        );
                    }
                }
            });

            return <div css={actionsListStyles}>{actionsList}</div>;
        };

        const renderNotificationStack = () => {
            const castedNotifications = props.notifications!;

            const notifications = castedNotifications.map(
                (notification, index) => (
                    <PushNotification
                        {...notification}
                        key={`nav-bar::notification::${notification.Headline}::${index}`}
                        ref={setNotificationRef}
                        onClick={resetMenusState}
                    />
                ),
            );

            return <div css={notificationStackStyles}>{notifications}</div>;
        };

        const renderDropdownMenus = () => {
            const dropdownMenus = props.Links.map((link, index) => {
                if (link.__component === "components.component-nav-group") {
                    return (
                        <DropdownMenu
                            {...link}
                            isExpanded={index === activeDropdownIndex}
                            key={`nav-bar::dropdown-menu::${link.Title}::${index}`}
                            onLinkClick={resetMenusState}
                        />
                    );
                }
            });

            return (
                <div css={dropdownMenusContainerStyles}>{dropdownMenus}</div>
            );
        };

        {
            /**
             * Due to the structure of this component and how we
             * render the mobile nav, we need to have a separate
             * breadcrumb for smaller and larger screens.
             */
        }
        const renderBreadcrumbs = (deviceSize: "small" | "large") => {
            if (!props.breadcrumbs) {
                return;
            }

            const smallContainerStyles = css(
                {
                    position: "relative",
                },
                buildStylesByBreakpoint("display", {
                    extraSmall: "block",
                    medium: "none",
                }),
            );

            const largeContainerStyles = css(
                {
                    position: "relative",
                },
                buildStylesByBreakpoint("display", {
                    extraSmall: "none",
                    medium: "flex",
                }),
            );

            return (
                <div
                    css={
                        deviceSize === "small"
                            ? smallContainerStyles
                            : largeContainerStyles
                    }
                >
                    <Breadcrumbs
                        breadcrumbs={props.breadcrumbs}
                        className={css({
                            left: 0,
                            position: "absolute",
                            top: 0,
                        })}
                        isLegacy={false}
                    />
                </div>
            );
        };

        return (
            <div css={navContainerStyles} onMouseLeave={resetDropdownMenu}>
                <div css={navInnerContainerStyles}>
                    <nav css={navStyles} ref={navigationBarRef}>
                        <div css={linksContainerStyles}>
                            {renderLogo()}

                            {!props.renderMinimalNav && renderLinksList()}
                        </div>

                        <div
                            css={actionsContainerStyles}
                            onMouseEnter={resetDropdownMenu}
                        >
                            {renderActionsList()}

                            {!props.renderMinimalNav && (
                                <MobileMenuButton
                                    className={mobileMenuButtonStyles}
                                    isExpanded={isMobileMenuExpanded}
                                    onClick={handleMobileMenuClick}
                                />
                            )}
                        </div>
                    </nav>

                    {!props.renderMinimalNav && renderBreadcrumbs("small")}

                    {/**
                     * The mobile menu is contained alongside the primary
                     * nav bar because on XS and S breakpoints, the mobile
                     * menu is anchored to the navigation bar, whereas
                     * supplemental content is anchored to the bottom of the viewport.
                     */}
                    {!props.renderMinimalNav && (
                        <ExpandableNav
                            Actions={props.Actions}
                            isExpanded={isMobileMenuExpanded}
                            Links={props.Links}
                            onLinkClick={handleMobileMenuClick}
                        />
                    )}
                </div>

                {/* Content that is related to, but underneath the nav bar */}
                {!props.renderMinimalNav && (
                    <div css={supplementalContainerStyles}>
                        {renderBreadcrumbs("large")}

                        {renderDropdownMenus()}

                        {props.notifications &&
                            renderNotifications &&
                            renderNotificationStack()}
                    </div>
                )}
            </div>
        );
    },
);

NavigationBar.displayName = "NavigationBar";
