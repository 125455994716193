import gsap from "gsap";
import { Expo } from "gsap";
import _ from "lodash";
import { useEffect, useState } from "react";

import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";

import { NavigationBarRef } from "@/ui/organisms/navigation_bar";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { getBreakpointFromMediaMatch } from "@/util/ui_util";

/**
 * Manages the visibility toggling for the
 * push notifications and the watch demo thumbnail.
 *
 * Also defines a callback that lets the consumer know
 * we're above the fold or not.
 */
export function useNavigationFrameVisibility(
    navigationFrameRef: TypedRefObject,
    navigationBarRef: TypedRefObject<NavigationBarRef>,
    videoContainerRef: TypedRefObject,
    visibilityListener: (isVisible: boolean) => void,
) {
    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        const ease = Expo.easeOut;

        const timeline = gsap.timeline({
            scrollTrigger: {
                onEnterBack: () => {
                    if (
                        navigationBarRef.current &&
                        navigationBarRef.current.notificationsRef.current &&
                        navigationBarRef.current.notificationsRef.current
                            .length > 0
                    ) {
                        gsap.to(
                            navigationBarRef.current.notificationsRef.current,
                            {
                                autoAlpha: 1,
                                ease,
                                scale: 1,
                            },
                        );
                    }

                    if (videoContainerRef.current) {
                        gsap.to(videoContainerRef.current, {
                            autoAlpha: 1,
                            ease,
                            scale: 1,
                        });
                    }

                    visibilityListener(true);
                },
                onLeave: () => {
                    if (
                        navigationBarRef.current &&
                        navigationBarRef.current.notificationsRef.current &&
                        navigationBarRef.current.notificationsRef.current
                            .length > 0
                    ) {
                        gsap.to(
                            navigationBarRef.current.notificationsRef.current,
                            {
                                autoAlpha: 0,
                                ease,
                                scale: 0.9,
                            },
                        );
                    }

                    if (videoContainerRef.current) {
                        gsap.to(videoContainerRef.current, {
                            autoAlpha: 0,
                            ease,
                            scale: 0.9,
                        });
                    }

                    visibilityListener(false);
                },
                trigger: navigationFrameRef.current,
            },
        });

        return () => {
            timeline.kill();
        };
    }, []);
}

/**
 * Determines if we're on an acceptable (desktop) breakpoint
 * for the watch demo video
 */
export function useDesktopDemoValidBreakpoint(
    breakpointListener: (isValidBreakpoint: boolean) => void,
) {
    /**
     * Effects
     */
    useEffect(() => {
        const determineIsValidBreakpoint = () => {
            breakpointListener(
                ["medium", "large", "extraLarge"].includes(
                    getBreakpointFromMediaMatch(),
                ),
            );
        };

        determineIsValidBreakpoint();

        const _debouncedCalculateLargestValues = _.debounce(
            determineIsValidBreakpoint,
            150,
        );

        window.addEventListener("resize", () => {
            _debouncedCalculateLargestValues();
        });
    }, []);
}

/**
 * Animates the lines in the mobile menu button
 */
export function useMobileMenuButtonAnimation(
    buttonRef: TypedRefObject,
    firstLineRef: TypedRefObject,
    secondLineRef: TypedRefObject,
    rowGap: string,
    isExpanded: boolean,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set([firstLineRef.current, secondLineRef.current], {
            onComplete: () => {
                setDidInit(true);
            },
            rotate: 0,
        });
    }, [firstLineRef, secondLineRef]);

    useEffect(() => {
        const ease = Expo.easeInOut;

        if (didInit) {
            if (isExpanded) {
                gsap.to(buttonRef.current, {
                    ease,
                    rotate: 180,
                });

                gsap.to(buttonRef.current, {
                    ease,
                    rowGap: 0,
                });

                gsap.to(firstLineRef.current, {
                    ease,
                    rotate: 45,
                    y: 0.35,
                });
                gsap.to(secondLineRef.current, {
                    ease,
                    rotate: -45,
                    y: -0.35,
                });
            } else {
                gsap.to(buttonRef.current, {
                    ease,
                    rotate: 0,
                });
                gsap.to([firstLineRef.current, secondLineRef.current], {
                    ease,
                    rotate: 0,
                });
                gsap.to(buttonRef.current, {
                    ease,
                    rowGap,
                });
            }
        }
    }, [isExpanded, buttonRef, firstLineRef, secondLineRef, rowGap, didInit]);
}

/**
 * Animates the height of the mobile menu
 */
export function useMobileMenuExpansionAnimation(
    containerRef: TypedRefObject,
    isExpanded: boolean,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(containerRef.current, {
            autoAlpha: 0,
            height: 0,
            onComplete: () => {
                setDidInit(true);
            },
            scale: 0.9,
        });
    }, [containerRef]);

    useEffect(() => {
        const ease = Expo.easeInOut;

        if (didInit) {
            if (isExpanded) {
                gsap.to(containerRef.current, {
                    autoAlpha: 1,
                    ease,
                    height: "auto",
                    scale: 1,
                });
            } else {
                gsap.to(containerRef.current, {
                    autoAlpha: 0,
                    ease,
                    height: 0,
                    scale: 0.9,
                });
            }
        }
    }, [isExpanded, containerRef, didInit]);
}

/**
 * Expands the height of nested items in the mobile menu
 */
export function useMobileNestedNavLinkExpansionAnimation(
    containerRef: TypedRefObject,
    caretRef: TypedRefObject,
    listRef: TypedRefObject,
    backgroundColor: string,
    isExpanded?: boolean,
    isParentExpanded?: boolean,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(containerRef.current, {
            background: "transparent",
        });

        if (listRef.current) {
            gsap.set(listRef.current, {
                autoAlpha: 0,
                height: 0,
                onComplete: () => {
                    setDidInit(true);
                },
                scale: 0.95,
            });
        }
    }, []);

    useEffect(() => {
        const ease = Expo.easeInOut;

        if (didInit && listRef.current) {
            if (isExpanded) {
                gsap.to(containerRef.current, {
                    background: backgroundColor,
                    ease,
                });
                gsap.to(caretRef.current, {
                    ease,
                    rotate: 90,
                });
                gsap.to(listRef.current, {
                    autoAlpha: 1,
                    ease,
                    height: "auto",
                    scale: 1,
                });
            } else {
                gsap.to(containerRef.current, {
                    background: "transparent",
                    ease,
                });
                gsap.to(caretRef.current, {
                    ease,
                    rotate: 0,
                });
                gsap.to(listRef.current, {
                    autoAlpha: 0,
                    ease,
                    height: 0,
                    scale: 0.95,
                });
            }
        }
    }, [isExpanded]);

    useEffect(() => {
        const ease = Expo.easeInOut;

        if (didInit && !isParentExpanded) {
            gsap.to(containerRef.current, {
                background: "transparent",
                ease,
            });
            gsap.to(caretRef.current, {
                ease,
                rotate: 0,
            });
            gsap.to(listRef.current, {
                autoAlpha: 0,
                ease,
                height: 0,
                scale: 0.95,
            });
        }
    }, [isParentExpanded]);
}

/**
 * Animates the presence of desktop dropdown menus
 */
export function useDropdownMenuExpansionAnimation(
    containerRef: TypedRefObject,
    listItemsRef: TypedArrayRefObject,
    isExpanded?: boolean,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        if (listItemsRef.current) {
            gsap.set(containerRef.current, {
                autoAlpha: 0,
                scale: 0.9,
            });

            gsap.set(listItemsRef.current, {
                autoAlpha: 0,
                onComplete: () => {
                    setDidInit(true);
                },
            });
        }
    }, []);

    useEffect(() => {
        const ease = Expo.easeOut;

        if (didInit) {
            if (isExpanded) {
                gsap.to(containerRef.current, {
                    autoAlpha: 1,
                    ease,
                    scale: 1,
                });
                gsap.to(listItemsRef.current, {
                    autoAlpha: 1,
                    ease,
                    stagger: 0.05,
                });
            } else {
                gsap.to(containerRef.current, {
                    autoAlpha: 0,
                    ease,
                    scale: 0.9,
                });
                gsap.to(listItemsRef.current, {
                    autoAlpha: 0,
                    ease,
                    stagger: 0.05,
                });
            }
        }
    }, [isExpanded]);
}
