/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface TableProps {
    children: string;
    className?: SerializedStyles;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
}

export const Table: FunctionComponent<TableProps> = (props) => {
    /**
     * Globals & Refs
     */
    const _wrapperRef = useRef<HTMLTableElement>(null);
    const _tableRef = useRef<HTMLTableElement>(null);
    const [margin, setMargin] = useState<number>(0);

    /**
     * Interactivity
     */
    const handleResize = () => {
        if (_wrapperRef.current && _tableRef.current) {
            const _bRect = _wrapperRef.current.getBoundingClientRect();
            const _tRect = _tableRef.current.getBoundingClientRect();
            if (_tRect.width > _bRect.width) {
                const margin = (window.innerWidth - _bRect.width) / 2;
                setMargin(margin);
            }
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /**
     * Styles
     */
    const marginTopStyles = props.marginTop
        ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
        : undefined;

    const marginBottomStyles = props.marginBottom
        ? buildStylesByBreakpoint(
              "marginBottom",
              spacingSets[props.marginBottom],
          )
        : undefined;

    /**
     * Elements
     */
    return (
        <div css={css(marginBottomStyles, marginTopStyles)} ref={_wrapperRef}>
            <div
                css={css(
                    {
                        marginLeft: `-${margin}px`,
                        marginRight: `-${margin}px`,
                        overflowX: "auto",
                        paddingLeft: `${margin}px`,
                        paddingRight: `${margin}px`,
                        width: "100%",
                    },
                    {
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        msOverflowStyle: "none" /* IE and Edge */,
                        scrollbarWidth: "none" /* Firefox */,
                    },
                )}
            >
                <table
                    css={css({
                        borderCollapse: "separate",
                        borderSpacing: Spacing["spacing-0"],
                        paddingRight: `${margin}px`,
                    })}
                    ref={_tableRef}
                >
                    {props.children}
                </table>
            </div>
        </div>
    );
};
