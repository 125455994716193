/* eslint-disable sort-keys-custom-order/object-keys */
import { BreakpointOrBreakpointObject } from "@/types/tokens/breakpoints";

import { FontSize } from "@/tokens/typography";

/**
 * This is where you define font sizes.
 *
 * Please do not hardcode font sizes and stick to the system.
 */
export type FontStyle = BreakpointOrBreakpointObject<FontSize>;
export type FontStyleSlug = FontStyle | FontStyleSet;

export type FontStyleSet =
    // Headlines
    | "HeadlineDefault"
    | "HeadlineSmall"

    // Text
    | "TextDefault"
    | "TextSmall"

    // Longform Headlines and Text
    | "LongformHeadlineLarge"
    | "LongformHeadlineDefault"
    | "LongformTextDefault"

    // Buttons
    | "Button"

    // Labels
    | "LabelDefault"
    | "EyeBrow"
    | "LabelLarge"

    // Contact Page
    | "ContactHeadlineSecondary"
    | "ContactCTA"

    // Compare Page
    | "CompareHeroHeadline"
    | "CompareHeroHeadlineSecondary"
    | "CompareHeroPrimaryCta"
    | "CompareHeroSecondaryCta"
    | "ComparePageTableHeadline"
    | "ComparePageTableLinks"
    | "CompareMatrixRowTitle"
    | "CompareMatrixRowContent"
    | "CompareMatrixHeaderRowTitle"
    | "CompareSlugFaqHeadline"

    // Download Page
    | "DownloadButtonCTA"
    | "DownloadGroupComingSoonText"
    | "DownloadMobileCardTitle"
    | "DownloadMobileFormButton"
    | "DownloadMobileCardSubtitle"
    | "DownloadPrimaryButtonHeading"
    | "DownloadPrimaryButtonSubheading"
    | "DownloadSecondaryButton"
    | "DownloadWaitlistOverviewItemTitle"

    // Pricing Cards
    | "PlanCardTitle"
    | "PlanCardPrice"
    | "PlanCardDetails"

    // Pricing Matrix
    | "PricingMatrixPlanName"
    | "PricingMatrixPlanPricingMain"
    | "PricingMatrixPlanPricingDesc"
    | "PricingMatrixPlanPricingMonthly"
    | "PricingMatrixNavHeadline"

    // Privacy Values
    | "PrivacyOverviewItemTitle"
    | "PrivacyIntroductionHeadline"

    // FAQ Values
    | "FaqItem"

    // Product Hero
    | "ProductHeroSubheadline"
    | "ProductHeroPrimaryCta"
    | "ProductHeroSecondaryCta"

    // Blog
    | "FeaturedArticleHeadline"
    | "BlogTopic"
    | "BlogArticleCardTitle"
    | "BlogArticleCardAuthors"
    | "BlogArchiveSectionTitle"
    | "ArticleHeadline"
    | "BlogArchiveListRowTitle"

    // Terminus
    | "TerminusArticleListHeadline"
    | "TerminusArticleCardTitle"
    | "TerminusFeaturedArticleCardTitle"
    | "TerminusFeaturedArticleCardDescription"
    | "TerminusTopicListHeadline"
    | "TerminusTopicChip"
    | "TerminusSidebarAdHeadline"
    | "TerminusSidebarAdSubheadline"
    | "TerminusHeroHeadline"
    | "TerminusHeroDescription"
    | "TerminusArticleTitle"
    | "TerminusPublishedDate"
    | "TerminusArchiveSectionTitle"
    | "TerminusArchiveListRowTitle"
    | "TerminusArticleHeading1"
    | "TerminusArticleHeading2"
    | "TerminusArticleHeading3"
    | "TerminusArticleHeading4"
    | "TerminusArticleHeading5"
    | "TerminusArticleHeading6"
    | "TerminusArticleBody"
    | "TerminusQuestionIcon"
    | "TerminusPubDate"

    // Notification Bar
    | "NotificationToastText"

    // Navigation
    | "MobileSubnavLink"
    | "MobileSubnavTitle"

    // About Values
    | "AboutLargeHeadline"
    | "AboutHeadline"
    | "AboutSubHeadline"
    | "AboutTeamIcons"
    | "AboutTeamMemberHeading"
    | "AboutInvestorName"

    // All Features
    | "AllFeaturesTableButton"
    | "AllFeaturesTableHeadline"
    | "AllFeaturesTable"

    /*
     * Agent Mode
     */
    | "AgentModeHeadline"
    | "AgentModeSubheadline"
    | "AgentModeCaption"
    | "AgentModeVideoRowHeading2"
    | "AgentModeVideoRowHeading3"

    // Legacy Benefit Item
    | "LegacyBenefitItemHeadline"
    | "LegacyBenefitItemDescription"

    // Legacy Features Section
    | "LegacyFeaturesHeroHeadline"
    | "LegacyFeaturesHeroSubheadline"

    // Legacy Feature Item (Horizontal)
    | "LegacyFeatureHorizontalItemEyebrow"
    | "LegacyFeatureHorizontalItemHeadline"
    | "LegacyFeatureHorizontalItemSubheadline"
    | "LegacyFeatureHorizontalItemLink"

    // Legacy Feature Item (Vertical)
    | "LegacyFeatureVerticalItemEyebrow"
    | "LegacyFeatureVerticalItemHeadline"
    | "LegacyFeatureVerticalItemSubheadline"
    | "LegacyFeatureVerticalItemLink"

    // Legacy Testimonial
    | "LegacyTestimonialQuote"
    | "LegacyTestimonialAttribution"

    // Legacy Tweet Grid
    | "LegacyTweetGridTweet"

    // Legacy Big Tile Grid
    | "LegacyBigTileGridLabel"

    // Careers
    | "CareerHeadline"
    | "CareerSubheadline"
    | "CareerDefaultText"
    | "CareerCaptionText"
    | "CareerCaptionText"

    // State of CLI 2023
    | "StateOfCliTerminal"
    | "StateOfCliCopyright"

    // Article
    | "ArticleHeading1"
    | "ArticleHeading2"
    | "ArticleHeading3"
    | "ArticleHeading4"
    | "ArticleHeading5"
    | "ArticleHeading6"
    | "ArticleBody"
    | "ArticleQuote"
    | "ArticleQuoteAuthor"
    | "ArticleCode"
    | "ArticleCodeCTA"

    // Standardized T,ype Configs (Sep 2024)
    | "HeadlineHero"
    | "HeadlineHeroXl"
    | "Headline1"
    | "Headline2"
    | "Headline3"
    | "Subheadline"
    | "Default"
    | "Small"
    | "Micro"
    | "Quote"
    | "TechnicalText"
    | "Attribution"
    | "BillboardHeadlineCarouselNumber"
    | "CtaHero"
    | "CtaDefault"

    // Component-Scoped Type Configs (Sep 2024)
    | "ScrollableList"
    | "DownloadTitle"

    // Nav (Sep 2024)
    | "NavText"
    | "NestedNavText"
    | "TouchNavText"
    | "Breadcrumbs"

    // Modals (Sep 2024)
    | "ModalTitle"
    | "ModalText";

export const fontStyles: {
    [key in FontStyleSet]: FontStyle;
} = {
    /*
     * Headlines
     */
    HeadlineDefault: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    HeadlineSmall: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
    },

    /*
     * Longform Headlines & Text
     */
    LongformHeadlineLarge: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
        large: "TEXT_3",
    },
    LongformHeadlineDefault: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
    },
    LongformTextDefault: {
        extraSmall: "TEXT_1",
        medium: "TEXT_1125",
    },

    /*
     * Text
     */
    TextDefault: {
        extraSmall: "TEXT_1125",
    },

    TextSmall: {
        extraSmall: "TEXT_1",
    },

    /*
     * Labels
     */
    LabelDefault: {
        extraSmall: "TEXT_0875",
    },
    EyeBrow: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    LabelLarge: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
        large: "TEXT_1125",
    },

    /*
     * Buttons
     */
    Button: {
        extraSmall: "TEXT_0625",
        small: "TEXT_075",
        medium: "TEXT_1",
        large: "TEXT_125",
        extraLarge: "TEXT_150",
    },

    /**
     * Download page
     */
    DownloadButtonCTA: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    DownloadGroupComingSoonText: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    DownloadMobileCardTitle: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
    },
    DownloadMobileCardSubtitle: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },
    DownloadMobileFormButton: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    DownloadPrimaryButtonHeading: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    DownloadPrimaryButtonSubheading: {
        extraSmall: "TEXT_075",
        large: "TEXT_0875",
    },
    DownloadSecondaryButton: {
        extraSmall: "TEXT_0875",
    },
    DownloadWaitlistOverviewItemTitle: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },

    /*
     * Contact Page
     */
    ContactHeadlineSecondary: {
        extraSmall: "TEXT_2",
        large: "TEXT_3",
    },
    ContactCTA: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },

    /*
     * Compare Page
     */
    CompareHeroHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    CompareHeroHeadlineSecondary: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
        large: "TEXT_150",
    },
    CompareHeroPrimaryCta: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
    },
    CompareHeroSecondaryCta: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },
    ComparePageTableHeadline: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
        large: "TEXT_3",
    },
    ComparePageTableLinks: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    CompareMatrixRowTitle: {
        extraSmall: "TEXT_1",
    },
    CompareMatrixRowContent: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },
    CompareMatrixHeaderRowTitle: {
        extraSmall: "TEXT_1125",
    },
    CompareSlugFaqHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_4",
    },

    /*
     * Pricing Cards
     */
    PlanCardTitle: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
    },
    PlanCardPrice: {
        extraSmall: "TEXT_125",
        medium: "TEXT_150",
    },
    PlanCardDetails: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },

    /*
     * Pricing Matrix
     */
    PricingMatrixPlanName: {
        extraSmall: "TEXT_150",
        medium: "TEXT_125",
        large: "TEXT_150",
    },
    PricingMatrixNavHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_150",
        large: "TEXT_2",
    },
    PricingMatrixPlanPricingMain: {
        extraSmall: "TEXT_125",
        medium: "TEXT_1",
        large: "TEXT_125",
    },
    PricingMatrixPlanPricingDesc: {
        extraSmall: "TEXT_1",
        medium: "TEXT_0875",
        large: "TEXT_1",
    },
    PricingMatrixPlanPricingMonthly: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_075",
        large: "TEXT_0875",
    },

    /*
     * Privacy Values
     */
    PrivacyOverviewItemTitle: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    PrivacyIntroductionHeadline: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
        large: "TEXT_3",
    },

    /**
     * FAQ Page
     */
    FaqItem: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },

    /**
     * Product Hero
     */
    ProductHeroSubheadline: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
    },
    ProductHeroPrimaryCta: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
    },
    ProductHeroSecondaryCta: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },

    /**
     * Blog
     */
    FeaturedArticleHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
    },
    BlogTopic: {
        extraSmall: "TEXT_0875",
    },
    BlogArticleCardTitle: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
    },
    BlogArticleCardAuthors: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    BlogArchiveSectionTitle: {
        extraSmall: "TEXT_2",
    },
    ArticleHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },

    /**
     * Blog
     */
    BlogArchiveListRowTitle: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },

    /**
     * Terminus
     */
    TerminusArticleListHeadline: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    TerminusArticleCardTitle: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },
    TerminusFeaturedArticleCardTitle: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
        large: "TEXT_150",
    },
    TerminusFeaturedArticleCardDescription: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    TerminusTopicListHeadline: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    TerminusTopicChip: {
        extraSmall: "TEXT_0625",
        large: "TEXT_075",
    },
    TerminusSidebarAdHeadline: {
        extraSmall: "TEXT_125",
        medium: "TEXT_1125",
    },
    TerminusSidebarAdSubheadline: {
        extraSmall: "TEXT_1",
        medium: "TEXT_0875",
    },
    TerminusHeroHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    TerminusHeroDescription: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    TerminusArticleTitle: {
        extraSmall: "TEXT_250",
        large: "TEXT_3",
    },
    TerminusPublishedDate: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    TerminusArchiveSectionTitle: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    TerminusArchiveListRowTitle: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },
    TerminusArticleHeading1: {
        extraSmall: "TEXT_2",
        large: "TEXT_3",
    },
    TerminusArticleHeading2: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    TerminusArticleHeading3: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    TerminusArticleHeading4: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    TerminusArticleHeading5: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    TerminusArticleHeading6: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    TerminusArticleBody: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    TerminusQuestionIcon: {
        extraSmall: "TEXT_0625",
    },
    TerminusPubDate: {
        extraSmall: "TEXT_0625",
    },

    /**
     * Notification Bar
     */
    NotificationToastText: {
        extraSmall: "TEXT_075",
        medium: "TEXT_0875",
    },

    /**
     * Navigation
     */
    MobileSubnavLink: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },
    MobileSubnavTitle: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
    },

    /**
     * About Page
     */
    AboutLargeHeadline: {
        extraSmall: "TEXT_3",
        medium: "TEXT_4",
        large: "TEXT_6",
    },
    AboutHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    AboutSubHeadline: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_150",
    },
    AboutTeamIcons: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_1",
        large: "TEXT_1125",
    },
    AboutTeamMemberHeading: {
        extraSmall: "TEXT_1",
        medium: "TEXT_0875",
        large: "TEXT_1",
    },
    AboutInvestorName: {
        extraSmall: "TEXT_1125",
    },

    /**
     * All Features
     */
    AllFeaturesTableButton: {
        extraSmall: "TEXT_1",
    },
    AllFeaturesTableHeadline: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
        large: "TEXT_3",
    },
    AllFeaturesTable: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },

    /**
     * Agent Mode Page
     */
    AgentModeHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    AgentModeSubheadline: {
        extraSmall: "TEXT_125",
    },
    AgentModeCaption: {
        extraSmall: "TEXT_1",
    },
    AgentModeVideoRowHeading2: {
        extraSmall: "TEXT_2",
        large: "TEXT_3",
    },
    AgentModeVideoRowHeading3: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },

    /**
     * Legacy Benefit Item
     */
    LegacyBenefitItemHeadline: {
        extraSmall: "TEXT_1125",
        small: "TEXT_1",
        large: "TEXT_125",
    },
    LegacyBenefitItemDescription: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },

    /**
     * Legacy Features Section
     */
    LegacyFeaturesHeroHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    LegacyFeaturesHeroSubheadline: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_125",
    },

    /**
     * Legacy Feature Item (Horizontal)
     */
    LegacyFeatureHorizontalItemEyebrow: {
        extraSmall: "TEXT_1",
    },
    LegacyFeatureHorizontalItemHeadline: {
        extraSmall: "TEXT_150",
        medium: "TEXT_2",
        large: "TEXT_3",
    },
    LegacyFeatureHorizontalItemSubheadline: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_1125",
        large: "TEXT_1125",
    },
    LegacyFeatureHorizontalItemLink: {
        extraSmall: "TEXT_1125",
        medium: "TEXT_1125",
        large: "TEXT_1125",
    },

    /**
     * Legacy Feature Item (Vertical)
     */
    LegacyFeatureVerticalItemEyebrow: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    LegacyFeatureVerticalItemHeadline: {
        extraSmall: "TEXT_1",
        large: "TEXT_125",
    },
    LegacyFeatureVerticalItemSubheadline: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    LegacyFeatureVerticalItemLink: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },

    /**
     * Legacy Testimonial
     */
    LegacyTestimonialQuote: {
        extraSmall: "TEXT_2",
        medium: "TEXT_2",
        large: "TEXT_3",
    },
    LegacyTestimonialAttribution: {
        extraSmall: "TEXT_1",
    },

    /**
     * Legacy Tweet Grid
     */
    LegacyTweetGridTweet: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },

    /**
     * Legacy Big Tile Grid
     */
    LegacyBigTileGridLabel: {
        medium: "TEXT_4",
        large: "TEXT_8",
    },

    /**
     * Careers
     */
    CareerHeadline: {
        extraSmall: "TEXT_2",
        medium: "TEXT_3",
        large: "TEXT_4",
    },
    CareerSubheadline: {
        extraSmall: "TEXT_125",
        large: "TEXT_150",
    },
    CareerDefaultText: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    CareerCaptionText: {
        extraSmall: "TEXT_0875",
    },

    /**
     * Article
     */
    ArticleHeading1: {
        extraSmall: "TEXT_2",
        large: "TEXT_3",
    },
    ArticleHeading2: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    ArticleHeading3: {
        extraSmall: "TEXT_125",
        large: "TEXT_150",
    },
    ArticleHeading4: {
        extraSmall: "TEXT_1125",
    },
    ArticleHeading5: {
        extraSmall: "TEXT_1125",
    },
    ArticleHeading6: {
        extraSmall: "TEXT_1125",
    },
    ArticleBody: {
        extraSmall: "TEXT_1125",
        large: "TEXT_125",
    },
    ArticleQuote: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },
    ArticleQuoteAuthor: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
    },
    ArticleCode: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    ArticleCodeCTA: {
        extraSmall: "TEXT_1",
    },

    /**
     * Standardized Type Configs (Sep 2024)
     */
    HeadlineHero: {
        extraSmall: "TEXT_2",
        small: "TEXT_250",
        medium: "TEXT_3",
        large: "TEXT_350",
        extraLarge: "TEXT_4",
    },
    HeadlineHeroXl: {
        extraSmall: "TEXT_3",
        small: "TEXT_350",
        medium: "TEXT_5",
        extraLarge: "TEXT_6",
    },
    Headline1: {
        extraSmall: "TEXT_150",
        small: "TEXT_2",
        large: "TEXT_250",
        extraLarge: "TEXT_3",
    },
    Headline2: {
        extraSmall: "TEXT_125",
        small: "TEXT_150",
        large: "TEXT_2",
        extraLarge: "TEXT_250",
    },
    Headline3: {
        extraSmall: "TEXT_1",
        small: "TEXT_125",
        extraLarge: "TEXT_150",
    },

    Subheadline: {
        extraSmall: "TEXT_1",
        small: "TEXT_1125",
        large: "TEXT_125",
        extraLarge: "TEXT_1375",
    },
    Default: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
        extraLarge: "TEXT_125",
    },
    Small: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
        extraLarge: "TEXT_1125",
    },
    Micro: {
        extraSmall: "TEXT_0875",
        extraLarge: "TEXT_1",
    },
    Quote: {
        extraSmall: "TEXT_125",
        small: "TEXT_150",
        extraLarge: "TEXT_2",
    },

    TechnicalText: {
        extraSmall: "TEXT_075",
        extraLarge: "TEXT_0875",
    },

    Attribution: {
        extraSmall: "TEXT_0875",
        small: "TEXT_1",
        extraLarge: "TEXT_1125",
    },

    BillboardHeadlineCarouselNumber: {
        extraSmall: "TEXT_0625",
        large: "TEXT_075",
    },
    CtaHero: {
        extraSmall: "TEXT_1",
        large: "TEXT_1125",
        extraLarge: "TEXT_125",
    },
    CtaDefault: {
        extraSmall: "TEXT_1",
        extraLarge: "TEXT_1125",
    },

    /**
     * Component-Scoped Type Configs (Sep 2024)
     */
    ScrollableList: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
        extraLarge: "TEXT_250",
    },
    DownloadTitle: {
        extraSmall: "TEXT_150",
        large: "TEXT_2",
    },

    /**
     * Nav (Sep 2024)
     */
    NavText: {
        extraSmall: "TEXT_0875",
        large: "TEXT_1",
    },
    NestedNavText: {
        extraSmall: "TEXT_0875",
        small: "TEXT_1",
    },
    TouchNavText: {
        extraSmall: "TEXT_1125",
        small: "TEXT_125",
    },
    Breadcrumbs: {
        extraSmall: "TEXT_075",
        small: "TEXT_0875",
    },

    /**
     * Modals (Sep 2024)
     */
    ModalTitle: {
        extraSmall: "TEXT_125",
        small: "TEXT_150",
        large: "TEXT_2",
    },
    ModalText: {
        extraSmall: "TEXT_0875",
        small: "TEXT_1",
    },

    /**
     * State of CLI 2023
     */
    StateOfCliTerminal: {
        extraSmall: "TEXT_075",
        medium: "TEXT_0875",
    },
    StateOfCliCopyright: {
        extraSmall: "TEXT_0875",
        medium: "TEXT_1",
    },
};
