import DOMPurify from "dompurify";
import isEmail from "validator/lib/isEmail";

import {
    HubSpotForm,
    HubSpotFormFieldGroup,
    HubSpotFormSubmissionResponse,
    HubSpotSubmissionField,
    HubSpotSubmissionForm,
    NestedSubmissionForm,
} from "@/types/forms";

import { HubspotFormValues } from "@/ui/molecules/contact_form";
import { NestedFormValue } from "@/ui/molecules/nested_form";

export async function getHubSpotForm(formId: string): Promise<HubSpotForm> {
    const url = `https://api.hubapi.com/forms/v2/forms/${formId}`;

    try {
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${process.env.HUBSPOT_FORMS_ACCESS_TOKEN}`,
                "Content-Type": "application/json",
            },
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(
                `Error fetching form via getHubSpotForm: ${response.status} ${response.statusText}`,
            );
        }

        const formData = await response.json();

        return formData;
    } catch (error) {
        console.error("Error in getHubSpotForm: ", error);

        throw error;
    }
}
export async function submitHubSpotForm(
    portalId: string,
    formId: string,
    formData: HubSpotSubmissionForm,
): Promise<HubSpotFormSubmissionResponse> {
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    try {
        const response = await fetch(url, {
            body: JSON.stringify(formData),
            headers: {
                Authorization: `Bearer ${process.env.HUBSPOT_FORMS_ACCESS_TOKEN}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        });

        if (!response.ok) {
            console.error(
                `Error submitting form via submitHubSpotForm: ${response.status} ${response.statusText}`,
            );

            return {
                errors: [
                    {
                        errorType: "FORM_SUBMISSION_ERROR",
                        message:
                            "There was an error submitting your form. Please try again.",
                    },
                ],
                inlineMessage: "Form submitted successfully",
            } as HubSpotFormSubmissionResponse;
        }

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.error("Error in submitHubSpotForm: ", error);

        throw error;
    }
}

export async function submitNestedForm({
    data,
    endpoint,
    name,
}: {
    data: NestedSubmissionForm;
    endpoint: string;
    name: string;
}): Promise<unknown> {
    const res = await fetch(endpoint, {
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
    });

    if (!res.ok) {
        throw new Error(
            `Error submitting the form: ${name} [${res.status}]. ${res.statusText}`,
        );
    }

    return res;
}

export function isValidInputLength({
    input,
    maxLength,
    minLength,
}: {
    input: string;
    maxLength: number;
    minLength: number;
}) {
    return input.length >= minLength && input.length <= maxLength;
}

export function validateAndSanitizeSelectInput({
    enumOptions,
    input,
}: {
    enumOptions: string[];
    input: string;
}): {
    cleanValue: string;
    errorMessage?: string;
    isValid: boolean;
} {
    return {
        cleanValue: DOMPurify.sanitize(input.trim()),
        errorMessage: "Please select an option",
        isValid: enumOptions.includes(input),
    };
}

export function validateAndSanitizeTextInput({
    fieldType,
    input,
    name,
    required,
}: {
    fieldType: "text" | "textarea" | string;
    input: string;
    name: string;
    required: boolean;
}): {
    errorMessage?: string;
    isValid: boolean;
    value: string;
} {
    const maxLength = fieldType === "textarea" ? 5000 : 256;

    const isValidLength = isValidInputLength({
        input,
        maxLength,
        minLength: required ? 1 : 0,
    });

    const isValidEmail = name.includes("email") ? isEmail(input) : true;

    if (!required) {
        return {
            errorMessage: name.includes("email")
                ? "Please enter a valid email"
                : `Please enter a value less than ${maxLength} characters`,
            isValid: isValidLength && isValidEmail,
            value: DOMPurify.sanitize(input.trim()),
        };
    }

    return {
        errorMessage: name.includes("email")
            ? "Please enter a valid email"
            : `Please enter a value less than ${maxLength} characters`,
        isValid: isValidLength && isValidEmail,
        value: DOMPurify.sanitize(input.trim()),
    };
}

export function processNestedFormDataOnSubmit(data: NestedFormValue) {
    const userErrors = Object.entries(data).reduce(
        (acc: { key?: string; message?: string }[] | null, [key, value]) => {
            const validatedAndSanitizedData = validateAndSanitizeTextInput({
                fieldType: "text",
                input: value,
                name: key,
                required: true,
            });

            if (validatedAndSanitizedData.isValid) {
                return acc;
            } else {
                return [
                    ...(acc || []),
                    { key, message: validatedAndSanitizedData.errorMessage },
                ];
            }
        },
        null,
    );

    const fields = Object.entries(data).map(([key, value]) => {
        return {
            name: key,
            value: DOMPurify.sanitize(value.trim()),
        };
    });

    return {
        field: {
            name: fields[0].name,
            value: fields[0].value,
        },
        userErrors,
    };
}

export function processHubspotFormDataOnSubmit({
    data,
    inputs,
}: {
    data: HubspotFormValues;
    inputs: HubSpotFormFieldGroup[];
}): {
    fields: HubSpotSubmissionField[];
    userErrors: { key?: string; message?: string }[] | null;
} {
    const userErrors = Object.entries(data).reduce(
        (acc: { key?: string; message?: string }[] | null, [key, value]) => {
            const { fieldType, options, required } = inputs.filter((_input) => {
                return _input.fields[0].name === key;
            })[0].fields[0];

            const validatedAndSanitizedData =
                fieldType === "select"
                    ? validateAndSanitizeSelectInput({
                          enumOptions: options.map((o) => o.value),
                          input: value,
                      })
                    : validateAndSanitizeTextInput({
                          fieldType,
                          input: value,
                          name: key,
                          required,
                      });

            if (validatedAndSanitizedData.isValid) {
                return acc;
            } else {
                return [
                    ...(acc || []),
                    { key, message: validatedAndSanitizedData.errorMessage },
                ];
            }
        },
        null,
    );

    const fields = Object.entries(data).map(([key, value]) => {
        return {
            name: key,
            objectTypeId: inputs.filter((_input) => {
                return _input.fields[0].name === key;
            })[0].fields[0].objectTypeId,
            value: DOMPurify.sanitize(value.trim()),
        };
    });

    return {
        fields,
        userErrors,
    };
}
