/** @jsxImportSource @emotion/react */
import { IconBase, IconProps, IconWeight } from "@phosphor-icons/react";
import { ReactElement, forwardRef } from "react";

const weights = new Map<IconWeight, ReactElement>([
    [
        "regular",
        <>
            <path
                d="M129.315 44H206.986C219.559 44 229.751 54.5323 229.751 67.5244V158.854C229.751 171.847 219.559 182.379 206.986 182.379H95.8362L129.315 44Z"
                fill="currentColor"
            />

            <path
                d="M109.14 70.2157H46.5715C34.1056 70.2157 24 80.748 24 93.7401V185.07C24 198.062 34.1056 208.594 46.5715 208.594H123.58L126.668 195.721H79.035L109.14 70.2157Z"
                fill="currentColor"
            />
        </>,
    ],
    [
        "bold",
        <>
            <path
                d="M129.315 44H206.986C219.559 44 229.751 54.5323 229.751 67.5244V158.854C229.751 171.847 219.559 182.379 206.986 182.379H95.8362L129.315 44Z"
                fill="currentColor"
            />

            <path
                d="M109.14 70.2157H46.5715C34.1056 70.2157 24 80.748 24 93.7401V185.07C24 198.062 34.1056 208.594 46.5715 208.594H123.58L126.668 195.721H79.035L109.14 70.2157Z"
                fill="currentColor"
            />
        </>,
    ],
]);
export const LogoIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
    <IconBase ref={ref} {...props} weights={weights} />
));
export default LogoIcon;
LogoIcon.displayName = "LogoIcon";
