/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import NextLink from "next/link";
import { MouseEventHandler, forwardRef } from "react";

import { StrapiNavGroupLink } from "@/types/strapi";

import { BorderRadius, BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { formatUrl } from "@/util/data_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

import { Icon } from "./icon";
import { Text } from "./text";

interface ChildNavLinkProps extends StrapiNavGroupLink {
    borderRadius?: BorderRadius;
    className?: SerializedStyles;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const ChildNavLink = forwardRef<HTMLAnchorElement, ChildNavLinkProps>(
    (props, ref) => {
        /**
         * Variables
         */
        const theme = useTypedTheme();

        /**
         * Styles
         */
        const containerStyles = css(
            {
                alignItems: props.Description ? "flex-start" : "center",
                background: "transparent",
                borderRadius: props.borderRadius
                    ? BorderRadiuses[props.borderRadius]
                    : undefined,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textDecoration: "none",
                transition: `background ${TransitionSpeeds.fast}ms ${TransitionEasings.linear}`,
            },
            {
                "&:hover": {
                    background: Colors[theme.backgrounds.backgroundTertiary],
                },
            },
            buildStylesByBreakpoint("padding", {
                extraSmall: `${Spacing["spacing-2"]} 0`,
                medium: Spacing["spacing-2"],
            }),
            props.className,
        );

        const textContainerStyles = css({
            display: "flex",
            flexDirection: "column",
        });

        /**
         * We only show this on M–XL screens
         */
        const iconStyles = buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "flex",
        });

        /**
         * Rendering
         */
        return (
            <NextLink
                css={containerStyles}
                href={formatUrl(props.URL)}
                ref={ref}
                onClick={props.onClick}
            >
                <div css={textContainerStyles}>
                    <Text
                        fontSize="NestedNavText"
                        fontWeight="medium"
                        themeKey="headlinePrimary"
                    >
                        {props.Text}
                    </Text>

                    {props.Description && (
                        <Text fontSize="Micro" themeKey="textSecondary">
                            {props.Description}
                        </Text>
                    )}
                </div>

                <Icon
                    className={iconStyles}
                    color={theme.ui.uiInactive}
                    size={12}
                    slug="Caret Right"
                />
            </NextLink>
        );
    },
);

ChildNavLink.displayName = "ChildNavLink";
