/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React, { FunctionComponent } from "react";

import { StrapiQuotePrimitive } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { generateFontStyles } from "@/util/tokens/typography_util";

import { Picture } from "./picture";

interface StrapiQuoteProps extends Partial<StrapiQuotePrimitive> {
    children?: React.ReactNode;
    className?: SerializedStyles;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
}

export const Quote: FunctionComponent<StrapiQuoteProps> = (props) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const citationWrapperStyle = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        marginTop: Spacing["spacing-6"],
    });

    const authorHeadshotStyle = css({
        borderRadius: BorderRadiuses["borderRound"],
        marginRight: Spacing["spacing-2"],
        width: Spacing["spacing-8"],
    });

    const blockQuoteStyle = css(
        {
            marginLeft: Spacing["spacing-6"],
            paddingTop: props.children ? 0 : Spacing["spacing-4"],
        },
        {
            ":has(blockquote), & blockquote": {
                "& > div": {
                    marginBottom: 0,
                },
                paddingBottom: 0,
            },
        },
        {
            "&:not(:has(blockquote))": {
                paddingBottom: Spacing["spacing-4"],
            },
        },
        {
            /**
             * Because of how the markdown parser works,
             * it is very difficult to apply styles to
             * children. This selector works well.
             */
            "& > p": css(generateFontStyles("ArticleQuote", {})),
        },
    );

    const marginTopStyles = props.marginTop
        ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
        : undefined;

    const marginBottomStyles = props.marginBottom
        ? buildStylesByBreakpoint(
              "marginBottom",
              spacingSets[props.marginBottom],
          )
        : undefined;

    /**
     * Rendering
     */
    const renderStrapiQuote = () => (
        <>
            <Text fontSize="ArticleQuote">{props.Quote}</Text>

            {props.Author && (
                <Text className={citationWrapperStyle} tag="div">
                    {props.Author.Headshot && (
                        <Picture
                            aspectRatio="1 / 1"
                            borderRadius="borderRound"
                            className={authorHeadshotStyle}
                            {...props.Author.Headshot}
                        />
                    )}

                    {props.Author.Name && (
                        <Text
                            color={theme.text.textSecondary}
                            fontSize="ArticleQuoteAuthor"
                            tag="cite"
                        >
                            {props.Author.Name}
                        </Text>
                    )}
                </Text>
            )}
        </>
    );

    return (
        <div
            css={css(
                {
                    borderLeft: `2px solid ${Colors["lighten-40"]}`,
                    display: "flex",
                    flexDirection: "row",
                },
                marginBottomStyles,
                marginTopStyles,
            )}
        >
            <Text
                className={blockQuoteStyle}
                fontSize="ArticleQuote"
                tag="blockquote"
            >
                {props.children ? props.children : renderStrapiQuote()}
            </Text>
        </div>
    );
};
