import type { ApiObject, RudderAnalytics } from "@rudderstack/analytics-js";
import * as _ from "lodash-es";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { AnalyticsTrack } from "@/types/analytics";

import { getCookieValue } from "@/util/cookie_util";

/**
 * useRudderAnalytics returns an instance of RudderAnalytics
 * if it has been initialized.
 *
 * If not, it asynchronously initializes the SDK and provides the instance
 * once ready.
 *
 * @returns {RudderAnalytics | undefined} The initialized RudderAnalytics instance,
 * or `undefined` if still initializing or failed.
 */
export function useRudderAnalytics(): RudderAnalytics | undefined {
    const [analytics, setAnalytics] = useState<RudderAnalytics>();

    useEffect(() => {
        if (!analytics) {
            initializeSDK().then(setAnalytics, (error) => console.error(error));
        }
    }, [analytics]);

    return analytics;
}

/**
 * initializeSDK initalizes the RudderStack SDK and returns
 * an instance of RudderAnalytics.
 *
 * This function will load the RudderStack SDK, create an analytics instance,
 * and configure it with the necessary write key and data plane URL.
 *
 * The initialization process is wrapped in `_.once` to ensure that the SDK
 * is only initialized a single time during the application's lifecycle,
 * preventing redundant SDK instances and potential configuration conflicts.
 *
 * @returns {Promise<RudderAnalytics>} A promise that resolves to the initialized RudderAnalytics instance.
 * @throws {Error} If the required environment variable is missing.
 */
const initializeSDK = _.once(
    async function initializeSDK(): Promise<RudderAnalytics> {
        const { RudderAnalytics } = await import("@rudderstack/analytics-js");

        const analytics = new RudderAnalytics();

        const writeKey = process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY;

        if (!writeKey) {
            throw new Error(
                "Initialization failed: NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY environment variable is missing or undefined.",
            );
        }

        const dataPlaneUrl = process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL;

        if (!dataPlaneUrl) {
            throw new Error(
                "Initialization failed: NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL environment variable is missing or undefined.",
            );
        }

        const sdkBaseUrl = process.env.NEXT_PUBLIC_RUDDERSTACK_SDK_BASE_URL;

        if (!sdkBaseUrl) {
            throw new Error(
                "Initialization failed: NEXT_PUBLIC_RUDDERSTACK_SDK_BASE_URL environment variable is missing or undefined.",
            );
        }

        analytics.load(writeKey, dataPlaneUrl, {
            anonymousIdOptions: {
                autoCapture: { enabled: true, source: "segment" },
            },
            destSDKBaseURL: `${sdkBaseUrl}/js-integrations`,
            pluginsSDKBaseURL: `${sdkBaseUrl}/plugins`,
        });

        return analytics;
    },
);

/**
 * Provides a function to retrieve the `anonymousId` from RudderAnalytics,
 * decoded from the browser's `rl_anonymous_id` cookie.
 *
 * If unavailable, it attempts to retrieve a legacy `anonymousId` from
 * the Segment (`ajs_anonymous_id`) cookie as a fallback.
 *
 * @returns {() => string | undefined} A function that returns the RudderAnalytics
 * or Segment `anonymousId`, or `undefined` if neither is available.
 */
export function useRudderAnalyticsAnonymousId(): () => string | undefined {
    const analytics = useRudderAnalytics();
    return useCallback(
        () => analytics?.getAnonymousId() ?? getCookieValue("ajs_anonymous_id"),
        [analytics],
    );
}

export function useRudderAnalyticsTrack(): (data: AnalyticsTrack) => void {
    const analytics = useRudderAnalytics();

    return useCallback(
        (data) => {
            if (!analytics) {
                throw new Error(
                    "Tracking failed: RudderAnalytics instance is not initialized.",
                );
            }

            analytics.track(data.Event, parseProperties(data.Properties));
        },
        [analytics],
    );
}

function parseProperties(
    properties: AnalyticsTrack["Properties"],
): ApiObject | undefined {
    if (properties == null) {
        return;
    }
    if (Array.isArray(properties)) {
        throw new TypeError(
            "Invalid 'Properties' field: expected an object, but received an array.",
        );
    }
    if (typeof properties !== "object") {
        throw new TypeError(
            `Invalid 'Properties' field: expected an object, but received a ${typeof properties}.`,
        );
    }
    return properties as ApiObject;
}

export function useRudderAnalyticsPageView() {
    const router = useRouter();

    const analytics = useRudderAnalytics();

    useEffect(() => {
        const handlePageView = () => {
            analytics?.page();
        };

        const handleFirstPageView = () => {
            analytics?.ready(() => {
                handlePageView();
            });
        };

        handleFirstPageView();

        router.events.on("routeChangeComplete", handlePageView);

        return () => {
            router.events.off("routeChangeComplete", handlePageView);
        };
    }, [analytics, router.events]);
}
