/**
 * getCookieValue returns the value of a cookie by its key.
 *
 * @param {string} key - The name of the cookie to get.
 * @returns {string | undefined} The value of the cookie,
 * or `undefined` if not found.
 */
export function getCookieValue(key: string): string | undefined {
    const match = document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`));
    return match ? decodeURIComponent(match[2]) : undefined;
}
