import { createContext, useContext } from "react";

import { Browser, OperatingSystem } from "@/types/data";
import { StrapiDownloadGroup } from "@/types/strapi";

type GlobalsContextType = {
    browser?: Browser;
    downloads: Array<StrapiDownloadGroup>;
    isTouchDevice: boolean;
    operatingSystem?: OperatingSystem;
};

export const GlobalsContext = createContext<GlobalsContextType | undefined>(
    undefined,
);

export const useGlobalsContext = () => {
    const context = useContext(GlobalsContext);
    if (!context) {
        throw new Error(
            "useGlobalsContext must be used within a GlobalsProvider",
        );
    }
    return context;
};
