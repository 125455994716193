/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ElementType, forwardRef } from "react";

import { GeneralCallback } from "@/types/interactivity";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";

import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import { generateGridColumn } from "@/util/tokens/layout_util";

interface GridColumnProps {
    children: React.ReactNode;
    className?: SerializedStyles;
    columnSpan?: GridColumnSpan;
    columnStart?: GridColumnCount;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    onClick?: GeneralCallback;
    onMouseEnter?: GeneralCallback;
    onMouseLeave?: GeneralCallback;
    tag?: ElementType;
}

export const GridColumn = forwardRef<HTMLElement, GridColumnProps>(
    (
        {
            children,
            columnSpan = "full",
            columnStart = 1,
            tag: Tag = "div",
            ...props
        },
        ref,
    ) => {
        /*
         * Styles
         */
        const marginTopStyles = props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined;

        const marginBottomStyles = props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginBottom",
                  spacingSets[props.marginBottom],
              )
            : undefined;

        const gridColumnStyles = css(
            buildStylesByStringOrObject(
                "gridColumn",
                generateGridColumn(columnStart, columnSpan),
            ),
            marginTopStyles,
            marginBottomStyles,
            props.className,
        );

        /*
         * Rendering
         */
        return (
            <Tag
                css={gridColumnStyles}
                ref={ref}
                onClick={props.onClick}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            >
                {children}
            </Tag>
        );
    },
);

GridColumn.displayName = "GridColumn";
