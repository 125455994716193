/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import NextLink from "next/link";
import { MouseEventHandler, ReactNode, forwardRef, useRef } from "react";

import { AnalyticsTrack } from "@/types/analytics";
import { LinkData } from "@/types/data";
import { LinkThemeKeys } from "@/types/tokens/themes";

import { Color, Colors } from "@/tokens/color";
import { LinkInteractionKey } from "@/tokens/configs/link_config";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamilies, FontFamily } from "@/tokens/fonts";
import { linkAnimationStyle } from "@/tokens/motion";
import { FontWeight, fontWeights } from "@/tokens/typography";

import { formatUrl } from "@/util/data_util";
import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import { useRudderAnalyticsTrack } from "@/util/hooks/use_rudder_analytics";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { getLinkStyles } from "@/util/tokens/link_util";
import { getLinkTheme } from "@/util/tokens/themes_util";
import {
    generateFontStyles,
    getBaseTypeStyles,
} from "@/util/tokens/typography_util";

interface LinkProps extends LinkData {
    ariaLabel?: string;
    children: ReactNode;
    className?: SerializedStyles;
    color?: Color;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    fontWeight?: FontWeight;
    inheritFontSize?: boolean;
    interactionType?: LinkInteractionKey;
    isUppercase?: boolean;
    labelLineHeight?: boolean;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    noWrap?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    renderAsBlock?: boolean;
    text?: string;
    themeKey?: LinkThemeKeys;
    track?: AnalyticsTrack;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            fontFamily = "sans",
            fontSize = "TextDefault",
            fontWeight = "medium",
            inheritFontSize = false,
            interactionType = "underline-disappear",
            labelLineHeight = true,
            noWrap = false,
            renderAsBlock = false,
            themeKey = "linkPrimary",
            ...props
        },
        ref,
    ) => {
        /**
         * Globals & Refs
         */
        const _localLinkRef = useRef<HTMLAnchorElement>(null);
        const linkRef = useCombinedRefs<HTMLAnchorElement>(ref, _localLinkRef);

        /**
         * Interactivity
         */

        const track = useRudderAnalyticsTrack();

        const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
            if (props.track) {
                track(props.track);
            }
            props.onClick?.(event);
        };

        /*
         * Styles
         */
        const baseLinkStyles = css({
            display: renderAsBlock ? "inline-block" : undefined,
            fontWeight: fontWeights[fontWeight],
            textDecoration:
                interactionType === "hover-color-change" ? "none" : "underline",
            textTransform: props.isUppercase ? "uppercase" : undefined,
            whiteSpace: noWrap ? "nowrap" : "normal",
        });

        // TODO abstract vertical margin helper GROW-4905
        const marginYStyles = [
            props.marginTop
                ? buildStylesByBreakpoint(
                      "marginTop",
                      spacingSets[props.marginTop],
                  )
                : undefined,
            props.marginBottom
                ? buildStylesByBreakpoint(
                      "marginBottom",
                      spacingSets[props.marginBottom],
                  )
                : undefined,
        ];

        const linkInteractionStyles =
            interactionType !== "hover-color-change" &&
            css(
                {
                    textDecorationColor:
                        interactionType === "underline-disappear"
                            ? Colors[getLinkTheme(themeKey).underline.default]
                            : "transparent",
                },
                {
                    "&:hover": {
                        textDecorationColor:
                            interactionType === "underline-disappear"
                                ? "transparent"
                                : Colors[
                                      getLinkTheme(themeKey).underline.hover
                                  ],
                    },
                },
            );

        const linkStyles = css(
            FontFamilies[fontFamily].style,
            getBaseTypeStyles(props.color ?? themeKey),
            generateFontStyles(fontSize, {
                baselineLineHeight: labelLineHeight,
                isMonospaced: fontFamily === "mono",
                isUppercase: props.isUppercase,
                shouldInheritFontSize: inheritFontSize,
            }),
            linkAnimationStyle,
            getLinkStyles(themeKey),
            baseLinkStyles,
            linkInteractionStyles,
            marginYStyles,
            props.className,
        );

        /*
         * Rendering
         */
        return (
            <NextLink
                aria-label={props.ariaLabel}
                css={linkStyles}
                href={formatUrl(props.href)}
                ref={linkRef}
                target={props.newWindow ? "_blank" : undefined}
                title={props.text}
                onClick={handleClick}
            >
                {props.children}
            </NextLink>
        );
    },
);

Link.displayName = "Link";
