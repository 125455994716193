/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, MouseEventHandler, useRef } from "react";

import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { useMobileMenuButtonAnimation } from "@/util/animation_hooks/navigation_frame_animations";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { convertToRem } from "@/util/ui_util";

interface MobileMenuButtonProps {
    className?: SerializedStyles;
    isExpanded: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export const MobileMenuButton: FunctionComponent<MobileMenuButtonProps> = (
    props,
) => {
    /**
     * Variables
     */
    const theme = useTypedTheme();
    const rowGap = convertToRem(8);

    /**
     * Refs
     */
    const buttonRef = useRef(null);
    const firstLineRef = useRef(null);
    const secondLineRef = useRef(null);

    /**
     * Animations
     */
    useMobileMenuButtonAnimation(
        buttonRef,
        firstLineRef,
        secondLineRef,
        rowGap,
        props.isExpanded,
    );

    /**
     * Styles
     */
    const menuButtonStyles = css(
        {
            appearance: "none",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            padding: `0 ${Spacing["spacing-4"]}`,
            rowGap,
        },
        props.className,
    );

    const baseLineStyles = css({
        backgroundColor: Colors[theme.ui.uiActive],
        height: 1,
        width: convertToRem(16),
    });

    /**
     * Rendering
     */
    return (
        <button css={menuButtonStyles} ref={buttonRef} onClick={props.onClick}>
            <span css={baseLineStyles} ref={firstLineRef} />

            <span css={baseLineStyles} ref={secondLineRef} />
        </button>
    );
};
