import localFont from "next/font/local";

const WarpSans = localFont({
    src: [
        {
            path: "../fonts/Matter-Regular.woff2",
            style: "normal",
            weight: "400",
        },
        {
            path: "../fonts/Matter-Medium.woff2",
            style: "normal",
            weight: "500",
        },
    ],
});

const WarpMono = localFont({
    src: "../fonts/MatterMono-Regular.woff2",
});

/**
 * Font Family
 */
export const FontFamilies = {
    mono: WarpMono,
    sans: WarpSans,
} as const;

export type FontFamily = keyof typeof FontFamilies;
