/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, MouseEventHandler, useRef } from "react";

import { StrapiNavGroup } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { ChildNavLink } from "@/ui/atoms/child_nav_link";

import { useDropdownMenuExpansionAnimation } from "@/util/animation_hooks/navigation_frame_animations";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur } from "@/util/style_util";

interface DropdownMenuProps extends StrapiNavGroup {
    className?: SerializedStyles;
    isExpanded?: boolean;
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = (props) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const containerRef = useRef(null);
    const [listItemsRef, setListItemRef] = useArrayRef();

    /**
     * Animations
     */
    useDropdownMenuExpansionAnimation(
        containerRef,
        listItemsRef,
        props.isExpanded,
    );

    /**
     * Util
     */
    const noDescriptions = props.Links.every((item) => !item.Description);

    /**
     * Styles
     */
    const containerStyles = css({
        backdropFilter: backgroundBlur("blurMedium"),
        background: Colors[theme.backgrounds.backgroundSecondary],
        borderRadius: BorderRadiuses.borderLarge,
        boxSizing: "border-box",
        display: "flex",
        left: 0,
        opacity: 0,
        padding: Spacing["spacing-2"],
        pointerEvents: "auto",
        position: "absolute",
        top: 0,
        visibility: "hidden",
        width: noDescriptions ? "35ch" : "50ch", // If no descriptions at all, we use a narrower width
    });

    const listStyles = css({
        display: "flex",
        flexDirection: "column",
        rowGap: Spacing["spacing-1"],
        width: "100%",
    });

    const linkGroupStyles = css({ width: "100%" });

    /**
     * Rendering
     */
    const listItems = props.Links.map((link, index) => (
        <li
            key={`dropdown-menu::child-link::${link.Text}::${index}`}
            ref={setListItemRef}
        >
            <ChildNavLink
                {...link}
                borderRadius="borderMedium"
                className={linkGroupStyles}
                onClick={props.onLinkClick}
            />
        </li>
    ));

    return (
        <div css={containerStyles} ref={containerRef}>
            <ul css={listStyles}>{listItems}</ul>
        </div>
    );
};
