/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ElementType, FunctionComponent } from "react";

import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface ListProps {
    children?: React.ReactNode;
    className?: SerializedStyles;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    tag?: ElementType;
}

export const List: FunctionComponent<ListProps> = ({
    tag: Tag = "ul",
    ...props
}) => {
    /**
     * Styles
     */
    const marginYStyles = [
        props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined,
        props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginBottom",
                  spacingSets[props.marginBottom],
              )
            : undefined,
    ];

    const listStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            listStyle: "none",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.List),
        marginYStyles,
        props.className,
    );

    /**
     * Rendering
     */
    return <Tag css={listStyles}>{props.children}</Tag>;
};

List.displayName = "List";
