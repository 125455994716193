/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiIcon } from "@/types/strapi";
import { TextThemeKeys } from "@/types/tokens/themes";

import { BorderRadiuses } from "@/tokens/border";
import { Color } from "@/tokens/color";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamily } from "@/tokens/fonts";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { getBaseTypeStyles } from "@/util/tokens/typography_util";
import { convertToRem } from "@/util/ui_util";

import { Icon } from "./icon";
import { Text } from "./text";

interface ListItemProps {
    children?: React.ReactNode;
    className?: SerializedStyles;
    color?: Color;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    iconSlug?: StrapiIcon;
    index?: number;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    ordered?: boolean;
    themeKey?: TextThemeKeys;
}

export const ListItem: FunctionComponent<ListItemProps> = ({
    fontFamily = "sans",
    fontSize = "TextDefault",
    index = 0,
    ordered = false,
    themeKey = "textPrimary",
    ...props
}) => {
    /**
     * Styles
     */
    const marginYStyles = [
        props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined,
        props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginBottom",
                  spacingSets[props.marginBottom],
              )
            : undefined,
    ];

    const listItemStyles = css(
        {
            "& p": {
                margin: 0,
            },
            alignItems: ordered ? "baseline" : "start",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            position: "relative",
        },
        buildStylesByBreakpoint("columnGap", spacingSets.ListItem),
        getBaseTypeStyles(props.color ?? themeKey),
        marginYStyles,
        props.className,
    );

    const listItemDiscStyles = css({
        backgroundColor: "currentcolor",
        borderRadius: BorderRadiuses.borderRound,
        flexShrink: 0,
        height: convertToRem(6),
        transform:
            "translateY(0.6em)" /** TODO: Making this relative to the list items' font size. Should we make this into a spacing config value?  **/,
        width: convertToRem(6),
    });

    /**
     * Rendering
     */
    const renderListMarker = (index: number) => {
        if (!ordered) {
            if (props.iconSlug) {
                return <Icon fontSize={fontSize} slug={props.iconSlug} />;
            }

            return <span css={listItemDiscStyles} />;
        }

        return (
            <Text
                color="currentColor"
                fontFamily={fontFamily}
                fontSize={fontSize}
                tag="span"
            >
                {`${index + 1}. `}
            </Text>
        );
    };

    return (
        <li css={listItemStyles}>
            {renderListMarker(index)}

            <Text
                color="currentColor"
                fontFamily={fontFamily}
                fontSize={fontSize}
                tag="span"
            >
                {props.children}
            </Text>
        </li>
    );
};

ListItem.displayName = "ListItem";
