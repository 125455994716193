/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as _ from "lodash-es";

import { BreakpointObject } from "@/types/tokens/breakpoints";
import { ButtonPlacementKey } from "@/types/tokens/button";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { ButtonVariantKeys } from "@/tokens/configs/button_config";
import { buttonStyles } from "@/tokens/configs/button_config";
import {
    FontStyleSet,
    FontStyleSlug,
    fontStyles,
} from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";
import { FontSize } from "@/tokens/typography";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { getButtonTheme } from "@/util/tokens/themes_util";

/**
 * Button Base Styles
 */
export function generateButtonBoxModelStyles(
    fontSize: FontStyleSlug,
    iconPlacement?: ButtonPlacementKey,
) {
    const _formatPadding = (_fontSize: FontSize) => {
        const { icon, x, y } = buttonStyles[_fontSize].padding;

        const _formattedX = Spacing[x];
        const _formattedIcon = Spacing[icon];

        switch (iconPlacement) {
            case "action":
                return `0 ${_formattedIcon} 0 ${_formattedX}`;
            case "status":
                return `0 ${_formattedX} 0 ${_formattedIcon}`;
            default:
                return `0 ${_formattedX}`;
        }
    };

    const generateSingleStyle = (_fontSize: FontSize) => {
        const { borderRadius, columnGap } = buttonStyles[_fontSize];

        return css({
            borderRadius: BorderRadiuses[borderRadius],
            columnGap: Spacing[columnGap],
        });
    };

    const generateBreakpointObjectStyles = (
        breakpointObject: BreakpointObject<FontSize>,
    ) => {
        // Filter falsy breakpoint keys
        const _filteredFontSizeObject = _.omitBy(breakpointObject, _.isNil);

        const _buttonBorderRadiuses = _.mapValues(
            _filteredFontSizeObject,
            (_fontSize) => {
                return BorderRadiuses[
                    buttonStyles[_fontSize as FontSize].borderRadius
                ];
            },
        );

        const _buttonPaddings = _.mapValues(
            _filteredFontSizeObject,
            (_fontSize) => _formatPadding(_fontSize as FontSize),
        );

        const _buttonColumnGaps = _.mapValues(
            _filteredFontSizeObject,
            (_fontSize) =>
                Spacing[buttonStyles[_fontSize as FontSize].columnGap],
        );

        return css(
            buildStylesByBreakpoint("borderRadius", _buttonBorderRadiuses),
            buildStylesByBreakpoint("padding", _buttonPaddings),
            buildStylesByBreakpoint("columnGap", _buttonColumnGaps),
        );
    };

    if (typeof fontSize === "string" && fontSize in fontStyles) {
        const fontStyle = fontStyles[fontSize as FontStyleSet];

        return typeof fontStyle === "string"
            ? generateSingleStyle(fontStyle)
            : generateBreakpointObjectStyles(fontStyle);
    }
}

export function generateButtonTextPaddingStyles(fontSize: FontStyleSlug) {
    const generateSingleStyle = (_fontSize: FontSize) => {
        const { y } = buttonStyles[_fontSize].padding;

        return css({
            paddingBottom: Spacing[y],
            paddingTop: Spacing[y],
        });
    };

    const generateBreakpointObjectStyles = (
        breakpointObject: BreakpointObject<FontSize>,
    ) => {
        // Filter falsy breakpoint keys
        const _filteredFontSizeObject = _.omitBy(breakpointObject, _.isNil);

        const _buttonPaddings = _.mapValues(
            _filteredFontSizeObject,
            (_fontSize) =>
                Spacing[buttonStyles[_fontSize as FontSize].padding.y],
        );

        return css(
            buildStylesByBreakpoint("paddingTop", _buttonPaddings),
            buildStylesByBreakpoint("paddingBottom", _buttonPaddings),
        );
    };

    if (typeof fontSize === "string" && fontSize in fontStyles) {
        const fontStyle = fontStyles[fontSize as FontStyleSet];

        return typeof fontStyle === "string"
            ? generateSingleStyle(fontStyle)
            : generateBreakpointObjectStyles(fontStyle);
    }
}

export function generateIconMarginStyles(fontSize: FontStyleSlug) {
    const generateSingleStyle = (_fontSize: FontSize) => {
        const { icon } = buttonStyles[_fontSize].padding;

        return css({
            paddingBottom: Spacing[icon],
            paddingTop: Spacing[icon],
        });
    };

    const generateBreakpointObjectStyles = (
        breakpointObject: BreakpointObject<FontSize>,
    ) => {
        // Filter falsy breakpoint keys
        const _filteredFontSizeObject = _.omitBy(breakpointObject, _.isNil);

        const _buttonPaddings = _.mapValues(
            _filteredFontSizeObject,
            (_fontSize) =>
                Spacing[buttonStyles[_fontSize as FontSize].padding.icon],
        );

        return css(
            buildStylesByBreakpoint("paddingTop", _buttonPaddings),
            buildStylesByBreakpoint("paddingBottom", _buttonPaddings),
        );
    };

    if (typeof fontSize === "string" && fontSize in fontStyles) {
        const fontStyle = fontStyles[fontSize as FontStyleSet];

        return typeof fontStyle === "string"
            ? generateSingleStyle(fontStyle)
            : generateBreakpointObjectStyles(fontStyle);
    }
}

export const getButtonThemeStyles = (variant: ButtonVariantKeys) => {
    const { background, text } = getButtonTheme(variant);

    return css(
        {
            background: Colors[background.default],
            color: Colors[text.default],
        },
        {
            "&:hover": {
                background: Colors[background.hover],
                color: Colors[text.hover],
            },
        },
    );
};

export const getButtonIconThemeStyles = (
    variant: ButtonVariantKeys,
    renderBackgroundColor: boolean = true,
) => {
    const { icon, iconContainer } = getButtonTheme(variant);

    return css(
        {
            background: renderBackgroundColor
                ? Colors[iconContainer.default]
                : Colors.transparent,
            color: Colors[icon.default],
        },
        {
            "&:active": {
                background: renderBackgroundColor
                    ? Colors[iconContainer.active]
                    : undefined,
                color: Colors[icon.active],
            },
            "&:hover": {
                background: renderBackgroundColor
                    ? Colors[iconContainer.hover]
                    : undefined,
                color: Colors[icon.hover],
            },
        },
    );
};
