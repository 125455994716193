import { Expo, gsap } from "gsap";
import { useEffect, useState } from "react";

import { TypedRefObject } from "@/types/interactivity";

export function useLottieFadeAnimation(
    lottieContainerRef: TypedRefObject,
    path: string,
): [string, React.Dispatch<React.SetStateAction<boolean>>] {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);
    const [currentPath, setCurrentPath] = useState(path);
    const [isLoaded, setIsLoaded] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(lottieContainerRef.current, {
            autoAlpha: 1,
            onComplete: () => {
                setDidInit(true);
            },
        });
    }, [lottieContainerRef]);

    useEffect(() => {
        setIsLoaded(false);
    }, [path]);

    useEffect(() => {
        if (didInit) {
            const _opacityValue = isLoaded ? 1 : 0;
            const _duration = 0.35;
            const _ease = Expo.easeInOut;

            gsap.to(lottieContainerRef.current, {
                autoAlpha: _opacityValue,
                duration: _duration,
                ease: _ease,
                onComplete: () => {
                    setCurrentPath(path);
                },
            });
        }
    }, [lottieContainerRef, path, didInit, isLoaded]);

    return [currentPath, setIsLoaded];
}
