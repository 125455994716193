/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { FunctionComponent } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { SerializerConfigKeys } from "@/tokens/configs/serializer_config";

import { Serializer } from "@/util/serializers/serializer";

interface RichTextProps {
    children: string;
    className?: SerializedStyles;
    config: SerializerConfigKeys; // GROW-4907: Once we define a Default config, make this optional
}

export const RichText: FunctionComponent<RichTextProps> = (props) => {
    /**
     * Elements
     */
    return (
        <Markdown
            components={Serializer(props.config)}
            css={props.className}
            remarkPlugins={[remarkGfm]}
        >
            {props.children}
        </Markdown>
    );
};
