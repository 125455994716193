/**
 * Based on the format from:
 * https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/b0d771441590ff06eda265488bbf011a0140fbf4/src/styles/prism/vs-dark.js#L5
 * using the values from the warp.dev site on Sept 2024
 */
import { CSSProperties } from "react";

import { Colors } from "@/tokens/color";

export const SyntaxHighlighterConfig: { [key: string]: CSSProperties } = {
    ".namespace": {
        opacity: "0.7",
    },
    cdata: {
        color: Colors["lighten-40"],
    },
    'code[class*="language-"]': {
        // textShadow: "0 1px rgba(0, 0, 0, 0.3)",
        direction: "ltr",
        MozHyphens: "none",
        // lineHeight: "1.5",

        MozTabSize: "4",
        textAlign: "left",
        hyphens: "none",
        whiteSpace: "pre",
        wordBreak: "normal",
        msHyphens: "none",
        wordSpacing: "normal",
        OTabSize: "4",
        tabSize: "4",

        WebkitHyphens: "none",
    },
    /*
     * ":not(pre) > code[class*=\"language-\"]": {
     *     "background": "#1e1e1e",
     *     "padding": ".1em",
     *     "borderRadius": ".3em"
     * },
     */
    comment: {
        color: Colors["lighten-40"],
    },
    boolean: {
        color: "#99cc99",
    },
    doctype: {
        color: Colors["lighten-40"],
    },
    'pre[class*="language-"]': {
        color: Colors["lighten-90"],
        // textShadow: "0 1px rgba(0, 0, 0, 0.3)",
        direction: "ltr",
        // lineHeight: "1.5",
        MozTabSize: "4",
        textAlign: "left",
        whiteSpace: "pre",
        MozHyphens: "none",
        wordBreak: "normal",
        wordSpacing: "normal",
        hyphens: "none",
        OTabSize: "4",
        tabSize: "4",
        // borderRadius: "0.3em",
        background: "rgba(255, 255, 255, 0.05)",
        WebkitHyphens: "none",
        msHyphens: "none",
        /*
         * padding: "1em",
         * margin: ".5em 0",
         */
        overflow: "auto",
    },
    "class-name": {
        //this was not in the warp file but I matched the pattern
        color: "#ffffb6",
        textDecoration: "underline",
    },
    prolog: {
        color: Colors["lighten-40"],
    },
    keyword: {
        color: "#96cbfe",
    },
    constant: {
        color: "#99cc99",
    },
    property: {
        color: "#96cbfe",
    },
    punctuation: {
        color: "#c5c8c6",
    },
    char: {
        color: Colors["green-60"],
    },
    deleted: {
        color: "#f92672",
    },
    number: {
        color: "#ff73fd",
    },
    "attr-name": {
        //this was not in the warp theme but was here. decided to keep it
        color: Colors["green-60"],
    },
    tag: {
        //was not in the warp file but I matched the pattern from base theme
        color: "#c5c8c6",
    },
    atrule: {
        color: "#fb99f7",
    },
    selector: {
        color: Colors["green-60"],
    },
    "attr-value": {
        color: Colors["green-60"],
    },
    symbol: {
        color: "#f92672",
    },
    builtin: {
        color: Colors["green-60"],
    },
    entity: {
        color: "#ffffb6",
        cursor: "help",
    },
    ".language-css .token.string": {
        color: "#87c38a",
    },
    function: {
        color: "#fb99f7",
    },
    string: {
        color: Colors["green-60"],
    },
    ".style .token.string": {
        color: "#87c38a",
    },
    inserted: {
        color: Colors["green-60"],
    },
    important: {
        color: "#fd971f",
        // fontWeight: "bold", //was not in warp file
    },
    bold: {
        color: "#fd971f", //this was in the warp file but not in base theme
        fontWeight: "bold",
    },
    operator: {
        color: "#ededed",
    },
    italic: {
        fontStyle: "italic",
    },

    regex: {
        color: "#e9c062",
    },
    url: {
        color: "#96cbfe",
    },

    variable: {
        color: "#c6c5fe",
    },
};
