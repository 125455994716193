/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Logo } from "@/ui/atoms/logo";

interface MinimalFooterProps {
    legacyGrid?: boolean;
}

export const MinimalFooter: FunctionComponent<MinimalFooterProps> = ({
    legacyGrid = true,
}) => {
    const smallFooterContainerStyles = css({
        display: "flex",
        justifyContent: "center",
        padding: `${Spacing["spacing-4"]} 0`,
    });

    const glyphStyles = css({
        display: "flex",
        justifyContent: "center",
        objectFit: "contain",
        width: Spacing["spacing-8"],
    });

    return (
        <>
            <Divider />

            <GridContainer
                className={smallFooterContainerStyles}
                legacyGrid={legacyGrid}
                tag="footer"
            >
                <GridColumn>
                    <Logo glyphOnly className={glyphStyles} />
                </GridColumn>
            </GridContainer>
        </>
    );
};
