/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ElementType, forwardRef, useRef } from "react";

import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";
import { TextThemeKeys } from "@/types/tokens/themes";
import { TextAlignment } from "@/types/ui";

import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamilies, FontFamily } from "@/tokens/fonts";
import { FontWeight, fontWeights } from "@/tokens/typography";

import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import { generateGridColumn } from "@/util/tokens/layout_util";
import {
    TextColor,
    generateFontStyles,
    getBaseTypeStyles,
} from "@/util/tokens/typography_util";

interface TextProps {
    ariaHidden?: boolean;
    children: React.ReactNode;
    className?: SerializedStyles;
    color?: TextColor;
    columnSpan?: GridColumnSpan;
    columnStart?: GridColumnCount;
    dateTime?: Date | string;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    fontWeight?: FontWeight;
    id?: string;
    inheritFontSize?: boolean;
    isUppercase?: boolean;
    labelLineHeight?: boolean;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    shouldBalanceWrap?: boolean;
    tag?: ElementType;
    textAlign?: TextAlignment;
    themeKey?: TextThemeKeys;
}

export const Text = forwardRef<HTMLElement, TextProps>(
    (
        {
            children,
            fontFamily = "sans",
            fontSize = "TextDefault",
            fontWeight = "normal",
            inheritFontSize = false,
            tag: Tag = "p",
            ...props
        },
        ref,
    ) => {
        /**
         * Refs
         */
        const _localTextRef = useRef<HTMLElement>(null);
        const textRef = useCombinedRefs(ref, _localTextRef);

        /**
         * Variables
         */
        const _headlineTags: Array<ElementType> = [
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
        ];

        const shouldBalanceWrap = _headlineTags.includes(Tag)
            ? typeof props.shouldBalanceWrap !== "undefined"
                ? props.shouldBalanceWrap
                : true
            : props.shouldBalanceWrap ?? false;

        const themeKey: TextThemeKeys = props.themeKey
            ? props.themeKey
            : _headlineTags.includes(Tag)
              ? "headlinePrimary"
              : "textPrimary";

        /**
         * Styles
         */
        const gridColumnStyles = () => {
            if (!props.columnSpan && !props.columnStart) {
                return null;
            }

            return css(
                { display: "grid" },
                buildStylesByStringOrObject(
                    "gridColumn",
                    generateGridColumn(props.columnStart!, props.columnSpan!),
                ),
            );
        };

        const marginTopStyles = props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined;

        const marginBottomStyles = props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginBottom",
                  spacingSets[props.marginBottom],
              )
            : undefined;

        const baseTextStyles = css(
            {
                fontWeight: fontWeights[fontWeight],
                textTransform: props.isUppercase ? "uppercase" : undefined,
                textWrap: shouldBalanceWrap ? "balance" : undefined,
            },
            props.textAlign
                ? buildStylesByStringOrObject("textAlign", props.textAlign)
                : undefined,
            gridColumnStyles(),
        );

        const textStyles = css(
            FontFamilies[fontFamily].style,
            getBaseTypeStyles(props.color ?? themeKey),
            generateFontStyles(fontSize, {
                baselineLineHeight: props.labelLineHeight,
                isMonospaced: fontFamily === "mono",
                isUppercase: props.isUppercase,
                shouldInheritFontSize: inheritFontSize,
            }),
            baseTextStyles,
            marginTopStyles,
            marginBottomStyles,
            props.className,
        );

        /*
         * Rendering
         */
        return (
            <Tag
                aria-hidden={props.ariaHidden}
                css={textStyles}
                dateTime={props.dateTime}
                id={props.id}
                ref={textRef}
                onClick={props.onClick}
            >
                {children}
            </Tag>
        );
    },
);

Text.displayName = "Text";
