/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import Lottie from "lottie-web";
import { FunctionComponent, useRef } from "react";

import { BorderRadiuses } from "@/tokens/border";

import { useLottieFadeAnimation } from "@/util/animation_hooks/lottie_animations";
import { extractFileName } from "@/util/data_util";
import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";

interface LottieAnimationProps {
    className?: SerializedStyles;
    path: string;
}

export const LottieAnimation: FunctionComponent<LottieAnimationProps> = (
    props,
) => {
    /**
     * Refs
     */
    const animationElementRef = useRef(null);

    const [path, setIsLoaded] = useLottieFadeAnimation(
        animationElementRef,
        props.path,
    );

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        if (animationElementRef.current) {
            const _animationName = extractFileName(props.path);

            const animation = Lottie.loadAnimation({
                autoplay: true,
                container: animationElementRef.current,
                loop: true,
                name: _animationName,
                path: props.path,
                renderer: "svg",
                rendererSettings: {
                    progressiveLoad: true,
                },
            });

            animation.addEventListener("data_ready", () => {
                setIsLoaded(true);
            });

            return () => {
                Lottie.destroy(_animationName);
            };
        }
    }, [path]);

    /**
     * Styles
     */
    const animationContainerStyles = {
        display: "flex",
        ...props.className,
    };

    /**
     * Rendering
     */
    return <div css={animationContainerStyles} ref={animationElementRef} />;
};
