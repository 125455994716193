// Libraries
import { BreakpointObject } from "./breakpoints";

export type ColumnCount = keyof typeof columnCounts;

export type ColumnSmallCount = 1 | 2 | 3 | 4 | 5 | 6;
export type ColumnStandardCount = ColumnSmallCount | 7 | 8 | 9 | 10 | 11 | 12;

export const columnCounts: {
    small: ColumnSmallCount;
    standard: ColumnStandardCount;
} = {
    small: 6,
    standard: 12,
};

/*
 * CSS Grid Columns
 */
export interface GridColumn<T> {
    start: T;
    end: T;
}

export type SmallGridColumn = GridColumn<ColumnSmallCount>;
export type StandardGridColumn = GridColumn<ColumnStandardCount>;

export interface GridColumnBreakpointObject {
    extraSmall?: SmallGridColumn;
    small?: SmallGridColumn;
    medium?: StandardGridColumn;
    large?: StandardGridColumn;
    extraLarge?: StandardGridColumn;
    [key: string]: StandardGridColumn | undefined;
}

export interface GridColumnStartBreakpointObject<T = undefined> {
    extraSmall?: ColumnSmallCount | T;
    small?: ColumnSmallCount | T;
    medium?: ColumnStandardCount | T;
    large?: ColumnStandardCount | T;
    extraLarge?: ColumnStandardCount | T;
    [key: string]: ColumnStandardCount | undefined | T;
}

export type GridColumnCount<T = undefined> =
    | ColumnStandardCount
    | T
    | GridColumnStartBreakpointObject<T>;

export type GridColumnSpanBase = ColumnStandardCount | "full";

export type GridColumnSpan = GridColumnCount<"full">;

export type TemplateColumnObject = BreakpointObject<ColumnStandardCount>;
