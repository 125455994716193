/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import NextLink from "next/link";
import { FunctionComponent, MouseEventHandler, useRef } from "react";

import { StrapiNavGroupLink } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { useMobileNestedNavLinkExpansionAnimation } from "@/util/animation_hooks/navigation_frame_animations";
import { formatUrl } from "@/util/data_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

import { ChildNavLink } from "./child_nav_link";
import { Divider } from "./divider";
import { Icon } from "./icon";
import { Text } from "./text";

interface NestedNavLinkProps {
    className?: SerializedStyles;
    isExpanded?: boolean;
    isParentExpanded?: boolean;
    links?: Array<StrapiNavGroupLink>;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    onNestedLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    text: string;
    url?: string;
}

export const NestedNavLink: FunctionComponent<NestedNavLinkProps> = (props) => {
    /**
     * Variables
     */
    const theme = useTypedTheme();
    const backgroundColor = Colors[theme.backgrounds.backgroundTertiary];

    /**
     * Refs
     */
    const caretRef = useRef(null);
    const listRef = useRef(null);
    const containerRef = useRef(null);

    /**
     * Animation
     */
    useMobileNestedNavLinkExpansionAnimation(
        containerRef,
        caretRef,
        listRef,
        backgroundColor,
        props.isExpanded,
        props.isParentExpanded,
    );

    /**
     * Styles
     */
    const containerStyles = css(
        {
            borderRadius: BorderRadiuses.borderMedium,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            paddingBottom: Spacing["spacing-3"],
            paddingTop: Spacing["spacing-3"],
        },
        buildStylesByBreakpoint("paddingLeft", HalfColumnGaps),
        buildStylesByBreakpoint("paddingRight", HalfColumnGaps),
        props.className,
    );

    const baseLinkStyles = css({
        alignItems: "baseline",
        appearance: "none",
        background: "transparent",
        border: "none",
        boxSizing: "border-box",
        columnGap: Spacing["spacing-1"],
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
        textDecoration: "none",
        width: "100%",
    });

    const listStyles = css({
        display: "flex",
        flexDirection: "column",
        height: 0,
        overflow: "hidden",
        rowGap: Spacing["spacing-1"],
    });

    /**
     * Rendering
     */
    const renderLink = () => {
        return (
            <>
                <Text
                    fontSize="TouchNavText"
                    fontWeight="medium"
                    labelLineHeight={true}
                    tag="span"
                    themeKey="textEmphasis"
                >
                    {props.text}
                </Text>

                {props.links && (
                    <Icon
                        className={css({
                            transformOrigin: "center",
                        })}
                        color={theme.ui.uiInactive}
                        ref={caretRef}
                        size={12}
                        slug="Caret Right"
                    />
                )}
            </>
        );
    };

    // Button is used for nested links, Link is for direct links
    const renderTopLink = () => {
        return props.links ? (
            <button css={baseLinkStyles} onClick={props.onClick}>
                {renderLink()}
            </button>
        ) : (
            <NextLink
                css={baseLinkStyles}
                href={formatUrl(props.url ?? "/")} // Base path is fallback
                onClick={props.onClick}
            >
                {renderLink()}
            </NextLink>
        );
    };

    const renderNestedLinks = () => {
        const castedLinks = props.links!;

        const nestedLinks = castedLinks.map((link, index) => {
            return (
                <li key={`nested-nav::link::${link.Text}::${index}`}>
                    <ChildNavLink
                        {...link}
                        borderRadius="borderSmall"
                        onClick={props.onNestedLinkClick}
                    />
                </li>
            );
        });

        // Adds dividers
        const nestedLinksWithDividers = nestedLinks.reduce(
            (acc, curr, index) => {
                if (index < nestedLinks.length - 1) {
                    return [
                        ...acc,
                        curr,
                        <Divider
                            className={css({ width: "100%" })}
                            key={`nested-nav::link-divider::${index}`}
                        />,
                    ];
                } else {
                    return [...acc, curr];
                }
            },
            [
                <Divider
                    className={css({
                        marginTop: Spacing["spacing-3"],
                        width: "100%",
                    })}
                    key="nested-nav::link-divider::start"
                />,
            ] as JSX.Element[],
        );

        return (
            <ul css={listStyles} ref={listRef}>
                {nestedLinksWithDividers}
            </ul>
        );
    };

    return (
        <div css={containerStyles} ref={containerRef}>
            {renderTopLink()}

            {props.links && renderNestedLinks()}
        </div>
    );
};
