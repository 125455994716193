import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";

import { BreakpointObject } from "@/types/tokens/breakpoints";
import { FontConfig } from "@/types/tokens/font";
import { SingleThemeKeys, TypeThemeKeys } from "@/types/tokens/themes";

import { Color, Colors } from "@/tokens/color";
import {
    FontStyleSet,
    FontStyleSlug,
    fontStyles,
} from "@/tokens/configs/typography_config";
import {
    FontSize,
    FontSizeConfigs,
    LetterSpacings,
    LineHeights,
} from "@/tokens/typography";

import { buildObjectStylesByBreakpoint } from "@/util/style_util";
import { getSingleThemeColor } from "@/util/tokens/themes_util";

/**
 * Types
 */
interface FontStylesGenerator {
    baselineLineHeight?: boolean;
    isMonospaced?: boolean;
    isUppercase?: boolean;
    shouldInheritFontSize?: boolean;
}

export type TextColor = Color | "currentColor";

/**
 * Base Styles
 */
export function getBaseTypeStyles(color: TextColor | TypeThemeKeys) {
    const _getColorProperty = () => {
        if (color === "currentColor") {
            return "currentColor";
        }

        if (_.has(Colors, color)) {
            return Colors[color as Color];
        }

        return getSingleThemeColor(color as SingleThemeKeys);
    };

    return css({
        color: _getColorProperty(),
        fontFeatureSettings: "'ss04' on",
        textSizeAdjust: "none",
        WebkitFontSmoothing: "antialiased",
        WebkitTextSizeAdjust: "none",
    });
}

/**
 * This method takes a singular font style or a breakpoint-object of
 * the font styles, and then maps them to the raw values.
 */
export function generateFontStyles(
    styleSlugOrObject: FontStyleSlug,
    {
        baselineLineHeight,
        isMonospaced = false,
        isUppercase = false,
        shouldInheritFontSize = false,
    }: FontStylesGenerator,
): SerializedStyles {
    /**
     * By default, text that is both monospaced and
     * uppercase will be rendered as a label (100% line height).
     *
     * In the very rare case that use uppercase monospaced
     * type in a non-label setting, we will purposefully
     * opt out of it.
     */
    const isLabel =
        isMonospaced &&
        isUppercase &&
        (baselineLineHeight === true ||
            typeof baselineLineHeight === "undefined");

    const fetchFontConfig = () => {
        if (isMonospaced && isUppercase) {
            if (isLabel) {
                return FontSizeConfigs.eyebrow;
            } else {
                return _.mapValues(FontSizeConfigs.code, (fontSize) => {
                    return {
                        ...fontSize,
                        letterSpacing: LetterSpacings.TECHNICAL_EYEBROW,
                    };
                });
            }
        } else if (isMonospaced && !isUppercase) {
            return FontSizeConfigs.code;
        } else if (!isMonospaced && isUppercase) {
            return FontSizeConfigs.eyebrow;
        } else {
            return FontSizeConfigs.sans;
        }
    };

    const FontSizeConfig = fetchFontConfig();

    const applyConfigOverrides = (
        fontConfiguration: FontConfig,
    ): FontConfig => {
        /**
         * We clone the font configuration so we don't override
         * the master font config if/when we set "inherit"
         */
        const clonedFontConfiguration = _.clone(fontConfiguration);

        if (shouldInheritFontSize) {
            clonedFontConfiguration["fontSize"] = "inherit";
        }

        return baselineLineHeight
            ? {
                  ...clonedFontConfiguration,
                  lineHeight: LineHeights.LINE_HEIGHT_PURE,
              }
            : clonedFontConfiguration;
    };

    const generateBreakpointObjectStyles = (
        breakpointObject: BreakpointObject<FontSize>,
    ) => {
        const breakpointStyles = Object.entries(breakpointObject).reduce(
            (_acc, [breakpoint, style]) => {
                if (style) {
                    _acc[breakpoint] = applyConfigOverrides(
                        FontSizeConfig[style],
                    );
                }
                return _acc;
            },
            {} as BreakpointObject<FontConfig>,
        );

        return buildObjectStylesByBreakpoint(breakpointStyles);
    };

    const generateSingleStyle = (fontSize: FontSize) => {
        return css(applyConfigOverrides(FontSizeConfig[fontSize]));
    };

    if (
        typeof styleSlugOrObject === "string" &&
        styleSlugOrObject in fontStyles
    ) {
        const fontStyle = fontStyles[styleSlugOrObject as FontStyleSet];

        return typeof fontStyle === "string"
            ? generateSingleStyle(fontStyle)
            : generateBreakpointObjectStyles(fontStyle);
    }

    return generateSingleStyle(styleSlugOrObject as FontSize);
}
