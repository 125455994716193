import { BreakpointObject } from "@/types/tokens/breakpoints";

export const ImageAspectRatioStrings = [
    "1 / 1",
    "4 / 3",
    "3 / 2",
    "16 / 9",
    "2 / 1",
    "4 / 5",
] as const;

export type ImageAspectRatioString = (typeof ImageAspectRatioStrings)[number];

export type ImageAspectRatioSet =
    | ImageAspectRatioString
    | BreakpointObject<ImageAspectRatioString>;

export interface MediaData {
    alt: string;
    aspectRatio?: ImageAspectRatioString;
    src: string;
}

/**
 * Layout Sizes
 */
const LayoutConfigurations = ["fullWidth", "halfWidth"] as const;
export type LayoutConfiguration = (typeof LayoutConfigurations)[number];

const MediaWidths = [25, 33, 50, 75, 100] as const;
type MediaWidth = (typeof MediaWidths)[number];

export const MediaWidthsByLayoutConfiguration: {
    [key in LayoutConfiguration]: BreakpointObject<MediaWidth>;
} = {
    fullWidth: {
        extraSmall: 100,
        small: 100,
        medium: 100,
        large: 100,
        extraLarge: 100,
    },
    halfWidth: {
        extraSmall: 100,
        small: 100,
        medium: 50,
        large: 50,
        extraLarge: 50,
    },
};
