/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyPreFooter, StrapiMediaPrimitive } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";

import { RenderLocation } from "@/util/tokens/render_location_util";

interface LegacyPreFooterStaticProps extends StrapiLegacyPreFooter {
    className?: SerializedStyles;
    onlyRenderDownloadCta?: boolean;
}

export const LegacyPreFooterStatic: FunctionComponent<
    LegacyPreFooterStaticProps
> = (props) => {
    /**
     * Styles
     */
    const preFooterContainerStyle = css(
        {
            alignItems: "start",
            background:
                "radial-gradient(136.13% 65.09% at 50.03% 100%, rgba(16, 224, 111, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 0.00) 100%",
        },
        props.className,
    );

    const ctaGroupContainerStyles = css({
        marginTop: Spacing["spacing-12"],
    });

    const ctaGroupStyles = css({
        display: "flex",
        justifyContent: "center",
    });

    const imgStyles = css({
        width: "100%",
    });

    /**
     * Rendering
     */
    const renderEyebrow = () => {
        return (
            <Text
                shouldBalanceWrap
                columnSpan={6}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                }}
                fontSize="EyeBrow"
                textAlign="center"
                themeKey="textSecondary"
            >
                {props.Eyebrow}
            </Text>
        );
    };

    const renderHeadline = () => {
        return (
            <GridColumn
                columnSpan={6}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                }}
                marginTop="PreFooterHeadline"
            >
                <Text fontSize="HeadlineDefault" tag="h2" textAlign="center">
                    {props.Headline}
                </Text>
            </GridColumn>
        );
    };

    const renderCtaGroup = () => {
        const _castedCta = props.CTA!;

        return (
            <GridColumn
                className={ctaGroupContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                }}
            >
                <CtaGroup
                    alignment="center"
                    className={ctaGroupStyles}
                    ctaData={_castedCta}
                    onlyRenderDownloadCta={props.onlyRenderDownloadCta}
                    primaryFontSize="CtaHero"
                    renderLocation={RenderLocation.PRE_FOOTER}
                    secondaryFontSize="Micro"
                />
            </GridColumn>
        );
    };

    const renderMedia = () => {
        const _castedMedia = props.Media[0] as StrapiMediaPrimitive;

        if (_castedMedia) {
            return (
                <GridColumn
                    columnSpan={{
                        extraSmall: 6,
                        medium: 8,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        medium: 3,
                    }}
                    marginTop="PreFooterMedia"
                >
                    <Picture
                        {..._castedMedia.Media}
                        borderRadius="borderNone"
                        css={imgStyles}
                    />
                </GridColumn>
            );
        }
        return null;
    };

    return (
        <div css={preFooterContainerStyle}>
            <Divider
                marginBottom="DefaultSectionMargin"
                marginTop="DefaultSectionMargin"
            />

            <GridContainer>
                {renderEyebrow()}

                {renderHeadline()}

                {props.CTA && renderCtaGroup()}

                {renderMedia()}
            </GridContainer>
        </div>
    );
};
