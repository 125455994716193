/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, ReactNode } from "react";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";

import { BorderRadiuses } from "@/tokens/border";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { SyntaxHighlighterConfig } from "@/tokens/configs/syntax_highlighter_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamilies } from "@/tokens/fonts";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { TrackEvent } from "@/util/tokens/track_event_util";
import { generateFontStyles } from "@/util/tokens/typography_util";

interface MarkdownCodeProps {
    children: string | string[];
    className?: SerializedStyles;
    fontSize?: FontStyleSlug;
    inline?: boolean;
    language: string;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
}

export const MarkdownCode: FunctionComponent<MarkdownCodeProps> = ({
    children,
    fontSize = "ArticleCode",
    inline = false,
    language,
    ...props
}) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const inlineStyles = {
        backgroundColor: theme.backgrounds.backgroundTertiary,
        display: "inline",
        lineHeight: "1em",
        padding: Spacing["spacing-2"],
    };

    const customStyles = {
        borderRadius: BorderRadiuses["borderMedium"],
        lineHeight: "1.5em",
        padding: Spacing["spacing-4"],
        ...FontFamilies["mono"].style,
        ...(inline && inlineStyles),
    };

    const fontStyles = generateFontStyles(fontSize, {
        isMonospaced: true,
    });

    const marginTopStyles = props.marginTop
        ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
        : undefined;

    const marginBottomStyles = props.marginBottom
        ? buildStylesByBreakpoint(
              "marginBottom",
              spacingSets[props.marginBottom],
          )
        : undefined;

    /**
     * Rendering
     */
    const preWrapper = (props: {
        children: ReactNode;
        className: SerializedStyles;
        style: SerializedStyles;
    }): ReactNode => {
        return (
            <pre
                css={css(
                    props.style,
                    marginBottomStyles,
                    marginTopStyles,
                    props.className,
                )}
            >
                {props.children}

                <Button
                    className={css({
                        justifyContent: "center",
                        marginTop: Spacing["spacing-8"],
                    })}
                    fontSize="ArticleCodeCTA"
                    href="https://app.warp.dev/get_warp"
                    iconClassName={css({
                        alignItems: "center",
                        display: "flex",
                    })}
                    iconPlacement="status"
                    iconSlug="Warp"
                    renderIconContainer={false}
                    track={TrackEvent.RUN_IN_WARP_CLICKED}
                    variant="secondary"
                >
                    Run in Warp
                </Button>
            </pre>
        );
    };

    const highlighterProps = {
        css: css(fontStyles),
        customStyle: customStyles,
        language: language,
        style: SyntaxHighlighterConfig,
        wrapLongLines: true,

        ...(inline && {
            css: css(fontStyles),
            language: "text",
            PreTag: "span" as keyof JSX.IntrinsicElements,
        }),
    };

    return (
        <SyntaxHighlighter PreTag={preWrapper} {...highlighterProps}>
            {children}
        </SyntaxHighlighter>
    );
};
