import _ from "lodash";

import { BreakpointObject } from "@/types/tokens/breakpoints";

import { convertToRem } from "@/util/ui_util";

/*
 * Spacing values
 */
/* eslint-disable sort-keys-custom-order/object-keys */
export const Spacing = {
    "spacing-0": convertToRem(0),
    "spacing-half": convertToRem(2),
    "spacing-1": convertToRem(4),
    "spacing-1-half": convertToRem(6),
    "spacing-2": convertToRem(8),
    "spacing-2-half": convertToRem(10),
    "spacing-3": convertToRem(12),
    "spacing-4": convertToRem(16),
    "spacing-5": convertToRem(20),
    "spacing-6": convertToRem(24),
    "spacing-8": convertToRem(32),
    "spacing-10": convertToRem(40),
    "spacing-12": convertToRem(48),
    "spacing-14": convertToRem(56),
    "spacing-16": convertToRem(64),
    "spacing-18": convertToRem(72),
    "spacing-24": convertToRem(96),
    "spacing-32": convertToRem(128),
    "spacing-40": convertToRem(160),
};
/* eslint-enable sort-keys-custom-order/object-keys */

export type SpacingKey = keyof typeof Spacing;

/*
 * Column Gaps
 */
export const ColumnGaps: BreakpointObject<string> = {
    extraSmall: Spacing["spacing-4"],
    small: Spacing["spacing-5"],
    medium: Spacing["spacing-6"],
    large: Spacing["spacing-8"],
    extraLarge: Spacing["spacing-8"],
};

export const HalfColumnGaps = Object.fromEntries(
    Object.entries(ColumnGaps).map(([key, value]) => [
        key,
        `calc(${value} / 2)`,
    ]),
) as BreakpointObject<string>;

/**
 * We manually set the nav spacer styles. Unfortunately, the frame
 * is too complex for a position: sticky setting, so manually setting this
 * is imperative.
 */
export const NavigationSpacerHeight: BreakpointObject<string> = {
    extraSmall: convertToRem(46),
    small: convertToRem(50),
    medium: convertToRem(56),
    large: convertToRem(73),
    extraLarge: convertToRem(73),
};

/**
 * This method combines NavigationSpacerHeight with HalfColumnGaps
 * which we use for elements that should be placed directly underneath the nav.
 */
export const NavigationSpacerHeightWithHalfColumnGap: BreakpointObject<string> =
    _.mapValues(NavigationSpacerHeight, (value, key) => {
        const columnGapValue = HalfColumnGaps[key];
        return `calc(${value} + ${columnGapValue})`;
    });
