/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { LinkThemeKeys } from "@/types/tokens/themes";

import { Colors } from "@/tokens/color";

import { getLinkTheme } from "@/util/tokens/themes_util";

/**
 * Link Base Styles
 */
export const getLinkStyles = (variant: LinkThemeKeys) => {
    const { text, underline } = getLinkTheme(variant);

    return css(
        {
            color: Colors[text.default],
            textDecorationColor: Colors[underline.default],
        },
        {
            "&:active": {
                color: Colors[text.active],
                textDecorationColor: Colors[underline.active],
            },
            "&:hover": {
                color: Colors[text.hover],
                textDecorationColor: Colors[underline.hover],
            },
        },
    );
};
