/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useState } from "react";

import { StrapiLottieAnimation } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { LottieAnimation } from "@/ui/atoms/lottie_animation";
import { Text } from "@/ui/atoms/text";

import { buildCustomAspectRatio } from "@/util/data_util";
import { buildStylesByBreakpoint } from "@/util/style_util";

interface LottieCarouselProps {
    className?: SerializedStyles;
    Lottie_Files: StrapiLottieAnimation["Lottie_Files"];
}

export const LottieCarousel: FunctionComponent<LottieCarouselProps> = (
    props,
) => {
    /**
     * State
     */
    const [currentAnimationFile, setCurrentAnimationFile] = useState(
        props.Lottie_Files[0],
    );

    const animationFileLabels = props.Lottie_Files.reduce((acc, { Label }) => {
        if (!acc.includes(Label)) {
            acc.push(Label);
        }
        return acc;
    }, [] as string[]);

    /**
     * Interactivity
     */
    const handleAnimationFileChange = (label: string) => {
        const _currentAnimationFile = props.Lottie_Files.find((file) => {
            return file.Label == label;
        });
        if (!_currentAnimationFile) return;
        setCurrentAnimationFile(_currentAnimationFile);
    };

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
            borderRadius: BorderRadiuses.borderMedium,
            display: "flex",
            flexDirection: "column",
            gap: Spacing["spacing-6"],
            justifyContent: "center",
            position: "relative",
            width: "100%",
        },
        props.className,
    );

    const animationContainerWrapperStyles = css(
        {
            boxSizing: "border-box",
            width: "100%",
        },
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
    );

    const animationContainerStyles = css({
        aspectRatio: buildCustomAspectRatio(754, 506), // I got these numbers from inspecting the lottie file in the browser
        width: "100%",
    });

    const buttonContainerWrapperStyles = css(
        {
            alignSelf: "stretch",
            display: "flex",
            overflowX: "auto",
        },
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("overflowX", {
            extraSmall: "auto",
            medium: "initial",
        }),
        buildStylesByBreakpoint("justifyContent", {
            extraSmall: "start",
            medium: "center",
        }),
    );

    const buttonContainerStyles = css({
        alignItems: "center",
        backgroundColor: Colors["darken-50"],
        borderRadius: BorderRadiuses.borderMedium,
        display: "flex",
        gap: Spacing["spacing-2"],
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: Spacing["spacing-1"],
    });

    const buttonStyles = css(
        {
            backgroundColor: Colors["transparent"],
            border: "none",
            borderRadius: BorderRadiuses.borderSmall,
            color: Colors["lighten-70"],
            cursor: "pointer",
            padding: Spacing["spacing-2"],
            position: "relative",
            textWrap: "nowrap",
            transition: `background-color ${TransitionSpeeds.mediumFast}ms ${TransitionEasings.easeInOut}, color ${TransitionSpeeds.mediumFast}ms ${TransitionEasings.easeInOut}`,
        },
        {
            "&:hover, &:focus, &:active": {
                backgroundColor: Colors["darken-40"],
                color: Colors["lighten-80"],
            },
        },
    );

    /**
     * Rendering
     */
    const renderAnimation = () => {
        const _lottieAnimation = (
            <div css={animationContainerWrapperStyles}>
                <div css={animationContainerStyles}>
                    <LottieAnimation
                        className={css({
                            height: "100%",
                            width: "100%",
                        })}
                        path={currentAnimationFile.Lottie_File.url}
                    />
                </div>
            </div>
        );

        return _lottieAnimation;
    };

    const renderAnimationFileButtons = () => {
        return (
            <div css={buttonContainerWrapperStyles}>
                <div css={buttonContainerStyles}>
                    {animationFileLabels.map((label) => {
                        return (
                            <button
                                css={buttonStyles}
                                key={`lottie-carousel-button::${label}`}
                                onClick={() => handleAnimationFileChange(label)}
                            >
                                <Text fontSize="TEXT_0875" fontWeight="medium">
                                    {label}
                                </Text>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div css={containerStyles}>
            {renderAnimation()}

            {renderAnimationFileButtons()}
        </div>
    );
};
