/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";

import { BreakpointOrBreakpointObject } from "@/types/tokens/breakpoints";

import { Breakpoint, BreakpointNames } from "@/tokens/breakpoints";
import { Color, Colors } from "@/tokens/color";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

type DividerOrientation = "vertical" | "horizontal";
type DividerSpan = number | "full";

interface DividerProps {
    className?: SerializedStyles;
    color?: Color;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    orientation?: BreakpointOrBreakpointObject<DividerOrientation>;
    span?: BreakpointOrBreakpointObject<DividerSpan>;
}

export const Divider = ({
    color,
    orientation = "horizontal",
    span = "full",
    ...props
}: DividerProps) => {
    /**
     *
     */
    const theme = useTypedTheme();
    if (!color) {
        color = theme.ui.uiBorder;
    }

    /**
     * Util
     */
    /**
     * Depending on the orientation and span configurations, we generate
     * the appropriate width and height values that works across
     * horizontal and vertical, string and breakpoint object.
     *
     * @returns
     */
    const generateDividerDimensions = () => {
        const _stroke = "1px";

        const _formatStringOrNumber = (_dividerSpan: DividerSpan) => {
            return _dividerSpan === "full"
                ? "100%"
                : convertToRem(_dividerSpan);
        };

        if (typeof orientation === "string") {
            const _spanValue = _formatStringOrNumber(
                _.isObject(span) ? span.extraSmall! : span,
            );

            const height = orientation === "horizontal" ? _stroke : _spanValue;

            const width = orientation === "horizontal" ? _spanValue : _stroke;

            return { height, width };
        } else {
            const height: Record<string, string> = {};
            const width: Record<string, string> = {};

            let lastUsedSpan: DividerSpan = _.isObject(span)
                ? span.extraSmall!
                : span;

            BreakpointNames.forEach((_breakpoint: Breakpoint) => {
                const _orientationValue = orientation[_breakpoint];

                if (_orientationValue) {
                    const _spanValue = _.isObject(span)
                        ? span[_breakpoint] ?? lastUsedSpan
                        : span;

                    if (_spanValue) lastUsedSpan = _spanValue;

                    if (_orientationValue === "horizontal") {
                        height[_breakpoint] = _stroke;
                        width[_breakpoint] = _formatStringOrNumber(_spanValue);
                    } else {
                        height[_breakpoint] = _formatStringOrNumber(_spanValue);
                        width[_breakpoint] = _stroke;
                    }
                }
            });

            return { height, width };
        }
    };

    /**
     * Styles
     */
    const marginBottomStyles =
        props.marginBottom &&
        buildStylesByBreakpoint(
            "marginBottom",
            spacingSets[props.marginBottom],
        );

    const marginTopStyles =
        props.marginTop &&
        buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop]);

    const dividerStyles = () => {
        const { height, width } = generateDividerDimensions();

        return css(
            marginBottomStyles,
            marginTopStyles,
            {
                background: Colors[color],
                display: "block",
            },
            buildStylesByStringOrObject("height", height),
            buildStylesByStringOrObject("width", width),
            props.className,
        );
    };

    /**
     * Rendering
     */
    return <span css={dividerStyles()} />;
};
