/* eslint-disable sort-keys-custom-order/object-keys */
// Source of truth
export const BorderRadiuses = {
    borderNone: 0,
    borderSmall: 4,
    borderMedium: 6,
    borderLarge: 8,
    borderRound: 999,
};

export type BorderRadius = keyof typeof BorderRadiuses;
