import { useEffect, useState } from "react";

export function useCopyToClipboard(text: string, shouldCopy: boolean) {
    const [status, setStatus] = useState<"idle" | "success" | "error">("idle");

    useEffect(() => {
        const copyTextToClipboard = async (text: string) => {
            try {
                await navigator.clipboard.writeText(text);
                setStatus("success");
            } catch (error) {
                console.error("Failed to copy text: ", error);
                setStatus("error");
            }
        };

        if (shouldCopy && text) {
            copyTextToClipboard(text);
        }
    }, [text, shouldCopy]);

    return status;
}
