// The source of truth for our breakpoint names
export const BreakpointNames = [
    "extraSmall",
    "small",
    "medium",
    "large",
    "extraLarge",
] as const;

export type Breakpoint = (typeof BreakpointNames)[number];
export type Breakpoints = Array<Breakpoint>;

// The source of truth for our breakpoint values
export enum BreakpointBases {
    EXTRA_SMALL = 0, //0,
    SMALL = 25, // 400
    MEDIUM = 48, // 768,
    LARGE = 80, // 1280,
    EXTRA_LARGE = 120, // 1920
}

// Legacy site max width to be used for legacy pages
export const LegacyGridMaxWidth = 1440;
