/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { IconWeight } from "@phosphor-icons/react";
import * as _ from "lodash-es";
import { forwardRef, useRef } from "react";

import { StrapiIcon } from "@/types/strapi";
import { BreakpointOrBreakpointObject } from "@/types/tokens/breakpoints";

import { BorderRadiuses } from "@/tokens/border";
import { Color, Colors } from "@/tokens/color";
import { AllIcons, IconConfig } from "@/tokens/configs/icon_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";

import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { getIconSizeStyles, getIconStylesForFontSize } from "@/util/icon_util";

interface IconProps {
    className?: SerializedStyles;
    color?: Color;
    fontSize?: FontStyleSlug;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    renderContainer?: boolean;
    size?: BreakpointOrBreakpointObject<number>;
    slug: AllIcons;
    weight?: IconWeight;
}

export const Icon = forwardRef<HTMLElement, IconProps>(
    ({ renderContainer = false, size = 24, ...props }, ref) => {
        /**
         * Globals & Refs
         */
        const _localIconRef = useRef<HTMLAnchorElement>(null);
        const iconRef = useCombinedRefs<HTMLAnchorElement>(ref, _localIconRef);
        const PhosphorIcon = IconConfig[props.slug as StrapiIcon] ?? null;

        if (!Icon) {
            throw new Error(
                `iconSlug ${props.slug} passed into icon.tsx does not match the available slugs in StrapiIcon.`,
            );
        }

        const theme = useTypedTheme();

        const iconColorAttribute =
            !!props.color && _.has(Colors, props.color)
                ? Colors[props.color]
                : "currentColor";

        /**
         * Styles
         */
        const iconContainerStyles = css(
            renderContainer &&
                css(
                    {
                        alignItems: "center",
                        backdropFilter: "blur(16px)",
                        backgroundColor:
                            Colors[theme.backgrounds.backgroundTertiary],
                        borderRadius: BorderRadiuses.borderSmall,
                        display: "flex",
                        justifyContent: "center",
                    },
                    props.fontSize &&
                        getIconStylesForFontSize(props.fontSize, "padding"),
                ),
            props.className,
        );

        const iconStyles = css(
            {
                height: "auto",
                ...(props.onClick && {
                    cursor: "pointer",
                }),
            },
            props.fontSize
                ? getIconStylesForFontSize(props.fontSize, "width")
                : getIconSizeStyles(size),
        );

        /**
         * Rendering
         */
        return (
            <span
                css={iconContainerStyles}
                ref={iconRef}
                onClick={props.onClick}
            >
                <PhosphorIcon
                    color={iconColorAttribute}
                    css={iconStyles}
                    weight={props.weight}
                />
            </span>
        );
    },
);

Icon.displayName = "Icon";
