import { Color, Colors } from "@/tokens/color";


/**
 * Gradient Generator
 */
export function generateLinearGradient(start: string, end: string) {
    return `linear-gradient(95deg, ${start} 0%, ${end} 100%)`;
}

export function generateRadialGradient(
    steps: Array<string>,
    backgroundColor?: string,
    centerPointPercent: {
        horizontal: number;
        vertical: number;
    } = {
        horizontal: 50,
        vertical: 115,
    },
) {
    const horizontalOffset = `${centerPointPercent.horizontal}%`;
    const verticalOffset = `${centerPointPercent.vertical}%`;

    const stepsAsString = steps.join(", ");

    const backgroundString = backgroundColor
        ? `, ${backgroundColor}`
        : undefined;

    return `radial-gradient(circle at ${horizontalOffset} ${verticalOffset}, ${stepsAsString})${backgroundString}`;
}

/**
 * Box Shadow Generator
 */
export function generateBoxShadow(
    color: string,
    x: number = 0,
    y: number = 0,
    blur: number = 0,
    spread: number = 8,
) {
    return `${x}px ${y}px ${blur}px ${spread}px ${color}`;
}

export function hex2rgba(hex: Color, alpha = 1): string {
    const _color = Colors[hex];
    const [r, g, b] = _color.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}

/**
 * Color Interpolation and Helper Functions
 */
export function getRgb(color: string) {
    const [r, g, b] = color
        .replace("rgb(", "")
        .replace(")", "")
        .split(",")
        .map((str: string) => Number(str));

    return [r, g, b];
}

export function colorInterpolate(
    colorA: string,
    colorB: string,
    intval: number,
): string {
    const rgbA = getRgb(colorA),
        rgbB = getRgb(colorB);

    const colorVal = (prop: number): number =>
        Math.round(rgbA[prop] * (1 - intval) + rgbB[prop] * intval);
    return `rgb(${colorVal(0)}, ${colorVal(1)}, ${colorVal(2)})`;
}

/**
 * Interpolates between infinite color stops on a linear gradient
 * Color stops are in the format `[rgb(1.0, 1.0, 1.0), offset]`
 * Where `rgb...` is the color, and `offset` represents the position
 * of the color stop between 0 and 1. See `addColorStop` for inspiration:
 * https://developer.mozilla.org/en-US/docs/Web/API/CanvasGradient/addColorStop
 * `intval` is between 0 and 1 and determines the point along the gradient to sample
 **/

export function colorInterpolateStops(
    stops: [string, number][],
    intval: number,
): string {
    const points = stops
        .sort((a, b) => a[1] - b[1])
        .filter((stop, _index) => {
            if (stop[1] == intval) {
                return true;
            } else if (_index == 0) {
                return stop[1] < intval && stops[_index + 1][1] > intval;
            } else if (_index == stops.length - 1) {
                return stop[1] > intval && stops[_index - 1][1] < intval;
            }
            return (
                (stop[1] > intval && stops[_index - 1][1] < intval) ||
                (stop[1] < intval && stops[_index + 1][1] > intval)
            );
        });

    if (points.length == 1) {
        return colorInterpolate(points[0][0], points[0][0], 0.5);
    }

    const scaledIntval =
        (intval - points[0][1]) / (points[1][1] - points[0][1]);

    return colorInterpolate(points[0][0], points[1][0], scaledIntval);
}

/**
* Returns a contrasting color to ensure that foreground and background color
* combinations provide sufficient contrast when viewed by someone having
* color deficits or when viewed on a black and white screen.
* Based on formula from W3C here: http://www.w3.org/TR/AERT#color-contrast
**/

export function setContrast(
    baseColor: string,
    lightColor: string,
    darkColor: string,
): string {
    const rgb = getRgb(baseColor);

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(
        (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000,
    );
    return brightness > 140 ? darkColor : lightColor;
}