/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ElementType, forwardRef, useImperativeHandle, useRef } from "react";

import { GeneralCallback, TypedRefObject } from "@/types/interactivity";
import { columnCounts } from "@/types/tokens/grid";

import { BreakpointBases, LegacyGridMaxWidth } from "@/tokens/breakpoints";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { ColumnGaps } from "@/tokens/spacing";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { getTemplateColumns } from "@/util/tokens/layout_util";
import { convertToRem } from "@/util/ui_util";

export interface GridContainerRef {
    containerRef: TypedRefObject;
    innerRef: TypedRefObject;
}

interface GridContainerProps {
    children: React.ReactNode;
    className?: SerializedStyles;
    containerClassName?: SerializedStyles;
    href?: string;
    legacyGrid?: boolean;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    onMouseEnter?: GeneralCallback;
    onMouseLeave?: GeneralCallback;
    tag?: ElementType;
}

export const GridContainer = forwardRef<GridContainerRef, GridContainerProps>(
    ({ children, legacyGrid = true, tag: Tag = "section", ...props }, ref) => {
        /**
         * Refs
         */
        const containerRef = useRef(null);
        const innerRef = useRef(null);

        useImperativeHandle(
            ref,
            () => ({
                containerRef,
                innerRef,
            }),
            [],
        );

        /*
         * Styles
         */
        const gridContainerStyles = css(
            {
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                width: "100%",
            },
            buildStylesByBreakpoint("paddingLeft", ColumnGaps),
            buildStylesByBreakpoint("paddingRight", ColumnGaps),
            props.containerClassName,
        );

        const marginTopStyles = props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined;

        const marginBottomStyles = props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginBottom",
                  spacingSets[props.marginBottom],
              )
            : undefined;

        const innerContainerStyles = css(
            {
                boxSizing: "border-box",
                display: "grid",
                maxWidth: legacyGrid
                    ? convertToRem(LegacyGridMaxWidth)
                    : `${BreakpointBases.EXTRA_LARGE}rem`,
                width: "100%",
            },
            buildStylesByBreakpoint("columnGap", ColumnGaps),
            buildStylesByBreakpoint("gridTemplateColumns", {
                extraSmall: getTemplateColumns(columnCounts.small),
                medium: getTemplateColumns(columnCounts.standard),
            }),
            marginTopStyles,
            marginBottomStyles,
            props.className,
        );

        /*
         *If tag is a and we don't pass the href attribute we throw an error
         */
        if (Tag === "a" && !props.href) {
            throw new Error("When defining a link, you must also define href.");
        }

        /**
         * Rendering
         */
        return (
            <div css={gridContainerStyles} ref={containerRef}>
                <Tag
                    css={innerContainerStyles}
                    href={Tag === "a" ? props.href : undefined}
                    ref={innerRef}
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                >
                    {children}
                </Tag>
            </div>
        );
    },
);

GridContainer.displayName = "GridContainer";
