import type { AnalyticsTrack } from "@/types/analytics";
import type { StrapiDownloadOption } from "@/types/strapi";

import type { RenderLocation } from "@/util/tokens/render_location_util";
import { getTrackEvent } from "@/util/tokens/track_event_util";

type Properties = {
    arch: StrapiDownloadOption["Architecture"];
    download_package: StrapiDownloadOption["Package"];
    location?: RenderLocation;
    os_category: "Linux" | "MacOS";
};

export function getDownloadOptionTrack(
    option: StrapiDownloadOption,
    location?: RenderLocation,
): AnalyticsTrack {
    let os_category: Properties["os_category"];
    switch (option.Operating_System) {
        case "mac":
            os_category = "MacOS";
            break;
        case "linux":
            os_category = "Linux";
            break;
        default:
            throw new Error(
                `Unhandled operating system: ${option.Operating_System}.`,
            );
    }

    const properties: Properties = {
        arch: option.Architecture,
        download_package: option.Package,
        location,
        os_category,
    };

    if (location) {
        properties.location = location;
    }

    return getTrackEvent("DOWNLOAD_BUTTON_CLICKED", properties);
}
