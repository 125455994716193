/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FocusEventHandler, FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

import { NestedFormField } from "@/types/forms";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamilies } from "@/tokens/fonts";
import { Spacing } from "@/tokens/spacing";

import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    generateFontStyles,
    getBaseTypeStyles,
} from "@/util/tokens/typography_util";
import { convertToRem } from "@/util/ui_util";

interface TextInputProps extends NestedFormField {
    className?: SerializedStyles;
    errorMessage?: string;
    fontSize?: FontStyleSlug;
    labelClassName?: SerializedStyles;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    register: UseFormRegister<Record<string, string>>;
    showInputLabel?: boolean;
    value?: string;
}

export const TextInput: FunctionComponent<TextInputProps> = ({
    fontSize = "TextDefault",
    register,
    showInputLabel = true,
    ...props
}) => {
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const baseInputStyles = css(
        {
            background: Colors[theme.backgrounds.backgroundSecondary],
            border: `1px solid ${props.errorMessage ? Colors["primary-red"] : Colors["transparent"]}`,
            borderRadius: convertToRem(BorderRadiuses.borderSmall),
            boxSizing: "border-box",
            padding: `${Spacing["spacing-3"]} ${Spacing["spacing-2"]}`,
            width: "100%",
        },
        {
            "&::placeholder": {
                color: Colors[theme.text.textPrimary],
            },
            "&:focus": {
                outline: "none",
            },
        },
        generateFontStyles(fontSize, {}),
        getBaseTypeStyles(theme.text.textPrimary),
        FontFamilies["sans"].style,
    );

    const inputStyles = css(
        baseInputStyles,
        props.fieldType === "textarea" && { resize: "vertical" },
        props.className,
    );

    const inputLabelGroupStyles = css(
        {
            display: "block",
            width: "100%",
        },
        props.labelClassName,
    );

    /**
     * Helpers
     */
    const getInputType = () => {
        if (props.fieldType === "textarea") {
            return "";
        }
        if (props.name === "email") {
            return "email";
        }
        return "text";
    };

    const getAutoComplete = () => {
        switch (props.name) {
            case "email":
                return "email";
            case "firstname":
                return "given-name";
            case "lastname":
                return "family-name";
            case "jobtitle":
                return "organization-title";
            case "name":
                return "organization";
            default:
                return "";
        }
    };

    /**
     * Rendering
     */
    const Tag = props.fieldType === "textarea" ? "textarea" : "input";

    return (
        <label css={inputLabelGroupStyles}>
            {showInputLabel && (
                <Text marginBottom="InputLabelMargin">
                    {props.label}

                    {props.required && (
                        <Text color={theme.text.textSecondary} tag="span">
                            *
                        </Text>
                    )}
                </Text>
            )}

            <Tag
                autoComplete={getAutoComplete()}
                css={inputStyles}
                placeholder={props.placeholder}
                type={getInputType()}
                value={props.value}
                {...register(props.name, {
                    maxLength: props.fieldType === "textarea" ? 5000 : 256,
                    required:
                        props.required && props.name.includes("email")
                            ? "Please enter a valid email"
                            : `Please enter a value less than ${props.fieldType === "textarea" ? 5000 : 256} characters`,
                })}
                cols={props.fieldType === "textarea" ? 20 : undefined}
                maxLength={props.fieldType === "textarea" ? 5000 : 256}
                rows={props.fieldType === "textarea" ? 5 : undefined}
                onBlur={props.onBlur}
            />

            {props.errorMessage && (
                <Text color="primary-red" marginTop="InputLabelMargin">
                    {props.errorMessage}
                </Text>
            )}
        </label>
    );
};
