/* eslint-disable sort-keys-custom-order/object-keys */
import {
    generateBoxShadow,
    generateLinearGradient,
    generateRadialGradient,
} from "@/util/tokens/color_util";

/*
 *Internal RGBA generator
 *This needs to be internal to avoid circular references.
 */
function _internalHex2Rgba(hex: string, alpha: number): string {
    const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}

const NeutralColors = {
    white: "#FFFFFF",
    black: "#121212",
    "off-white": "#faf9f6",

    transparent: "rgba(0, 0, 0, 0)",

    "neutral-95": "#1e1e1d",
    "neutral-90": "#292929",
    "neutral-80": "#404040",
    "neutral-70": "#585756",
    "neutral-60": "#6f6e6d",
    "neutral-50": "#868584",
    "neutral-40": "#9d9d9b",
    "neutral-30": "#b4b4b2",
    "neutral-20": "#cccbc8",
    "neutral-10": "#e3e2df",
    "neutral-5": "#eeedeb",
};

const ShadedNeutralColors = {
    // Darken
    "darken-95": _internalHex2Rgba(NeutralColors.black, 0.95),
    "darken-90": _internalHex2Rgba(NeutralColors.black, 0.9),
    "darken-80": _internalHex2Rgba(NeutralColors.black, 0.8),
    "darken-70": _internalHex2Rgba(NeutralColors.black, 0.7),
    "darken-60": _internalHex2Rgba(NeutralColors.black, 0.6),
    "darken-50": _internalHex2Rgba(NeutralColors.black, 0.5),
    "darken-40": _internalHex2Rgba(NeutralColors.black, 0.4),
    "darken-30": _internalHex2Rgba(NeutralColors.black, 0.3),
    "darken-20": _internalHex2Rgba(NeutralColors.black, 0.2),
    "darken-10": _internalHex2Rgba(NeutralColors.black, 0.1),
    "darken-5": _internalHex2Rgba(NeutralColors.black, 0.05),

    // Lighten
    "lighten-95": _internalHex2Rgba(NeutralColors["off-white"], 0.95),
    "lighten-90": _internalHex2Rgba(NeutralColors["off-white"], 0.9),
    "lighten-80": _internalHex2Rgba(NeutralColors["off-white"], 0.8),
    "lighten-70": _internalHex2Rgba(NeutralColors["off-white"], 0.7),
    "lighten-60": _internalHex2Rgba(NeutralColors["off-white"], 0.6),
    "lighten-50": _internalHex2Rgba(NeutralColors["off-white"], 0.5),
    "lighten-40": _internalHex2Rgba(NeutralColors["off-white"], 0.4),
    "lighten-30": _internalHex2Rgba(NeutralColors["off-white"], 0.3),
    "lighten-20": _internalHex2Rgba(NeutralColors["off-white"], 0.2),
    "lighten-10": _internalHex2Rgba(NeutralColors["off-white"], 0.1),
    "lighten-5": _internalHex2Rgba(NeutralColors["off-white"], 0.05),

    // Opaque Darkens
    "opaque-darken-5": _internalHex2Rgba(NeutralColors["neutral-10"], 0.5),

    // Opaque Lightens
    "opaque-lighten-20": _internalHex2Rgba(NeutralColors["neutral-70"], 0.5),
    "opaque-lighten-10": _internalHex2Rgba(NeutralColors["neutral-80"], 0.5),
    "opaque-lighten-5": _internalHex2Rgba(NeutralColors["neutral-90"], 0.5),
};

export const LegacyPrimaryPigments = {
    "primary-green": "#10E06F",
    "primary-blue": "#0868F9",
    "primary-fuchsia": "#F847F1",
    "primary-red": "#FF4F32",
    "primary-purple": "#7F1BFF",
    "primary-teal": "#00EAEA",
    "primary-brown": "#593E2B",
    "primary-yellow": "#EFFF36",
};

const PrimaryPigments = {
    "primary-green": "#34895c",
    "primary-blue": "#2e5d9e",
    "primary-purple": "#754dac",
    "primary-teal": "#4d9989",
    "primary-pink": "#a43787",
    "primary-red": "#c6372a",
    "primary-yellow": "#c0872a",
};

const Gradients = {
    /**
     * Linear
     */
    "linear-lighten-30-lighten-10": generateLinearGradient(
        ShadedNeutralColors["lighten-30"],
        ShadedNeutralColors["lighten-10"],
    ),
    "linear-lighten-20-to-lighten-5": generateLinearGradient(
        ShadedNeutralColors["lighten-20"],
        ShadedNeutralColors["lighten-5"],
    ),
    "linear-lighten-10-to-transparent": generateLinearGradient(
        ShadedNeutralColors["lighten-10"],
        _internalHex2Rgba(NeutralColors.white, 0), // One off
    ),

    /**
     * Radial
     */
    "prefooter-radial-green-100": generateRadialGradient(
        [
            LegacyPrimaryPigments["primary-green"],
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 2),
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0),
        ],
        NeutralColors.black,
        {
            horizontal: 0.5,
            vertical: 1,
        },
    ),
    "radial-green-100": generateRadialGradient(
        [
            LegacyPrimaryPigments["primary-green"],
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0),
        ],
        ShadedNeutralColors["lighten-5"],
    ),
    "radial-green-80": generateRadialGradient(
        [
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0.8),
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0),
        ],
        ShadedNeutralColors["lighten-5"],
    ),
    "radial-green-60": generateRadialGradient(
        [
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0.6),
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0),
        ],
        ShadedNeutralColors["lighten-5"],
    ),
    "radial-green-10": generateRadialGradient(
        [
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0.1),
            _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0),
        ],
        ShadedNeutralColors["lighten-5"],
    ),
};

export const BoxShadows = {
    "box-shadow-green-20": generateBoxShadow(
        _internalHex2Rgba(LegacyPrimaryPigments["primary-green"], 0.2),
    ),

    "box-shadow-lighten-20": generateBoxShadow(
        ShadedNeutralColors["lighten-20"],
    ),

    "box-shadow-transparent": generateBoxShadow(NeutralColors.transparent),
};

export const Colors = {
    // Neutral Bases
    ...NeutralColors,

    // Shaded Neutrals
    ...ShadedNeutralColors,

    // Primary Pigments,
    ...PrimaryPigments,

    // Gradients
    ...Gradients,

    // Box Shadows
    ...BoxShadows,

    // Green
    "green-100": "#15281f",
    "green-80": "#296043",
    "green-60": PrimaryPigments["primary-green"],
    "green-60-bright": "#1ca05a",
    "green-40": "#789b88",
    "green-20": "#b6c9bf",

    // Blue
    "blue-100": "#0f2748",
    "blue-80": "#224577",
    "blue-60": PrimaryPigments["primary-blue"],
    "blue-60-bright": "#1458b8",
    "blue-40": "#6f839f",
    "blue-20": "#acb8c8",

    // Purple
    "purple-100": "#35244c",
    "purple-80": "#4c3172",
    "purple-60": PrimaryPigments["primary-purple"],
    "purple-60-bright": "#7032c8",
    "purple-40": "#8979a0",
    "purple-20": "#b8acc8",

    // Teal
    "teal-100": "#293d39",
    "teal-80": "#396a60",
    "teal-60": PrimaryPigments["primary-teal"],
    "teal-60-bright": "#34b298",
    "teal-40": "#799c92",
    "teal-20": "#bfc5c3",

    // Pink
    "pink-100": "#471a3b",
    "pink-80": "#862d6e",
    "pink-60": PrimaryPigments["primary-pink"],
    "pink-60-bright": "#bf409d",
    "pink-40": "#a57899",
    "pink-20": "#c8acc2",

    // Red
    "red-100": "#44201d",
    "red-80": "#76251e",
    "red-60": PrimaryPigments["primary-red"],
    "red-60-bright": "#d22d1e",
    "red-40": "#ae756f",
    "red-20": "#dcb9b7",

    // Yellow
    "yellow-100": "#4d391a",
    "yellow-80": "#7a571f",
    "yellow-60": PrimaryPigments["primary-yellow"],
    "yellow-60-bright": "#e5a01a",
    "yellow-40": "#bd9f65",
    "yellow-20": "#e3d7bf",
};

export type Color = keyof typeof Colors;
