/* eslint-disable react-hooks/rules-of-hooks */
import * as _ from "lodash-es";

import {
    ButtonTheme,
    LinkTheme,
    LinkThemeKeys,
    SingleThemeKeys,
} from "@/types/tokens/themes";

import { Color, Colors } from "@/tokens/color";
import {
    ButtonVariantKeys,
    buttonThemeMapping,
} from "@/tokens/configs/button_config";

import { useTypedTheme } from "@/util/hooks/theme_hooks";

export function getSingleThemeColor(themeKey: SingleThemeKeys) {
    let _themeColor: Color = "black";

    const _theme = useTypedTheme();

    _.mapValues(_theme, (_level1Value) => {
        _.mapValues(_level1Value, (_level2Value, _level2Key) => {
            if (_level2Key === themeKey) {
                _themeColor = _level2Value;

                return;
            }
        });
    });

    return Colors[_themeColor];
}

export function getButtonTheme(variant: ButtonVariantKeys): ButtonTheme {
    const themeKey = buttonThemeMapping[variant];

    return useTypedTheme().buttons[themeKey];
}

export function getLinkTheme(variant: LinkThemeKeys): LinkTheme {
    return useTypedTheme().links[variant];
}
