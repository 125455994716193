/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyPreFooter } from "@/types/strapi";

import { LegacyPreFooterAnimated } from "@/ui/organisms/legacy_pre_footer_animated";
import { LegacyPreFooterStatic } from "@/ui/organisms/legacy_pre_footer_static";

interface LegacyPreFooterProps extends StrapiLegacyPreFooter {
    className?: SerializedStyles;
    onlyRenderDownloadCta?: boolean;
}

export const LegacyPreFooter: FunctionComponent<LegacyPreFooterProps> = (
    props,
) => {
    if (!props.Media || props.Media.length === 0) {
        return null;
    }

    const preFooterVersion =
        props.Media[0].__component === "primitives.primitive-media"
            ? "column"
            : "row";

    /**
     * Rendering
     */
    return preFooterVersion === "column" ? (
        <LegacyPreFooterStatic
            {...(props as StrapiLegacyPreFooter)}
            onlyRenderDownloadCta={props.onlyRenderDownloadCta}
        />
    ) : (
        <LegacyPreFooterAnimated
            {...(props as StrapiLegacyPreFooter)}
            onlyRenderDownloadCta={props.onlyRenderDownloadCta}
        />
    );
};
