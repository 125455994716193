/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from "lodash-es";
import { RefObject, useEffect, useRef } from "react";

export type RefSetter<T> = (ref: T | null) => void;

export function useArrayRef<T = HTMLElement>(): [
    refs: RefObject<Array<T | null>>,
    setRef: RefSetter<T>,
] {
    type refType = T | null;
    const refs = useRef<Array<refType>>([]);
    refs.current = [];

    return [
        refs,
        (ref: refType) => {
            /**
             * We check for a ref being already defined due to the
             * side effect of having multiple renders through lists,
             * and we want to make sure that we're only animating
             * what's actually rendered :) not duplicates
             */
            const _refAlreadyDefined = _.indexOf(refs.current, ref) >= 0;

            if (!_refAlreadyDefined && ref) {
                refs.current.push(ref);
            }
        },
    ];
}

export function useCombinedRefs<T = HTMLElement>(...refs: any[]) {
    const targetRef = useRef<T>(null);

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === "function") {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
}
