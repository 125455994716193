/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";
import { FunctionComponent, MouseEventHandler } from "react";

import { AnalyticsTrack } from "@/types/analytics";
import { OperatingSystem, OperatingSystemKeys } from "@/types/data";
import type { StrapiDownloadOption } from "@/types/strapi";

import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Text } from "@/ui/atoms/text";

interface DownloadButtonProps {
    actionData: StrapiDownloadOption;
    className?: SerializedStyles;
    fontSize?: FontStyleSlug;
    keyboardShortcut?: string;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    os: string;
    renderIcon?: boolean;
    renderSecondaryText?: boolean;
    track?: AnalyticsTrack;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = ({
    fontSize = "CtaDefault",
    renderIcon = true,
    ...props
}) => {
    /**
     * Util
     */
    const getButtonText = (os: string, actionText: string) => {
        const formattedOs = formatStrapiOs(os);

        const prefix = formattedOs !== "linux" ? "Download for" : "";

        const getAction = () => {
            return formattedOs === "linux" ? actionText : os;
        };

        return `${prefix} ${getAction()}`;
    };

    const formatStrapiOs = (strapiOsName: string): OperatingSystem => {
        const _formattedStrapiOsName = strapiOsName.toLowerCase();

        if (_.includes(OperatingSystemKeys, _formattedStrapiOsName)) {
            return _formattedStrapiOsName as OperatingSystem;
        }

        return "mac";
    };

    /**
     * Styles
     */
    const containerStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: Spacing["spacing-2"],
        width: "max-content",
    });

    /**
     * Elements
     */
    const renderSecondaryText = () => {
        return props.actionData.Secondary_Text && props.renderSecondaryText ? (
            <Text fontSize="Micro" textAlign="center" themeKey="textSecondary">
                {props.actionData.Secondary_Text}
            </Text>
        ) : undefined;
    };

    return (
        <div css={containerStyles}>
            <Button
                fontSize={fontSize}
                href={props.actionData.URL}
                iconPlacement={renderIcon ? "action" : undefined}
                iconSlug={renderIcon ? "Download" : undefined}
                keyboardShortcut={props.keyboardShortcut}
                newWindow={true}
                track={props.track}
                onClick={props.onClick}
            >
                {getButtonText(props.os, props.actionData.Text)}
            </Button>

            {renderSecondaryText()}
        </div>
    );
};
