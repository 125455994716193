import { Breakpoint, BreakpointBases } from "@/tokens/breakpoints";

import { getMediaQuery } from "@/util/tokens/layout_util";

interface BreakpointRange {
    min: string;
    max?: string;
}

interface BreakpointRanges {
    extraSmall: BreakpointRange;
    small: BreakpointRange;
    medium: BreakpointRange;
    large: BreakpointRange;
    extraLarge: BreakpointRange;
}

/*
 * Sorted breakpoint bases
 */
export const breakpointBases: {
    [key in Breakpoint]: BreakpointBases;
} = {
    extraSmall: BreakpointBases.EXTRA_SMALL,
    small: BreakpointBases.SMALL,
    medium: BreakpointBases.MEDIUM,
    large: BreakpointBases.LARGE,
    extraLarge: BreakpointBases.EXTRA_LARGE,
};

export const mediaQueries = {
    MIN: "min",
    MAX: "max",
};

export type MediaQueries = keyof typeof mediaQueries;

export const mediaQuerySets: BreakpointRanges = {
    extraSmall: {
        min: getMediaQuery(BreakpointBases.EXTRA_SMALL),
        max: getMediaQuery(BreakpointBases.SMALL, "MAX"),
    },

    small: {
        min: getMediaQuery(BreakpointBases.SMALL),
        max: getMediaQuery(BreakpointBases.MEDIUM, "MAX"),
    },

    medium: {
        min: getMediaQuery(BreakpointBases.MEDIUM),
        max: getMediaQuery(BreakpointBases.LARGE, "MAX"),
    },

    large: {
        min: getMediaQuery(BreakpointBases.LARGE),
        max: getMediaQuery(BreakpointBases.EXTRA_LARGE, "MAX"),
    },

    extraLarge: {
        min: getMediaQuery(BreakpointBases.EXTRA_LARGE),
    },
};

export type MediaQuerySet = keyof typeof mediaQuerySets;

/*
 * Media query matches (JavaScript)
 */
export type MediaMatches = { [key in Breakpoint]?: BreakpointBases };

/* eslint-disable sort-keys-custom-order/object-keys */
export const mediaMatches: MediaMatches = {
    extraLarge: BreakpointBases.EXTRA_LARGE,
    large: BreakpointBases.LARGE,
    medium: BreakpointBases.MEDIUM,
    small: BreakpointBases.SMALL,
    extraSmall: BreakpointBases.EXTRA_SMALL,
};
/* eslint-enable sort-keys-custom-order/object-keys */
