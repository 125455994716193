import gsap from "gsap";
import Observer from "gsap/dist/Observer";
import { useEffect, useState } from "react";

import { TypedRefObject } from "@/types/interactivity";

export function useMediaParallaxScroll(
    containerRef: TypedRefObject,
    pictureRef: TypedRefObject,
    shouldAnimate: boolean,
    scale: number,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        if (shouldAnimate && pictureRef.current) {
            gsap.set(pictureRef.current, {
                onComplete: () => {
                    setDidInit(true);
                },
                scale,
            });
        }
    }, []);

    useEffect(() => {
        if (didInit && shouldAnimate && pictureRef.current) {
            const _animation = gsap.to(pictureRef.current, {
                ease: "none",
                scale: scale + 0.05,
                scrollTrigger: {
                    end: "bottom top",
                    scrub: 1,
                    start: "top bottom",
                    trigger: containerRef.current,
                },
                yPercent: -5,
            });

            return () => {
                _animation.kill();
            };
        }
    }, [didInit]);
}

export function useMediaPanningAnimation(
    containerRef: TypedRefObject,
    mediaRef: TypedRefObject,
) {
    useEffect(() => {
        const container = containerRef.current;
        const nest = mediaRef.current;

        if (!container || !nest) return;

        const nestRect = nest.getBoundingClientRect();

        const centerX = nestRect.left + nestRect.width / 2;
        const centerY = nestRect.top + nestRect.height / 2;

        const moveNest = (event: MouseEvent) => {
            const mouseX = event.clientX;
            const mouseY = event.clientY;

            const deltaX = mouseX - centerX;
            const deltaY = mouseY - centerY;

            gsap.to(nest, {
                duration: 1,
                ease: "power3.out",
                x: -deltaX * 0.005,
                y: -deltaY * 0.005,
            });
        };

        const resetNestPosition = () => {
            gsap.to(nest, {
                duration: 1,
                ease: "power3.out",
                x: 0,
                y: 0,
            });
        };

        const observer = Observer.create({
            onHoverEnd: resetNestPosition,
            onMove: (self) => moveNest(self.event as MouseEvent),
            target: container,
            type: "pointer",
        });

        return () => {
            observer.kill();
        };
    }, []);
}
