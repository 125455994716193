import { ButtonTheme, ButtonThemeKeys } from "@/types/tokens/themes";

import { BorderRadius } from "@/tokens/border";
import { SpacingKey } from "@/tokens/spacing";
import { FontSize } from "@/tokens/typography";

// Button padding
interface ButtonPadding {
    icon: SpacingKey;
    x: SpacingKey;
    y: SpacingKey;
}

const buttonPaddingVariants = ["default", "iconStatus", "iconAction"] as const;
export type ButtonPaddingVariant = (typeof buttonPaddingVariants)[number];

/* eslint-disable sort-keys-custom-order/object-keys */
export const buttonPadding: {
    [key in FontSize]: ButtonPadding;
} = {
    TEXT_0625: {
        x: "spacing-2",
        y: "spacing-2",
        icon: "spacing-1",
    },
    TEXT_075: {
        x: "spacing-3",
        y: "spacing-2",
        icon: "spacing-1",
    },
    TEXT_0875: {
        x: "spacing-3",
        y: "spacing-2",
        icon: "spacing-1",
    },
    TEXT_1: {
        x: "spacing-3",
        y: "spacing-3",
        icon: "spacing-1",
    },
    TEXT_1125: {
        x: "spacing-4",
        y: "spacing-3",
        icon: "spacing-1",
    },
    TEXT_125: {
        x: "spacing-4",
        y: "spacing-3",
        icon: "spacing-2",
    },
    TEXT_1375: {
        x: "spacing-4",
        y: "spacing-3",
        icon: "spacing-2",
    },
    TEXT_150: {
        x: "spacing-5",
        y: "spacing-3",
        icon: "spacing-2",
    },
    TEXT_2: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_250: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_3: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_350: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_4: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_5: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_6: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_8: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
    TEXT_10: {
        x: "spacing-6",
        y: "spacing-4",
        icon: "spacing-2",
    },
};

export const buttonColumnGap: {
    [key in FontSize]: SpacingKey;
} = {
    TEXT_0625: "spacing-2",
    TEXT_075: "spacing-2",
    TEXT_0875: "spacing-2",
    TEXT_1: "spacing-3",
    TEXT_1125: "spacing-3",
    TEXT_125: "spacing-3",
    TEXT_1375: "spacing-3",
    TEXT_150: "spacing-3",
    TEXT_2: "spacing-4",
    TEXT_250: "spacing-4",
    TEXT_3: "spacing-4",
    TEXT_350: "spacing-4",
    TEXT_4: "spacing-4",
    TEXT_5: "spacing-4",
    TEXT_6: "spacing-4",
    TEXT_8: "spacing-4",
    TEXT_10: "spacing-4",
};

// Border radius
export const buttonBorderRadius: {
    [key in FontSize]: BorderRadius;
} = {
    TEXT_0625: "borderSmall",
    TEXT_075: "borderSmall",
    TEXT_0875: "borderSmall",
    TEXT_1: "borderSmall",
    TEXT_1125: "borderMedium",
    TEXT_125: "borderMedium",
    TEXT_1375: "borderMedium",
    TEXT_150: "borderMedium",
    TEXT_2: "borderLarge",
    TEXT_250: "borderLarge",
    TEXT_3: "borderLarge",
    TEXT_350: "borderLarge",
    TEXT_4: "borderLarge",
    TEXT_5: "borderLarge",
    TEXT_6: "borderLarge",
    TEXT_8: "borderLarge",
    TEXT_10: "borderLarge",
};
/* eslint-enable sort-keys-custom-order/object-keys */

// Button theme
interface ButtonStyle {
    borderRadius: BorderRadius;
    columnGap: SpacingKey;
    padding: ButtonPadding;
}

type ButtonStyles = {
    [key in FontSize]: ButtonStyle;
};

function createButtonStyles(): ButtonStyles {
    const masterObject: ButtonStyles = {} as ButtonStyles;

    for (const key in buttonPadding) {
        if (
            Object.prototype.hasOwnProperty.call(buttonPadding, key) &&
            Object.prototype.hasOwnProperty.call(buttonBorderRadius, key) &&
            Object.prototype.hasOwnProperty.call(buttonColumnGap, key)
        ) {
            const _castedKey = key as FontSize;

            masterObject[_castedKey] = {
                borderRadius: buttonBorderRadius[_castedKey],
                columnGap: buttonColumnGap[_castedKey],
                padding: buttonPadding[_castedKey],
            };
        }
    }

    return masterObject;
}

export const buttonStyles = createButtonStyles();

export const buttonThemeMapping: {
    [key: string]: ButtonThemeKeys;
} = {
    ghost: "buttonGhost",
    primary: "buttonPrimary",
    secondary: "buttonSecondary",
};

const buttonVariantKeys = ["ghost", "primary", "secondary"] as const;
export type ButtonVariantKeys = (typeof buttonVariantKeys)[number];
export type ButtonVariants = { [key in ButtonVariantKeys]: ButtonTheme };
