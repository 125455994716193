import * as _ from "lodash-es";

import {
    BreakpointObject,
    BreakpointOrBreakpointObject,
} from "@/types/tokens/breakpoints";

import { IconStyleProperty, iconStyles } from "@/tokens/configs/icon_config";
import {
    FontStyleSet,
    FontStyleSlug,
    fontStyles,
} from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";
import { FontSize } from "@/tokens/typography";

import { buildStylesByStringOrObject } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

export function getIconStylesForFontSize(
    fontSize: FontStyleSlug,
    property: IconStyleProperty,
) {
    const _formatStyleProperty = (_fontSize: FontSize) => {
        const _iconStylesByFontSize = iconStyles[_fontSize];

        if (property === "padding") {
            return Spacing[_iconStylesByFontSize.padding];
        }

        return convertToRem(_iconStylesByFontSize[property]);
    };

    const _generateStyleObject = (
        breakpointObject: BreakpointObject<FontSize>,
    ) => {
        const _filteredFontSizeObject = _.omitBy(breakpointObject, _.isNil);

        return _.mapValues(_filteredFontSizeObject, (_fontSize) => {
            return _formatStyleProperty(_fontSize as FontSize);
        });
    };

    if (typeof fontSize === "string" && fontSize in fontStyles) {
        const fontStyle = fontStyles[fontSize as FontStyleSet];

        return buildStylesByStringOrObject(
            property,
            typeof fontStyle === "string"
                ? _formatStyleProperty(fontStyle as FontSize)
                : _generateStyleObject(fontStyle),
        );
    }
}

/**
 * Converts the size parameter from raw number to rem value
 * @param size
 * @returns
 */
export function getIconSizeStyles(size: BreakpointOrBreakpointObject<number>) {
    const _stringifiedSize =
        typeof size === "number"
            ? convertToRem(size)
            : _.flow(
                  (_data) => _.omitBy(_data, _.isNil),
                  (_data) =>
                      _.mapValues(_data, (_size) => convertToRem(_size!)),
              )(size);

    return buildStylesByStringOrObject("width", _stringifiedSize);
}
