/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ElementType, FunctionComponent } from "react";

import { StrapiTextHeader } from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { Color } from "@/tokens/color";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface TextHeaderProps extends StrapiTextHeader {
    buttonStyles?: SerializedStyles;
    className?: SerializedStyles;
    columnSpan?: GridColumnSpan;
    columnStart?: GridColumnCount;
    eyebrowColor?: Color;
    headlineSize?: FontStyleSlug;
    headlineTag?: ElementType;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    renderAsGrid?: boolean;
}

export const TextHeader: FunctionComponent<TextHeaderProps> = ({
    headlineSize = "Headline1",
    headlineTag: HeadlineTag = "h2",
    renderAsGrid = true,
    ...props
}) => {
    /**
     * Styles
     */
    const containerStyles = css(
        { alignItems: "flex-start", display: "flex", flexDirection: "column" },
        props.className,
    );

    const flexContainerStyles = css(
        props.marginTop
            ? buildStylesByBreakpoint("marginTop", spacingSets[props.marginTop])
            : undefined,
        props.marginBottom
            ? buildStylesByBreakpoint(
                  "marginTop",
                  spacingSets[props.marginBottom],
              )
            : undefined,
        containerStyles,
    );

    const subheadlineStyles = buildStylesByBreakpoint(
        "marginTop",
        spacingSets.TextHeaderSubheadline,
    );

    /**
     * Rendering
     */
    const renderHeaderElements = () => (
        <>
            {props.Eyebrow && (
                <Text
                    color={props.eyebrowColor}
                    fontSize={headlineSize}
                    marginBottom="TextHeaderEyebrow"
                    tag={HeadlineTag}
                    themeKey="headlineSecondary"
                >
                    {props.Eyebrow}
                </Text>
            )}

            <Text
                fontSize={headlineSize}
                tag={props.Eyebrow ? "p" : HeadlineTag}
                themeKey="headlinePrimary"
            >
                {props.Headline}
            </Text>

            {props.Subheadline && (
                <RichText className={subheadlineStyles} config="UiSubheadline">
                    {props.Subheadline}
                </RichText>
            )}

            {
                /**
                 * Sometimes Link registers as defined even when
                 * Text and URL aren't. So this is a bruteforce check to make sure
                 * we don't accidentally render Button.
                 */
                props.Link && props.Link.Text && props.Link.URL && (
                    <Button
                        className={props.buttonStyles}
                        fontSize="CtaDefault"
                        href={props.Link.URL}
                        marginTop="TextHeaderCta"
                    >
                        {props.Link.Text}
                    </Button>
                )
            }
        </>
    );

    if (props.columnSpan || props.columnStart || renderAsGrid) {
        return (
            <GridColumn
                className={containerStyles}
                columnSpan={props.columnSpan}
                columnStart={props.columnStart}
                marginBottom={props.marginBottom}
                marginTop={props.marginTop}
            >
                {renderHeaderElements()}
            </GridColumn>
        );
    }

    return <div css={flexContainerStyles}>{renderHeaderElements()}</div>;
};
