/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Text } from "@/ui/atoms/text";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface DraftModeBannerProps {
    className?: string;
}

export const DraftModeBanner: FunctionComponent<DraftModeBannerProps> = (
    props,
) => {
    /**
     * Globals
     */
    const router = useRouter();

    /**
     * Styles
     */
    const bannerContainerStyles = css(
        {
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            width: "100%",
            zIndex: 999,
        },
        buildStylesByBreakpoint("bottom", ColumnGaps),
        buildStylesByBreakpoint("paddingLeft", ColumnGaps),
        buildStylesByBreakpoint("paddingRight", ColumnGaps),
        props.className,
    );

    const bannerStyles = css(
        {
            alignItems: "center",
            backdropFilter: "blur(32px)",
            backgroundColor: Colors["neutral-80"],
            borderRadius: BorderRadiuses.borderMedium,
            columnGap: Spacing["spacing-3"],
            display: "flex",
            padding: Spacing["spacing-3"],
            rowGap: Spacing["spacing-2"],
        },
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column",
            small: "row",
        }),
        buildStylesByBreakpoint("width", {
            extraSmall: "100%",
            small: "auto",
        }),
    );

    const buttonStyles = css(
        { justifyContent: "center" },
        buildStylesByBreakpoint("width", {
            extraSmall: "100%",
            small: "auto",
        }),
    );

    /**
     * Rendering
     */

    return (
        <div css={bannerContainerStyles}>
            <div css={bannerStyles}>
                <Text fontWeight="medium">{`You\u2019re in draft mode`}</Text>

                <Button
                    className={buttonStyles}
                    href={`/api/exit-draft-mode?slug=${router.asPath}`}
                    variant="secondary"
                >
                    Exit draft mode
                </Button>
            </div>
        </div>
    );
};
