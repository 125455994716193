export const buttonBoxShadowStyle = {
    transition: "box-shadow 0.2s ease-in-out",
};

export const linkAnimationStyle = {
    transition:
        "text-decoration-color 0.2s ease-in-out, color 0.2s ease-in-out",
};

/*
 * Transition speeds
 */
/* eslint-disable sort-keys-custom-order/object-keys */
export const TransitionSpeeds = {
    xFast: 50,
    fast: 100,
    mediumFast: 250,
    medium: 500,
    mediumSlow: 750,
    slow: 1000,
};
/* eslint-enable sort-keys-custom-order/object-keys */

export type TransitionSpeed = keyof typeof TransitionSpeeds;

/*
 * Transition easing
 */
export const TransitionEasings = {
    easeIn: "ease-in",
    easeInOut: "ease-in-out",
    linear: "linear",
};

export type TransitionEasings = keyof typeof TransitionEasings;
