/** @jsxImportSource @emotion/react */
import { ClassNames, SerializedStyles, css } from "@emotion/react";
import gsap from "gsap";
import NextImage from "next/image";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import { StrapiMedia } from "@/types/strapi";

import { BorderRadius, BorderRadiuses } from "@/tokens/border";
import { LayoutConfiguration } from "@/tokens/media";

import { useMediaParallaxScroll } from "@/util/animation_hooks/media_animations";
import { useGlobalsContext } from "@/util/context/globals_context";
import { buildCustomAspectRatio } from "@/util/data_util";
import { generateMediaSizes } from "@/util/ui_util";

interface PictureProps extends Partial<StrapiMedia> {
    borderRadius?: BorderRadius;
    className?: SerializedStyles;
    pictureClassName?: SerializedStyles;
    priority?: boolean;
    quality?: number;
    shouldParallax?: boolean;
    sizeConfiguration?: LayoutConfiguration;
    unoptimized?: boolean;
    url: string;
}

export const Picture: FunctionComponent<PictureProps> = ({
    borderRadius = "borderLarge",
    priority = false,
    shouldParallax = false,
    ...props
}) => {
    /**
     * Globals
     */
    const sizeConfiguration = props.sizeConfiguration
        ? props.sizeConfiguration
        : "halfWidth";

    const parallaxTransformScale = 1.15;

    const isSvg = props.ext === ".svg";

    /**
     * Context
     */
    const { browser } = useGlobalsContext();

    /**
     * Safari, as a surprise to no one, has major performance
     * issues with parallax. So, we do away with it.
     */
    const _shouldParallax = browser !== "safari" ? shouldParallax : false;

    /**
     * Refs
     */
    const thumbnailRef = useRef(null);
    const containerRef = useRef(null);
    const pictureRef = useRef(null);

    /**
     * State
     */
    const [didLoad, setDidLoad] = useState(false);

    /**
     * Animations
     */
    useEffect(() => {
        if (didLoad && thumbnailRef.current) {
            gsap.to(thumbnailRef.current, {
                autoAlpha: 0,
                duration: 0,
                stagger: 0.1,
            });
        }
    }, [didLoad, thumbnailRef]);

    useMediaParallaxScroll(
        containerRef,
        pictureRef,
        _shouldParallax,
        parallaxTransformScale,
    );

    /**
     * Styles
     */
    const pictureContainerStyles = () => {
        const _buildAspectRatioStyles = () => {
            if (!props.aspectRatio && props.width && props.height) {
                return css({
                    aspectRatio: buildCustomAspectRatio(
                        props.width,
                        props.height,
                    ),
                });
            }

            if (props.aspectRatio === "stretch") {
                return css({
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    width: "100%",
                });
            }

            return css({ aspectRatio: props.aspectRatio });
        };

        return css(
            {
                borderRadius: BorderRadiuses[borderRadius],
                overflow: "hidden",
                position: "relative",
                width: "100%",
            },
            _buildAspectRatioStyles(),
            props.className,
        );
    };

    /**
     * Rendering
     */
    const renderThumbnail = () => {
        const thumbnailSize = 15;

        return (
            <ClassNames key={`image-thumbnail::${props.url}`}>
                {({ css }) => (
                    <NextImage
                        alt={`Thumbnail for ${props.alternativeText}`}
                        className={css({
                            height: "100%",
                            imageRendering: "pixelated",
                            left: 0,
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            transform: _shouldParallax
                                ? `scale(${parallaxTransformScale})`
                                : undefined,
                            width: "100%",
                        })}
                        height={thumbnailSize}
                        priority={true}
                        quality={50}
                        ref={thumbnailRef}
                        src={props.url || ""}
                        width={thumbnailSize}
                    />
                )}
            </ClassNames>
        );
    };

    const renderImage = () => {
        return (
            <ClassNames>
                {({ css }) => (
                    <NextImage
                        alt={props.alternativeText || ""}
                        className={css(
                            {
                                height: "100%",
                                left: 0,
                                objectFit: "cover",
                                opacity: didLoad ? 1 : 0,
                                position: "absolute",
                                top: 0,
                                transform: _shouldParallax
                                    ? `scale(${parallaxTransformScale})`
                                    : undefined,
                                width: "100%",
                                zIndex: 1,
                            },
                            props.pictureClassName,
                        )}
                        height={props.height}
                        loading={priority ? "eager" : "lazy"}
                        priority={priority}
                        quality={props.quality ? props.quality : 85}
                        ref={pictureRef}
                        sizes={generateMediaSizes(sizeConfiguration)}
                        src={props.url}
                        width={props.width}
                        onLoad={() => {
                            setDidLoad(true);
                        }}
                    />
                )}
            </ClassNames>
        );
    };

    return (
        <div css={pictureContainerStyles} ref={containerRef}>
            {!isSvg && renderThumbnail()}

            {renderImage()}
        </div>
    );
};
