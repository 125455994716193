/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";

export function useInterval(callback: any, delay: any, shouldInit: boolean) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        if (shouldInit) {
            savedCallback.current = callback;
        }
    }, [callback, shouldInit]);

    // Set up the interval.
    useEffect(() => {
        if (shouldInit) {
            const tick = () => {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            };

            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }
    }, [delay, shouldInit]);
}
