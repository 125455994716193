/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import Link from "next/link";
import {
    MouseEventHandler,
    RefObject,
    forwardRef,
    useRef,
    useState,
} from "react";

import { AnalyticsTrack } from "@/types/analytics";
import { ButtonData } from "@/types/data";
import { ButtonPlacementKey } from "@/types/tokens/button";

import { BorderRadiuses } from "@/tokens/border";
import { Color, Colors } from "@/tokens/color";
import { ButtonVariantKeys } from "@/tokens/configs/button_config";
import { AllIcons } from "@/tokens/configs/icon_config";
import { SpacingStyleKey, spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamily } from "@/tokens/fonts";

import { useButtonHover } from "@/util/animation_hooks/button_animations";
import { formatUrl } from "@/util/data_util";
import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import { useRudderAnalyticsTrack } from "@/util/hooks/use_rudder_analytics";
import { buildStylesByBreakpoint } from "@/util/style_util";
import {
    generateButtonBoxModelStyles,
    generateButtonTextPaddingStyles,
    generateIconMarginStyles,
    getButtonIconThemeStyles,
    getButtonThemeStyles,
} from "@/util/tokens/button_util";
import { getButtonTheme } from "@/util/tokens/themes_util";

import { Icon } from "./icon";
import { Text } from "./text";

interface ButtonProps extends ButtonData {
    className?: SerializedStyles;
    color?: Color;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    iconClassName?: SerializedStyles;
    iconPlacement?: ButtonPlacementKey;
    iconSlug?: AllIcons;
    isDisabled?: boolean;
    keyboardShortcut?: string;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    renderIconContainer?: boolean;
    textClassName?: SerializedStyles;
    track?: AnalyticsTrack;
    variant?: ButtonVariantKeys;
}

export const Button = forwardRef<
    HTMLAnchorElement | HTMLButtonElement,
    ButtonProps
>(
    (
        {
            fontFamily = "sans",
            fontSize = "TextDefault",
            iconSlug = "Arrow Right",
            renderIconContainer = true,
            variant = "primary",
            ...props
        },
        ref,
    ) => {
        /**
         * Refs
         */
        const buttonTextRef = useRef(null);
        const localButtonRef = useRef<HTMLAnchorElement | HTMLButtonElement>();

        const buttonContainerRef = useCombinedRefs<
            HTMLAnchorElement | HTMLButtonElement
        >(ref, localButtonRef);

        const iconContainerRef = useRef<HTMLSpanElement>(null);

        /**
         * State Management
         */
        const [isHovered, setIsHovered] = useState(false);
        const [isActive, setIsActive] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        /**
         * Animations
         */
        useButtonHover(
            buttonContainerRef,
            iconContainerRef,
            getButtonTheme(variant),
            {
                isActive,
                isFocused,
                isHovered,
            },
            renderIconContainer,
        );

        /**
         * Interactivity
         */

        const track = useRudderAnalyticsTrack();

        const handleClick: MouseEventHandler<
            HTMLAnchorElement | HTMLButtonElement
        > = (event) => {
            if (props.track) {
                track(props.track);
            }
            props.onClick?.(event);
        };

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        const handleMouseDown = () => {
            setIsActive(true);
        };

        const handleMouseUp = () => {
            setIsActive(false);
            setIsFocused(false);
        };

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        /**
         * Styles
         */
        // TODO abstract vertical margin helper GROW-4905
        const marginYStyles = [
            props.marginTop
                ? buildStylesByBreakpoint(
                      "marginTop",
                      spacingSets[props.marginTop],
                  )
                : undefined,
            props.marginBottom
                ? buildStylesByBreakpoint(
                      "marginBottom",
                      spacingSets[props.marginBottom],
                  )
                : undefined,
        ];

        const buttonBaseStyles = css(
            {
                alignItems: "center",
                backdropFilter: "blur(16px)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection:
                    props.iconPlacement === "status" ? "row-reverse" : "row",
                textDecoration: "none",
            },
            marginYStyles,
            generateButtonBoxModelStyles(fontSize, props.iconPlacement),
            getButtonThemeStyles(variant),
            props.className,
        );

        const buttonLinkElementStyles = css(buttonBaseStyles, {
            textDecoration: "none",
        });

        const buttonElementStyles = css(buttonBaseStyles, {
            appearance: "none",
            border: "none",
            cursor: props.isDisabled ? "default" : "pointer",
        });

        const buttonTextElementStyles = css(
            { alignItems: "center", display: "flex" },
            generateButtonTextPaddingStyles(fontSize),
            buildStylesByBreakpoint("whiteSpace", {
                extraSmall: "normal",
                medium: "nowrap",
            }),
            props.textClassName,
        );

        const iconContainerStyles = css(
            { display: "flex" },
            generateIconMarginStyles(fontSize),
        );

        const keyboardShortcutContainerStyles = css(
            {
                alignItems: "center",
                boxSizing: "border-box",
                display: "flex",
                height: "100%",
                justifyContent: "center",
            },
            generateIconMarginStyles(fontSize),
        );

        const keyboardShortcutStyles = css({
            alignItems: "center",
            aspectRatio: "1 / 1",
            background: Colors["blue-20"],
            borderRadius: BorderRadiuses.borderSmall,
            display: "flex",
            height: "100%",
            justifyContent: "center",
        });

        const keyboardShortcutTextStyles = css({
            left: ".075em",
            position: "relative",
        });

        /**
         * Rendering
         */
        const renderIcon = () => {
            return (
                <span css={iconContainerStyles}>
                    <Icon
                        className={css(
                            getButtonIconThemeStyles(
                                variant,
                                renderIconContainer,
                            ),
                            props.iconClassName,
                        )}
                        fontSize={fontSize}
                        ref={iconContainerRef}
                        renderContainer={renderIconContainer}
                        slug={iconSlug}
                    />
                </span>
            );
        };

        const renderChildren = () => (
            <>
                <Text
                    className={buttonTextElementStyles}
                    color="currentColor"
                    fontFamily={fontFamily}
                    fontSize={fontSize}
                    fontWeight="medium"
                    labelLineHeight={true}
                    ref={buttonTextRef}
                    tag="span"
                    textAlign="center"
                >
                    {props.children}
                </Text>

                {props.iconPlacement && renderIcon()}
            </>
        );

        /*
         * If we pass the href attribute, we should render as a Link
         * but with button styles.
         */
        if (props.href) {
            return (
                <Link
                    css={buttonLinkElementStyles}
                    href={formatUrl(props.href)}
                    ref={buttonContainerRef as RefObject<HTMLAnchorElement>}
                    rel={props.newWindow ? "noopener noreferrer" : undefined}
                    target={props.newWindow ? "_blank" : undefined}
                    onBlur={handleBlur}
                    onClick={handleClick}
                    onFocus={handleFocus}
                    onMouseDown={handleMouseDown}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                >
                    {renderChildren()}
                </Link>
            );
        }

        /*
         * If we don't pass the href attribute, we will render a button.
         * But first, we throw an error if onClick isn't defined.
         */
        if (!props.onClick) {
            throw new Error(
                "When defining a button, you must also define onClick.",
            );
        }

        return (
            <button
                css={buttonElementStyles}
                disabled={props.isDisabled}
                ref={buttonContainerRef as RefObject<HTMLButtonElement>}
                onBlur={handleBlur}
                onClick={handleClick}
                onFocus={handleFocus}
                onMouseDown={handleMouseDown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
            >
                {renderChildren()}
            </button>
        );
    },
);

Button.displayName = "Button";
