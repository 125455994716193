/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";
import { FunctionComponent } from "react";

import { AnalyticsTrack } from "@/types/analytics";
import { OperatingSystem } from "@/types/data";
import type { StrapiDownloadGroup, StrapiDownloadOption } from "@/types/strapi";

import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";

import { useGlobalsContext } from "@/util/context/globals_context";
import { getDownloadOptionTrack } from "@/util/download_option_util";
import type { RenderLocation } from "@/util/tokens/render_location_util";

import { DownloadButton } from "./download_button";

interface DownloadButtonsProps {
    className?: SerializedStyles;
    fontSize?: FontStyleSlug;
    keyboardShortcut?: string;
    renderLocation: RenderLocation;
    renderSecondaryText?: boolean;
}

export const DownloadButtons: FunctionComponent<DownloadButtonsProps> = ({
    ...props
}) => {
    /**
     * Context
     */
    const { downloads, operatingSystem } = useGlobalsContext();

    /**
     * Util
     */
    /**
     * getDownloadGroupByOs
     *
     * This method is responsible for cross-referencing the available
     * downloads from Strapi (which as of 9/2024, include Linux, Mac, and Windows OS)
     * and the visitor's UA.
     *
     * Because the OS string pulled from react-device-detect and the OS string
     * pulled from Strapi can be different, this method makes sure that they are
     * formatted the same so we can verify matches.
     */
    const getDownloadGroupByOs = () => {
        // Checks if the Strapi download group matches the visitor's current UA
        const _checkOsStringMatch = (
            _downloadGroup: StrapiDownloadGroup,
            _osName: OperatingSystem = "mac",
        ) => {
            return _downloadGroup.OS_Name.toLowerCase() === _osName;
        };

        // This component is only responsible for buttons, so we exclude any waitlist types
        const _checkIsNotWaitlist = (_downloadGroup: StrapiDownloadGroup) => {
            return _.every(
                _downloadGroup.Primary_Action,
                (primaryAction) =>
                    primaryAction.__component !==
                    "components.component-waitlist-form",
            );
        };

        // Checks if there is an OS string match (Strapi + UA) and if the type is not waitlist
        const _isOsMatch = _.find(downloads, (_download) => {
            const _osMatches = _checkOsStringMatch(_download, operatingSystem);
            const _componentIdIsValid = _checkIsNotWaitlist(_download);
            return _osMatches && _componentIdIsValid;
        });

        // As our first fallback, obtain the Mac download group from Strapi
        const _macOs = _.find(downloads, (_download) =>
            _checkOsStringMatch(_download),
        );

        // As our second fallback, obtain the first download group from Strapi
        const _firstOs = _.find(downloads, (_download) =>
            _checkIsNotWaitlist(_download),
        ) as StrapiDownloadGroup;

        // Return the OS match, then the Mac fallback, and then the first download group fallback
        return _isOsMatch || _macOs || _firstOs;
    };

    /**
     * Styles
     */

    const containerStyles = css(
        {
            display: "flex",
            flexWrap: "wrap",
            gap: Spacing["spacing-2"],
        },
        props.className,
    );

    /**
     * Elements
     */
    const downloadGroup = getDownloadGroupByOs();

    return (
        <div css={containerStyles}>
            {(downloadGroup.Primary_Action as Array<StrapiDownloadOption>).map(
                (action, index) => {
                    return (
                        <DownloadButton
                            actionData={action}
                            fontSize={props.fontSize}
                            key={`download-button::${action.URL}::${index}`}
                            keyboardShortcut={props.keyboardShortcut}
                            os={downloadGroup.OS_Name}
                            renderSecondaryText={props.renderSecondaryText}
                            track={getDownloadOptionTrack(
                                action,
                                props.renderLocation,
                            )}
                        />
                    );
                },
            )}
        </div>
    );
};
