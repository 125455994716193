import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";

import { TypedRefObject } from "@/types/interactivity";
import { ActivityThemeState, ButtonTheme } from "@/types/tokens/themes";

import { Colors } from "@/tokens/color";

import { CodeCtaInteractiveState } from "@/ui/atoms/code_cta";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";

export interface ButtonInteractiveState {
    isActive: boolean;
    isFocused: boolean;
    isHovered: boolean;
}

export function useButtonHover(
    buttonContainerRef: TypedRefObject,
    iconContainerRef: TypedRefObject,
    buttonTheme: ButtonTheme,
    interactiveState: ButtonInteractiveState,
    renderIconContainer: boolean,
) {
    const animationRef = useRef<gsap.core.Animation | null>(null);

    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        if (buttonContainerRef.current) {
            gsap.set(buttonContainerRef.current, {
                background: Colors[buttonTheme.background.default],
                boxShadow: Colors[buttonTheme.boxShadow.default],
                onComplete: () => {
                    setDidInit(true);
                },
            });
        }
    }, [
        buttonContainerRef,
        buttonTheme.background.default,
        buttonTheme.boxShadow.default,
    ]);

    useEffect(() => {
        const _getCurrentState = (): ActivityThemeState => {
            const { isActive, isFocused, isHovered } = interactiveState;

            if (isFocused) {
                return "focus";
            } else if (isActive) {
                return "active";
            } else if (isHovered) {
                return "hover";
            } else {
                return "default";
            }
        };

        if (didInit) {
            const currentState = _getCurrentState();

            const background = Colors[buttonTheme.background[currentState]],
                color = Colors[buttonTheme.text[currentState]],
                boxShadow = Colors[buttonTheme.boxShadow[currentState]];

            animationRef.current = gsap.to(buttonContainerRef.current, {
                background,
                boxShadow,
                color,
            });

            const iconBackground =
                    Colors[buttonTheme.iconContainer[currentState]],
                iconColor = Colors[buttonTheme.icon[currentState]];

            if (iconContainerRef.current && renderIconContainer) {
                animationRef.current = gsap.to(iconContainerRef.current, {
                    background: iconBackground,
                    color: iconColor,
                });
            }
        }

        return () => {
            animationRef.current?.kill();
        };
    }, [
        interactiveState,
        didInit,
        buttonContainerRef,
        buttonTheme.background,
        buttonTheme.boxShadow,
        buttonTheme.icon,
        buttonTheme.iconContainer,
        buttonTheme.text,
        iconContainerRef,
        renderIconContainer,
    ]);
}

/**
 * Code Snippet Icon Swap
 */
export function useCodeCtaIconSwapAnimation(
    defaultIconContainerRef: TypedRefObject,
    activeIconContainerRef: TypedRefObject,
    successIconContainerRef: TypedRefObject,
    interactiveState: CodeCtaInteractiveState,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        gsap.set(defaultIconContainerRef.current, {
            autoAlpha: 1,
            scale: 1,
        });

        gsap.set(
            [activeIconContainerRef.current, successIconContainerRef.current],
            {
                autoAlpha: 0,
                onComplete: () => {
                    setDidInit(true);
                },
                scale: 0.75,
            },
        );
    }, []);

    useEffect(() => {
        const _elasticEasing = "elastic.out(2, 0.4)";

        const _animateOutConfig: gsap.TweenVars = {
            autoAlpha: 0,
            duration: 0.15,
            scale: 0.75,
        };

        const _animateAutoAlphaInConfig: gsap.TweenVars = {
            autoAlpha: 1,
            duration: 0.25,
        };

        const _animateScaleInConfig: gsap.TweenVars = {
            duration: 0.65,
            ease: _elasticEasing,
            scale: 1,
        };

        if (didInit) {
            if (interactiveState === "hover") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateOutConfig,
                );

                _timeline.to(
                    activeIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    activeIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            } else if (interactiveState === "active") {
                const _animation = gsap.to(activeIconContainerRef.current, {
                    duration: 0.65,
                    ease: _elasticEasing,
                    scale: 0.85,
                });

                return () => {
                    _animation.kill();
                };
            } else if (interactiveState === "complete") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(activeIconContainerRef.current, {
                    autoAlpha: 0,
                    duration: 0.15,
                    scale: 1,
                });

                _timeline.to(
                    successIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    successIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            } else if (interactiveState === "default") {
                const _timeline = gsap.timeline({ paused: true });

                _timeline.to(
                    [
                        activeIconContainerRef.current,
                        successIconContainerRef.current,
                    ],
                    _animateOutConfig,
                );

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateAutoAlphaInConfig,
                );

                _timeline.to(
                    defaultIconContainerRef.current,
                    _animateScaleInConfig,
                    "<",
                );

                _timeline.play();

                return () => {
                    _timeline.kill();
                };
            }
        }
    }, [
        interactiveState,
        activeIconContainerRef,
        defaultIconContainerRef,
        didInit,
        successIconContainerRef,
    ]);
}
