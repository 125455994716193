/** @jsxImportSource @emotion/react */
import slugify from "@sindresorhus/slugify";
import * as _ from "lodash-es";
import { CSSProperties } from "react";

import { MarkdownComponents } from "@/types/tokens/serializer";
import { SerializerNode } from "@/types/ui";

import {
    SerializerConfigKeys,
    SerializerConfigs,
} from "@/tokens/configs/serializer_config";

import { Link } from "@/ui/atoms/link";
import { List } from "@/ui/atoms/list";
import { ListItem } from "@/ui/atoms/list_item";
import { Quote } from "@/ui/atoms/quote";
import { Table } from "@/ui/atoms/table";
import { Text } from "@/ui/atoms/text";
import { MarkdownCode } from "@/ui/organisms/markdown_code";

export const Serializer = (
    configKey: SerializerConfigKeys,
): MarkdownComponents => {
    const config = SerializerConfigs[configKey];
    const { baseline: baselineConfig } = config;

    const _getTagName = (node: SerializerNode) => {
        return node.tagName || node.type;
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const _getConfigByAttributes = (
        node: SerializerNode,
        markdownStyle?: CSSProperties,
    ): any => {
        const generateMarginTopOverride = () => {
            const _isFirstChild = node?.position?.start.offset === 0;
            const _isTopLevel = !node.parent || node.parent.type === "root";

            return _isFirstChild && _isTopLevel ? { marginTop: 0 } : {};
        };

        const _tagName = _getTagName(node);

        if (_tagName && _.has(config, _tagName)) {
            const _slugify =
                config[_tagName]?.shouldSlugify && node.children[0].value;

            return {
                // Set baseline tag prop (Text defaults to p)
                tag: _tagName,

                // Set any baseline configurations that may be overridden by tag-specific configurations
                ...baselineConfig,

                // Slugifies the string and injects as id if valid
                ...(_slugify && {
                    id: slugify(node.children[0].value),
                }),

                // Tag-specific configurations
                ...config[_tagName],

                // Removing the top margin if it's the first item
                ...generateMarginTopOverride(),

                // Add any default styles from markdown (td/th text alignment)
                ...markdownStyle,
            };
        }

        return {
            // Baseline configurations that apply to any tag
            ...baselineConfig,

            // Removing the top margin if it's the first item
            ...generateMarginTopOverride(),

            // Add any default styles from markdown (td/th text alignment)
            ...markdownStyle,
        };
    };

    return {
        a: ({ children, href, node }) => (
            <Link {..._getConfigByAttributes(node)} href={href ?? ""}>
                {children}
            </Link>
        ),
        blockquote: ({ children, node }) => (
            <Quote {..._getConfigByAttributes(node)}>{children}</Quote>
        ),
        code: ({ children, className, node, ...rest }) => (
            <MarkdownCode
                {...rest}
                {..._getConfigByAttributes(node)}
                language={className?.replace("language-", "") || ""}
            >
                {children}
            </MarkdownCode>
        ),
        em: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h1: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h2: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h3: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h4: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h5: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        h6: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        // Intentionally skipping hr and img
        hr: () => null,
        img: () => null,
        li: ({ children, node, ...props }) => (
            <ListItem {..._getConfigByAttributes(node)} {...props}>
                {children}
            </ListItem>
        ),
        ol: ({ children, node }) => (
            <List {..._getConfigByAttributes(node)} tag="ol">
                {children}
            </List>
        ),
        p: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        pre: ({ children }) => children,
        strong: ({ children, node }) => (
            <Text {..._getConfigByAttributes(node)}>{children}</Text>
        ),
        table: ({ children, node }) => (
            <Table {..._getConfigByAttributes(node)}>{children}</Table>
        ),
        tbody: ({ children, node }) => (
            <tbody {..._getConfigByAttributes(node)}>{children}</tbody>
        ),
        td: ({ children, node, style }) => (
            <Text {..._getConfigByAttributes(node, style)}>{children}</Text>
        ),
        th: ({ children, node, style }) => (
            <Text {..._getConfigByAttributes(node, style)}>{children}</Text>
        ),
        tr: ({ children, node }) => (
            <tr {..._getConfigByAttributes(node)}>{children}</tr>
        ),

        ul: ({ children, node }) => (
            <List {..._getConfigByAttributes(node)}>{children}</List>
        ),
    };
};
