/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLottieAnimation } from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { ImageAspectRatioString } from "@/tokens/media";
import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { LottieAnimation } from "@/ui/atoms/lottie_animation";
import { Picture } from "@/ui/atoms/picture";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { LottieCarousel } from "@/ui/molecules/lottie_carousel";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface ProductAnimationProps extends StrapiLottieAnimation {
    animationColumnSpan?: GridColumnSpan;
    animationColumnStart?: GridColumnCount;
    className?: SerializedStyles;
    containerColumnSpan?: GridColumnSpan;
    containerColumnStart?: GridColumnCount;
    placeholderAspectRatio?: ImageAspectRatioString;
}

export const ProductAnimation: FunctionComponent<ProductAnimationProps> = (
    props,
) => {
    /**
     * Globals
     */
    const shouldRenderGrid =
        props.containerColumnSpan && props.containerColumnStart;

    /**
     * Styles
     */
    const containerStyles = css(
        {
            padding: Spacing["spacing-12"],
            position: "relative",
        },
        props.className,
        props.Lottie_Files.length > 1 &&
            buildStylesByBreakpoint("padding", {
                extraSmall: `${Spacing["spacing-8"]} ${Spacing["spacing-0"]}`,
                medium: `${Spacing["spacing-8"]} ${Spacing["spacing-0"]}`,
                large: convertToRem(36),
            }),
    );

    /**
     * Rendering
     */
    const renderAnimation = () => {
        const _singleLottieAnimation = props.Lottie_Files.length === 1 && (
            <LottieAnimation
                className={css({
                    height: "100%",
                    width: "100%",
                })}
                path={props.Lottie_Files[0].Lottie_File.url}
            />
        );

        const _lottieCarousel = props.Lottie_Files.length > 1 && (
            <LottieCarousel
                className={css({
                    height: "100%",
                    width: "100%",
                })}
                Lottie_Files={props.Lottie_Files}
            />
        );

        if (shouldRenderGrid) {
            return (
                <GridColumn
                    className={css({
                        aspectRatio: props.placeholderAspectRatio,
                        zIndex: 10,
                    })}
                    columnSpan={props.animationColumnSpan}
                    columnStart={props.animationColumnStart}
                >
                    {_singleLottieAnimation}

                    {_lottieCarousel}
                </GridColumn>
            );
        }

        return _singleLottieAnimation;
    };

    const renderBackgroundImage = () => {
        return (
            <Picture
                alternativeText="Background gradient for product animation"
                aspectRatio="stretch"
                height={props.Background_Media.height}
                priority={true}
                url={props.Background_Media.url}
                width={props.Background_Media.width}
            />
        );
    };

    return shouldRenderGrid ? (
        <SubgridContainer
            className={containerStyles}
            columnSpan={props.containerColumnSpan}
            columnStart={props.containerColumnStart}
        >
            {renderAnimation()}

            {renderBackgroundImage()}
        </SubgridContainer>
    ) : (
        <div css={containerStyles}>
            {renderAnimation()}

            {renderBackgroundImage()}
        </div>
    );
};
