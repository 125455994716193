/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { Fragment } from "react";
import { FunctionComponent } from "react";

import { Breadcrumbs as BreadcrumbsType, LinkData } from "@/types/data";

import { ColumnGaps, HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { Text } from "@/ui/atoms/text";

import { buildStylesByBreakpoint } from "@/util/style_util";

const breadcrumbLevels = ["level1", "level2", "level3"] as const;
export type BreadcrumbLevels = (typeof breadcrumbLevels)[number];

interface BreadcrumbsProps {
    breadcrumbs?: BreadcrumbsType;
    className?: SerializedStyles;
    isLegacy?: boolean;
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
    isLegacy = true,
    ...props
}) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            position: "relative",
        },
        buildStylesByBreakpoint(
            "paddingLeft",
            isLegacy ? ColumnGaps : HalfColumnGaps,
        ),
        buildStylesByBreakpoint(
            "paddingRight",
            isLegacy ? ColumnGaps : HalfColumnGaps,
        ),
        props.className,
    );

    const breadcrumbListStyles = css({
        alignItems: "baseline",
        columnGap: Spacing["spacing-1"],
        display: "flex",
        paddingBottom: isLegacy ? Spacing["spacing-3"] : Spacing["spacing-2"],
        paddingTop: isLegacy ? Spacing["spacing-3"] : Spacing["spacing-2"],
        position: "relative",
    });

    const dividerStyles = css({
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    });

    const renderBreadcrumb = (crumb: LinkData, index: number) => {
        const isLast = index === Object.values(props.breadcrumbs!).length - 1;

        return (
            <>
                {isLast ? (
                    <Text
                        className={css({
                            textWrap: "nowrap",
                        })}
                        fontSize="TEXT_0875"
                        labelLineHeight={true}
                        tag="li"
                        themeKey="textEmphasis"
                    >
                        {crumb.children}
                    </Text>
                ) : (
                    <>
                        <li>
                            <Link
                                fontSize="TEXT_0875"
                                fontWeight="normal"
                                href={crumb.href}
                                interactionType="underline-appear"
                                labelLineHeight={true}
                                noWrap={true}
                            >
                                {crumb.children}
                            </Link>
                        </li>

                        {renderBreadcrumbArrow()}
                    </>
                )}
            </>
        );
    };

    const renderBreadcrumbArrow = () => {
        return (
            (props.breadcrumbs?.level1 ||
                props.breadcrumbs?.level2 ||
                props.breadcrumbs?.level3) && (
                <Icon color="lighten-60" size={11} slug="Caret Right" />
            )
        );
    };

    if (!props.breadcrumbs) {
        return null;
    }

    return (
        <nav css={containerStyles}>
            {isLegacy && <Divider className={dividerStyles} />}

            <ol css={breadcrumbListStyles}>
                {Object.values(props.breadcrumbs).map((item, index) => (
                    <Fragment key={`breadcrumb::list-item::${item.children}`}>
                        {renderBreadcrumb(item, index)}
                    </Fragment>
                ))}
            </ol>
        </nav>
    );
};
