/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import NextLink from "next/link";
import { MouseEventHandler, forwardRef } from "react";

import { StrapiNotification } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { Icon } from "@/ui/atoms/icon";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { formatUrl } from "@/util/data_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface PushNotificationProps extends StrapiNotification {
    className?: SerializedStyles;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const PushNotification = forwardRef<
    HTMLDivElement,
    PushNotificationProps
>((props, ref) => {
    /**
     * Variables
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const pushNotificationContainerStyles = css({
        backdropFilter: backgroundBlur("blurMedium"),
        background: Colors[theme.backgrounds.backgroundSecondary],
        borderRadius: BorderRadiuses.borderLarge,
        display: "flex",
    });

    const linkStyles = css(
        {
            columnGap: Spacing["spacing-3"],
            display: "flex",
            padding: Spacing["spacing-2"],
            textDecoration: "none",
            transition: `background ${TransitionSpeeds.fast}ms ${TransitionEasings.linear}`,
            width: "100%",
        },
        {
            "&:hover": {
                background: Colors[theme.backgrounds.backgroundTertiary],
            },
        },
    );

    const thumbnailStyles = css(
        { flexShrink: 0 },
        buildStylesByBreakpoint("width", {
            extraSmall: convertToRem(59),
            extraLarge: convertToRem(64),
        }),
    );

    const textContainerStyles = css({
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
    });

    /**
     * Rendering
     */
    return (
        <div css={pushNotificationContainerStyles} ref={ref}>
            <NextLink
                css={linkStyles}
                href={formatUrl(props.URL)}
                onClick={props.onClick}
            >
                <Picture
                    {...props.Thumbnail}
                    aspectRatio="1 / 1"
                    borderRadius="borderSmall"
                    className={thumbnailStyles}
                    priority={true}
                />

                <div css={textContainerStyles}>
                    <div>
                        <Text
                            fontSize="Micro"
                            fontWeight="medium"
                            themeKey="headlinePrimary"
                        >
                            {props.Headline}
                        </Text>

                        <Text fontSize="Micro">{props.Subheadline}</Text>
                    </div>

                    <Icon
                        color={theme.ui.uiInactive}
                        size={12}
                        slug="Caret Right"
                    />
                </div>
            </NextLink>
        </div>
    );
});

PushNotification.displayName = "PushNotification";
