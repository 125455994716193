import Head from "next/head";

import { MetaData } from "@/types/data";
import { StrapiGlobalData, StrapiSEO } from "@/types/strapi";

import { HeadTag } from "@/tokens/head";

/**
 * Build the standardized meta data object that maps to the MetaData type
 */
export function buildMetaData(
    globalsData: StrapiGlobalData,
    pageMetaData: StrapiSEO | null | undefined,
    path?: string,
): MetaData {
    const { Base_SEO } = globalsData;
    const pageUrl = generateUrlFromSlug(path);

    return {
        canonicalUrl: pageMetaData?.Canonical_URL ?? pageUrl,
        description: pageMetaData?.Description ?? Base_SEO.Description!,
        faviconUrl: globalsData.Favicon.url,
        openGraphUrl:
            pageMetaData?.OpenGraph_Image?.url ?? Base_SEO.OpenGraph_Image!.url,
        pageTitle: pageMetaData?.Page_Title,
        pageUrl,
        path,
        shouldIndex: !!pageMetaData?.Should_Index,
        siteTitle: globalsData.Site_Title,
    };
}

/**
 * Generate meta data fields
 */
export function generateMetaData(metaData: MetaData) {
    const {
        canonicalUrl,
        description,
        openGraphUrl,
        pageTitle,
        pageUrl,
        path,
        shouldIndex,
        siteTitle,
    } = metaData;

    const seoTitle = generatePageTitleString(siteTitle, pageTitle, path);
    const robotsContent = shouldIndex ? "index, follow" : "noindex, nofollow";

    return (
        <Head>
            <title>{seoTitle}</title>

            <meta content={seoTitle} property="og:title" />

            <link href="/favicon.png" rel="icon" type="image/x-icon" />

            <meta content="website" key="og-type" property="og:type" />

            <meta content={siteTitle} property="og:site_name" />

            <meta content={description} name="description" />

            <meta content={description} property="og:description" />

            <meta content={pageUrl} property="og:url" />

            <meta content={robotsContent} name="robots" />

            <meta content={openGraphUrl} property="og:image" />

            <meta charSet="UTF-8" />

            <meta
                content="minimum-scale=1, initial-scale=1, width=device-width"
                name="viewport"
            />

            <link
                href={canonicalUrl}
                key={HeadTag.CANONICAL_URL}
                rel="canonical"
            />
        </Head>
    );
}

/**
 * Build the url for meta data
 */
export function generateUrlFromSlug(path?: string) {
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";

    const host =
        process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL ??
        "localhost:3000";

    const base = `${protocol}://${host}`;
    if (
        path &&
        /**
         * Match next.js default trailing slash redirect behavior
         * https://nextjs.org/docs/app/api-reference/next-config-js/trailingSlash
         */
        path !== "/"
    ) {
        return new URL(path, base).toString();
    }
    return base;
}

/**
 * Formats the page title depending on presence of path and subtitle
 */
function generatePageTitleString(
    siteTitle: string,
    subTitle?: string,
    path?: string,
) {
    if (!path) {
        return subTitle ? `${siteTitle}: ${subTitle}` : siteTitle;
    }

    return subTitle ? `${subTitle} | ${siteTitle}` : siteTitle;
}
