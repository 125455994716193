/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import type {
    StrapiDownloadGroup,
    StrapiDownloadOption,
    StrapiDownloadRow,
} from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { CodeCta } from "@/ui/atoms/code_cta";
import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { OperatingSystemIcon } from "@/ui/atoms/operating_system_icon";
import { Text } from "@/ui/atoms/text";
import { NestedForm } from "@/ui/molecules/nested_form";

import { getCodeCtaTrack } from "@/util/code_cta_util";
import { getDownloadOptionTrack } from "@/util/download_option_util";
import { buildStylesByBreakpoint } from "@/util/style_util";
import type { RenderLocation } from "@/util/tokens/render_location_util";

interface DownloadGroupProps extends StrapiDownloadGroup {
    className?: SerializedStyles;
    renderLocation?: RenderLocation;
}

export const DownloadGroupItem: FunctionComponent<DownloadGroupProps> = (
    props,
) => {
    /**
     * Globals
     */
    const _castedOS_Name = props.OS_Name.toLowerCase() as
        | "mac"
        | "windows"
        | "linux";

    /*
     * Helpers
     */
    const getIconSize = {
        linux: {
            height: 48,
            width: 39.7,
        },
        mac: {
            height: 40,
            width: 37.113,
        },
        windows: {
            height: 36,
            width: 44.47,
        },
    };

    /**
     * Styles
     */
    const downloadCardStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },

        buildStylesByBreakpoint("gap", {
            extraSmall: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
        }),
        buildStylesByBreakpoint("padding", {
            medium: `${Spacing["spacing-6"]} 0`,
        }),
    );

    const downloadGroupHeaderStyles = css({
        display: "grid",
        gap: Spacing["spacing-3"],
    });

    const downloadGroupTextStyles = css({
        alignItems: "baseline",
        display: "flex",
        gap: Spacing["spacing-3"],
    });

    const primaryButtonContainerStyles = css({
        alignItems: "start",
        display: "flex",
        gap: Spacing["spacing-3"],
        width: "100%",
    });

    const primaryButtonGroupStyles = css({
        display: "flex",
        flex: "1 0 0",
        flexDirection: "column",
        rowGap: Spacing["spacing-3"],
    });

    const primaryButtonStyles = css(
        {
            display: "flex",
            justifyContent: "center",
        },
        {
            "&:is(:hover, :focus) *": {
                color: Colors["lighten-80"],
            },
        },
    );

    const primaryButtonTextStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const secondaryButtonGroupStyles = css({
        alignItems: "center",
        display: "flex",
        gap: Spacing["spacing-3"],
        justifyContent: "space-between",
    });

    const secondaryButtonTextStyles = css({
        alignItems: "baseline",
        display: "flex",
        gap: Spacing["spacing-2"],
    });

    const secondaryDownloadRowButtonGroupStyles = css({
        alignItems: "start",
        display: "flex",
        gap: Spacing["spacing-3"],
    });

    const secondaryDownloadButtonStyles = css(
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
        }),
    );

    const iconWrapperStyles = css({
        display: "flex",
        flexDirection: "column",
        height: Object.values(getIconSize).reduce(
            (acc, size) => (size.height > acc ? size.height : acc), // get the height of the largest icon
            0,
        ),
        justifyContent: "center",
        maxWidth: "fit-content",
        width: getIconSize[_castedOS_Name].width,
    });

    /**
     * Rendering
     */
    const renderPrimaryDownloadButtons = () => {
        const _castedPrimaryActions =
            props.Primary_Action as Array<StrapiDownloadOption>;

        return (
            <div css={primaryButtonContainerStyles}>
                {_castedPrimaryActions.map((action) => {
                    return (
                        <div
                            css={primaryButtonGroupStyles}
                            key={`primary-download-button::${action.id}`}
                        >
                            <Button
                                className={primaryButtonStyles}
                                fontSize="DownloadButtonCTA"
                                href={action.URL}
                                textClassName={primaryButtonTextStyles}
                                track={getDownloadOptionTrack(
                                    action,
                                    props.renderLocation,
                                )}
                                variant="primary"
                            >
                                <Text
                                    color="darken-90"
                                    fontSize="DownloadPrimaryButtonHeading"
                                    textAlign="center"
                                >
                                    {action.Text}
                                </Text>

                                <Text
                                    color="darken-60"
                                    fontSize="DownloadPrimaryButtonSubheading"
                                    textAlign="center"
                                >
                                    {action.Secondary_Text}
                                </Text>
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderSecondaryDownloadButtons = () => {
        const _castedSecondaryActions =
            props.Secondary_Action as Array<StrapiDownloadRow>;

        return (
            <div>
                {_castedSecondaryActions.map(
                    (_secondaryActions, _index, _arr) => {
                        return (
                            <div
                                key={`secondary-download-button-group::${_secondaryActions.id}`}
                            >
                                <div css={secondaryButtonGroupStyles}>
                                    <div css={secondaryButtonTextStyles}>
                                        <Text
                                            fontSize="DownloadButtonCTA"
                                            themeKey="headlinePrimary"
                                        >
                                            {_secondaryActions.Title}
                                        </Text>

                                        {_secondaryActions.Subtitle && (
                                            <Text
                                                fontSize="TEXT_075"
                                                themeKey="textSecondary"
                                            >
                                                {_secondaryActions.Subtitle}
                                            </Text>
                                        )}
                                    </div>

                                    <div
                                        css={
                                            secondaryDownloadRowButtonGroupStyles
                                        }
                                    >
                                        {_secondaryActions.Download_Option.map(
                                            (_cta) => {
                                                return (
                                                    <Button
                                                        className={
                                                            secondaryDownloadButtonStyles
                                                        }
                                                        fontSize="DownloadSecondaryButton"
                                                        href={_cta.URL}
                                                        key={`secondary-download-button::${_cta.Text}`}
                                                        track={getDownloadOptionTrack(
                                                            _cta,
                                                            props.renderLocation,
                                                        )}
                                                        variant="secondary"
                                                    >
                                                        {_cta.Text}
                                                    </Button>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>

                                {_index < _arr.length - 1 && (
                                    <Divider
                                        className={css({
                                            marginBottom: Spacing["spacing-3"],
                                            marginTop: Spacing["spacing-3"],
                                        })}
                                    />
                                )}
                            </div>
                        );
                    },
                )}
            </div>
        );
    };

    const renderPrimaryAction = () => {
        const { Primary_Action } = props;

        if (Primary_Action[0].__component === "data.data-download-option") {
            return renderPrimaryDownloadButtons();
        }

        return (
            <NestedForm
                alignment="start"
                formEndpoint={
                    process.env.NEXT_PUBLIC_WINDOWS_WAITLIST_ENDPOINT!
                }
                formName="windows waitlist"
                placeholder={Primary_Action[0].Placeholder_Text}
                submitText={Primary_Action[0].CTA_Text}
                title={Primary_Action[0].Title}
            />
        );
    };

    const renderSecondaryAction = () => {
        const { Secondary_Action } = props;

        if (!Secondary_Action || Secondary_Action.length === 0) {
            return;
        }

        if (
            Secondary_Action[0].__component === "primitives.primitive-code-cta"
        ) {
            const code = Secondary_Action[0].Code;
            return (
                <CodeCta
                    className={css({ justifyContent: "center" })}
                    code={code}
                    fontSize="PlanCardDetails"
                    track={getCodeCtaTrack(code)}
                />
            );
        }

        if (
            Secondary_Action[0].__component ===
            "components.component-download-row"
        ) {
            return renderSecondaryDownloadButtons();
        }
    };

    return (
        <GridColumn className={downloadCardStyles}>
            <div css={downloadGroupHeaderStyles}>
                <OperatingSystemIcon
                    className={iconWrapperStyles}
                    slug={_castedOS_Name}
                />

                <div css={downloadGroupTextStyles}>
                    <Text fontSize="LongformHeadlineDefault" tag="h2">
                        {props.OS_Name}
                    </Text>

                    {props.Primary_Action[0].__component ===
                        "components.component-waitlist-form" && (
                        <Text
                            fontSize="DownloadGroupComingSoonText"
                            themeKey="textSecondary"
                        >
                            Coming soon
                        </Text>
                    )}
                </div>
            </div>

            {renderPrimaryAction()}

            {renderSecondaryAction()}
        </GridColumn>
    );
};
