import * as _ from "lodash-es";

import { Breakpoint, BreakpointBases } from "@/tokens/breakpoints";
import {
    MediaMatches,
    mediaMatches,
} from "@/tokens/configs/breakpoints_config";
import { BaseUnit } from "@/tokens/globals";
import {
    LayoutConfiguration,
    MediaWidthsByLayoutConfiguration,
} from "@/tokens/media";

import { getMinOrMaxWidth } from "./tokens/layout_util";

// Get current breakpoint in javascript
export function getBreakpointFromMediaMatch(
    mediaMatchesOverride?: MediaMatches,
): Breakpoint {
    const _mediaMatches = mediaMatchesOverride ?? mediaMatches;

    const _sortedMediaMatchValues = _.values(mediaMatches).sort(
        (a, b) => b - a,
    );

    const _sortedMediaMatches = _sortedMediaMatchValues.map((_value) => {
        return _.findKey(
            _mediaMatches,
            (_mediaMatch) => _mediaMatch === _value,
        );
    });

    let _matchingBreakpoint: Breakpoint = "extraSmall";

    // Loop through the media query matches and return the first one that's true
    _sortedMediaMatches.find((_mediaMatch) => {
        const _mediaMatchBase = _mediaMatches[
            _mediaMatch as Breakpoint
        ] as BreakpointBases;

        const _mediaMatchString = getMinOrMaxWidth(_mediaMatchBase, "MIN");

        if (window.matchMedia(_mediaMatchString).matches) {
            _matchingBreakpoint = _mediaMatch as Breakpoint;
            return true;
        }
    });

    return _matchingBreakpoint;
}

// Generates sizes attribute for Next/Image
export function generateMediaSizes(layoutConfiguration: LayoutConfiguration) {
    const _sizeObject = MediaWidthsByLayoutConfiguration[layoutConfiguration];

    const _mediaQueriesArray: Array<string> = [];

    _.mapValues(_sizeObject, (val, key) => {
        if (mediaMatches[key as Breakpoint] === BreakpointBases.EXTRA_LARGE) {
            _mediaQueriesArray.push(`${val}vw`);

            return;
        }

        /**
         * Because we're using max-width directives and not media queries,
         * we need to manually "move up" the breakpoint values to the next value
         * so we're getting the correct breakpoint values.
         */
        let _nudgedKey: Breakpoint;
        switch (key as Breakpoint) {
            case "extraSmall":
                _nudgedKey = "small";
                break;
            case "small":
                _nudgedKey = "medium";
                break;
            case "medium":
                _nudgedKey = "large";
                break;
            case "large":
                _nudgedKey = "extraLarge";
                break;
            default:
                _nudgedKey = "small";
        }

        const _breakpointBaseForKey = mediaMatches[_nudgedKey];

        _mediaQueriesArray.push(
            `${getMinOrMaxWidth(_breakpointBaseForKey!, "MAX")} ${val}vw`,
        );

        return;
    });

    return _mediaQueriesArray.join(", ");
}

// Convert one-off value to rem
export function convertToRem(value: number) {
    return `${value / BaseUnit}rem`;
}
