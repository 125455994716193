import { css } from "@emotion/react";
import * as _ from "lodash-es";

import {
    BreakpointObject,
    BreakpointOrBreakpointObject,
    StyleAttribute,
} from "@/types/tokens/breakpoints";

import { mediaQuerySets } from "@/tokens/configs/breakpoints_config";
import { Blur, Blurs } from "@/tokens/misc";

/**
 * Defines a specific CSS attribute that changes across breakpoints
 *
 * @param cssAttribute
 * @param breakpointObject
 * @returns
 */

export function buildStylesByBreakpoint(
    cssAttribute: string,
    breakpointObject: BreakpointObject<StyleAttribute>,
) {
    const { extraSmall, small, medium, large, extraLarge } = breakpointObject;

    return css({
        [cssAttribute]: extraSmall,
        [mediaQuerySets.small.min]: { [cssAttribute]: small },
        [mediaQuerySets.medium.min]: { [cssAttribute]: medium },
        [mediaQuerySets.large.min]: { [cssAttribute]: large },
        [mediaQuerySets.extraLarge.min]: { [cssAttribute]: extraLarge },
    });
}

/**
 * Defines multiple CSS attributes that change across breakpoints
 *
 * @param breakpointObject
 * @returns
 */
export function buildObjectStylesByBreakpoint(
    breakpointObject: BreakpointObject<{ [key: string]: string }>,
) {
    const { extraSmall, small, medium, large, extraLarge } = breakpointObject;

    const expandObject = (nestedStyleObject?: { [key: string]: string }) => {
        if (!nestedStyleObject) {
            return undefined;
        }

        return Object.keys(nestedStyleObject).map((styleSet) => ({
            [styleSet]: nestedStyleObject[styleSet],
        }));
    };

    return css({
        [mediaQuerySets.extraSmall.min]: expandObject(extraSmall),
        [mediaQuerySets.small.min]: expandObject(small),
        [mediaQuerySets.medium.min]: expandObject(medium),
        [mediaQuerySets.large.min]: expandObject(large),
        [mediaQuerySets.extraLarge.min]: expandObject(extraLarge),
    });
}

/**
 * Can apply a singular CSS attribute or a CSS attribute that changes across different breakpoints.
 *
 * @param cssAttribute
 * @param styleProp
 * @returns
 */
export function buildStylesByStringOrObject<T extends StyleAttribute>(
    cssAttribute: string,
    styleProp: T | BreakpointOrBreakpointObject<T>,
) {
    // Is singular rule
    if (!_.isObject(styleProp)) {
        const stylePropAsString = styleProp as T;

        return css({
            [cssAttribute]: stylePropAsString,
        });
    }

    // Is object
    return buildStylesByBreakpoint(
        cssAttribute,
        styleProp as BreakpointObject<T>,
    );
}

/**
 * Applies display: none on extra small screens and display: flex by default on medium screens
 *
 * @param displayValue
 * @returns SerializedStyles
 */
export function hideOnExtraSmall(displayValue = "flex") {
    return css({
        [mediaQuerySets.extraSmall.min]: {
            display: "none",
        },
        [mediaQuerySets.medium.min]: {
            display: displayValue,
        },
    });
}

/**
 * Applies display: flex by default on extra small screens and display: none on medium screens
 *
 * @param displayValue
 * @returns SerializedStyles
 */
export function showOnExtraSmall(displayValue = "flex") {
    return css({
        [mediaQuerySets.extraSmall.min]: {
            display: displayValue,
        },
        [mediaQuerySets.medium.min]: {
            display: "none",
        },
    });
}

/**
 * Produces formatted backdrop filter command
 */
export function backgroundBlur(blurSize: Blur) {
    return `blur(${Blurs[blurSize]}px)`;
}
